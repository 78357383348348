import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import RouteComponent from "./routes";
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'antd/dist/reset.css';
import TagManager from '@sooro-io/react-gtm-module'

interface AppProps {
  isLoggedIn: boolean;
  skipTour: boolean;
  skipCostViewTour: boolean;
  auth_token: any;
  userDetails: any;
  companyDetails: any;
}

const App: React.FC<AppProps> = ({ isLoggedIn, auth_token, skipTour, skipCostViewTour, userDetails, companyDetails }) => {
  const yearNow = new Date().getFullYear();
  const appBuild = process.env.REACT_APP_RELEASE_VERSION || '0.0.0';
  const tagManagerArgs: any = {
    gtmId: process.env.REACT_APP_GTM_ID || "GTM-T3VRKK9R",
  }
  console.log('tagManagerArgs', tagManagerArgs)
  console.log('appBuild', appBuild)
  TagManager.initialize(tagManagerArgs)

  return (
    <div id="lemos-webapp">
      <Router>
        <RouteComponent isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} skipCostViewTour={skipCostViewTour} userDetails={userDetails} companyDetails={companyDetails} />
        <ToastContainer />
      </Router>
      <div className="footer">
        <label className='version'>	&#169; LEMOS {yearNow} - Version {appBuild}</label> 
      </div>
      
    </div>
  );
}

const mapStateToProps = (state:any) => {
  return {
      isLoggedIn: state.userInfo.isLoggedIn,
      skipTour: state.userInfo.skipTour,
      skipCostViewTour: state.userInfo.skipCostViewTour,
      auth_token: state.userInfo.auth_token,
      userDetails: state.userInfo.userDetails,
      companyDetails: state.userInfo.companyDetails
  }
}

export default connect(mapStateToProps, null)(App);
