import React from "react";
import "./welcome.scss";
import { ReactComponent as DashboardIcon } from "../Icons/dashboardIcon.svg";
import companyIcon from "../Icons/companyIcon.svg";

const Welcome = () => {
  return (
    <div className="welcome">
      <div className="company-logo ml-60">
        <img src={companyIcon} className="company-img-css" />
      </div>
      <div className="title-css ml-60">
        <h1>Welcome to LEMOS</h1>
      </div>
      <div className="dashboard-comp-img">
        <div className="dashboard-img-shadow-css"></div>
        <DashboardIcon className="dashboard-img-css" />
      </div>
    </div>
  );
};

export default Welcome;