import { ApiRoutesType } from "./api.types";

export const API_ROUTES: ApiRoutesType = {
  LOG_IN: "/user/login",
  SIGN_UP: "/user/signup-v1",
  VALIDATE_SETUP_OTP: "/user/validate-otp-v1",
  RESEND_SETUP_OTP: "/user/resend-otp",
  LOG_OUT: "/logout",
  DEFAULT_ENDPOINT: "/lemos/api",
  FORGOT_PASSWORD: "/user/forgot-password",
  RESET_PASSWORD: "/user/reset-password",
  SKIP_TOUR: "/user/skip-tour",
  SKIP_COST_VIEW_TOUR: "/user/skip-cost-view-tour",
  GET_PROJECT: "/project/get-projects-v1",
  CREATE_PROJECT: "/project/create-v1",
  PROJECT_OPERATIONS: "/project/project-operations",  
  PROJECT_VERSION_UPDATE: "/project/client-project-version",  
  SUBSCRIPTION_LIST: "/user/list-subscription-type",
  CHECK_USER_EXIST: "/user/check-email-exists",
  PROJECT_DETAILS: "/project",
  VERSIONS: "/project/version",
  GET_USER_PROFILE: "/user/get-user-profile",
  GET_USER_PROJECTS: "/project/get-user-projects",
  GET_VERSION_EXECUTION_STATUS: "/project/get-version-execution-status",
  GET_RECENTLY_OPENED_PROJECTS: "/project/recently-opened",
  EXPORT_PROJECT_REPORT: "/project/export",
  PROJECT_LIST_VERSIONS: "/project/get-versions",
  COMPARE_PROJECTS: "/project/compare",
  EXPORT_COMPARE_DATA:"/project/compare-export",
  GET_UNCATEGORIZED: "/project/get-uncategorized-v1",
  GET_CATEGORIZED: "/project/get-categorized-v1",
  ADD_DELETE_TAGS: "/project/tags",
  GET_POST_ALL_COMMENTS: "/project/comments",
  GET_COMMENTS_BY_ID: "/project/get-comments",
  GET_COMMENTS_BY_ID_V2: "/project/get-comments-v2",
  GET_PORTFOLIO_COMMENTS: "/project/get-portfolio-comments",
  GET_PROJECT_USERS: "/project/list-users-v1",
  PROJECT_MAPPING: "/project/map-uncategorized-v2",
  GET_RECENT_ACTIVITIES: "/project/get-recent-activities",
  INVITE_USERS:"/project/invite-v1",
  DELETE_PROJECT: "/project/delete-project",
  GENERATE_SAMPLE: '/project/generate-sample',
  CREATE_UPDATE_PROJECT_VERSION_COST: '/project/project-version-cost',
  BULK_UPDATE_PROJECT_VERSION_COST: '/project/project-version-cost-bulk',
  UPDATE_PROJECT_VERSION_TARGET_COST: '/project/target-cost',
  GET_HISTORICAL_PROJECT_COSTS: '/project/historical-project-costs',
  GET_ALL_MASTERFORMAT_CATEGORIES: '/project/all-master-format-categories',
  POST_COST_CUSTOM_ROW: '/project/cost-custom-row',
  RECLASSIFICATION_SPECS: '/project/reclassification-spec',
  PROJECT_VERSION_CUSTOM_UNIT: '/project/project-version-custom-unit',
  GET_USER_SUBSCRIPTION: '/stripe/get-subscriptions',
  CREATE_CHECKOUT_SESSION: '/stripe/create-checkout-session',
  CHECKOUT_UPDATE: '/stripe/checkout-update',
  ASSIGN_SUBSCRIPTION_ID: '/project/assign-subscription-id',
  COST_INSIGHTS: '/project/cost-insights',
  GET_PROJECT_COST_INSIGHTS: '/project/project-cost-insights',
  COST_UNDO_REDO: '/project/cost-undo-redo',
  ACCEPT_PROJECT_INVITE: '/project/accept-project-invite',
  ESTIMATORY_NOTIFY_USER: '/project/estimator/notify',
  NOTIFY_ESTIMATOR_REGEN_COST_REQ: '/project/regenerate-cost-notification'
};
