import React from 'react'
import { useState } from 'react';
import { NavLink } from "react-router-dom";
import { NavItem } from "reactstrap";
import withRouter from "../../../withRouter"

interface sidebarSubNavProps {
    navInfo: any;
    key: string;
}

const SidebarSubNav: React.FC<sidebarSubNavProps> = ({ navInfo, key }, props) => {
    const [showDropdown, setShowDropdown] = useState(false)

    const toggleDropDown = () => {
        setShowDropdown(!showDropdown)
        if (showDropdown) {
            let subModules = navInfo && navInfo.subModules;
            const activeRoute = props.location && props.location.pathname;
            let activeUrl = subModules.filter((item: any) => item.link == activeRoute);
            if (activeUrl.length > 0) {
                activeUrl = activeUrl[0].link;
            } else {
                activeUrl = subModules[0].link;
            }
            props.history.replace({ pathname: activeUrl.toString() });
        }
    };

    const subMenuClick = (e: any) => {
        e.stopPropagation();
        setShowDropdown(true);
    };

    const getSubModules = () => {
        let subModules = navInfo && navInfo.subModules;
        let subModuleArray = [];
        subModuleArray.push(
            subModules.map((subModule: any, index: number) => {
                return (
                    <>
                        <span className='dotted-css zIndexHigh'>{" "}</span>
                        <NavLink
                            onClick={(e) => subMenuClick(e)}
                            to={subModule.link}
                            className={({ isActive }) =>
                                [
                                    "nav-link sidebar-sub light-gray-400",
                                ]
                                    .filter(Boolean)
                                    .join(" ")
                            }
                            key={`subNav${index}`}
                        >
                            <span className="fs-400 ms-4 fs-12">
                                {subModule.name}
                            </span>
                        </NavLink>
                    </>
                );
            })
        );
        return subModuleArray;
    };


    return (
        <NavItem
            onClick={() => {
                toggleDropDown();
            }}

        >
            <a className="nav-link xx light-gray-400">
                <span>
                    <navInfo.icon />
                </span>
                <span className="ms-2 fs-12">{navInfo.name}</span>
                <span>
                </span>
            </a>
            {
                showDropdown ? getSubModules() : null
            }
        </NavItem>
    )
}

export default withRouter(SidebarSubNav)