import { SET_AUTH_TOKEN, SET_SKIP_TOUR, SET_SKIP_COST_VIEW_TOUR, SET_LOGIN_STATUS, SET_USER_DETAILS, SET_COMPANY_DETAILS, UserActionDispatchTypes, UserInfoState } from "./types";


const defaultState: UserInfoState = {
    isLoggedIn: false,
    skipTour: false,
    skipCostViewTour: false,
    auth_token: {
        token: "",
        token_expire: 0
    },
    userDetails: {
        id: 0,
        name: "",
        email: "",
    },
    companyDetails: [{
        id: "",
        name: "",
    }]
};

export const userInfoReducer = (state: UserInfoState = defaultState, action: UserActionDispatchTypes): UserInfoState => {
    switch (action.type) {
        case SET_AUTH_TOKEN:
            return {
                ...state,
                auth_token: (action.payload as any).auth_token
            }
        case SET_LOGIN_STATUS:
            return {
                ...state,
                isLoggedIn: (action.payload as any).isLoggedIn
            }
        case SET_SKIP_TOUR:
            return {
                ...state,
                skipTour: (action.payload as any).skipTour
            }
        case SET_SKIP_COST_VIEW_TOUR:
            return {
                ...state,
                skipCostViewTour: (action.payload as any).skipCostViewTour
            }
        case SET_USER_DETAILS:
            return {
                ...state,
                userDetails: (action.payload as any).userDetails
            }
        case SET_COMPANY_DETAILS:
            return {
                ...state,
                companyDetails: (action.payload as any).companyDetails
            }
        default:
            return state
    }
};


export default userInfoReducer

