import { logout } from "../redux/user/actions";
import { setLoginStatus, setUserDetails, setCompanyDetails, setAuthToken} from "../redux/user/actions"

export const saveTokenInLocalStorage = (tokenDetails:any) => {
    tokenDetails.loginTime = new Date().getTime();
    localStorage.setItem('tokenDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch:Function, timer:any) {
    setTimeout(() => {
        dispatch(setLoginStatus(false))
        dispatch(setUserDetails(""))
        dispatch(setCompanyDetails(""))
        dispatch(setAuthToken(""))
        dispatch(logout());
    }, timer);
}
