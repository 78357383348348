import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux';
import { API_ROUTES } from "../../../services/api.constants";
import { validateUserData } from '../../../services/baseApi.service'
import { isArray } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import './SubscriptionSidebarNavItem.scss'
import { STRIPE_SUBSCRIPTION_STATUS } from '../../constants/stripe.constants';
const CUSTOMER_PORTAL_URL: string = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL || '';


interface Props {
    userDetails: any;
    customClass?: any;
}

const SubscriptionSidebarNavItem: React.FC<Props> = (props: Props) => {

    const location = useLocation();
    const userDetails = props.userDetails
    const [userSubscription, setUserSubscription] = useState<any>(null)
    const [detailsFetched, setDetailsFetched] = useState(false)
    const [refreshSubscriptionCount, setRefreshSubscriptionCount] = useState(false);

    
    // API Calls
    const getUserSubscription = async () => {
        setDetailsFetched(false)

        const tokenDetails: any = localStorage.getItem('tokenDetails');
        const token = JSON.parse(tokenDetails)?.token
        const header = { "Authorization": "Bearer " + token || "" }
        const url = `${API_ROUTES.GET_USER_SUBSCRIPTION}`

        if (!token) {
            return;
        }

        await validateUserData(url, {}, "GET", header)
        .then(async (response: any) => {

            if (!response) {
                setDetailsFetched(true)
                return;
            }

            if (response.error || !response?.response || !response?.response?.data) {
                setDetailsFetched(true)
                return;
            }


            const data = response?.response?.data
            if (!data.subscription || !isArray(data.subscription) || data.subscription.length == 0) {
                setDetailsFetched(true)
                return;
            }

            const subscription = data.subscription[0]
            if (!subscription.subscription_items || !isArray(data.subscription)) {
                setDetailsFetched(true)
                return;
            }


            let totalQuantity = 0;
            subscription.subscription_items.forEach((element: any) => {
                totalQuantity += element.quantity;
            });


            subscription.totalQuantity = totalQuantity;
            setUserSubscription(subscription)
            setDetailsFetched(true)
        })
        .catch((error) => {
            console.log("getUserSubscription error", error)
            setDetailsFetched(true)
        });
    }

    const createCheckoutSession = async () => {
        const tokenDetails: any = localStorage.getItem('tokenDetails');
        const token = JSON.parse(tokenDetails)?.token
        const header = { "Authorization": "Bearer " + token || "" }

        if (!token) {
            return;
        }

        const url = `${API_ROUTES.CREATE_CHECKOUT_SESSION}`
        await validateUserData(url, {}, "POST", header)
          .then((response: any) => {
            if (!response) {
                return;
            }

            if (response.error) {
                return;
            }


            const session = response?.response?.session;
            if (!session.url) {
                return;
            }

            localStorage.setItem("previous_path", window.location.pathname)
            window.location.replace(session.url)
          })
          .catch((error) => {
            console.log("createCheckoutSession error", error)
          });
    }

    const formatDate = (date: any) => {
        let year = date.getFullYear() % 100;
        let month = (1 + date.getMonth()).toString();
        let day = date.getDate().toString();
        
        return month + '/' + day + '/' + year;
    }

    const customerPortal = () => {
        localStorage.setItem("previous_path", window.location.pathname)
        window.location.replace(`${CUSTOMER_PORTAL_URL}?prefilled_email=${userDetails.email}`)
    }

    // UseEffect
    useEffect(() => {
        if (userDetails) {
            getUserSubscription()
        }
    }, [location]);

    useEffect(() => {
        const handleRefreshSubscriptionCount = () => {
            setRefreshSubscriptionCount(true);
        };

        window.addEventListener('refresh_subscription_count_updated', handleRefreshSubscriptionCount);

        return () => {
            window.removeEventListener('refresh_subscription_count_updated', handleRefreshSubscriptionCount);
        };
    }, [])

    useEffect(() => {
        if (refreshSubscriptionCount) {
            setRefreshSubscriptionCount(false)
            getUserSubscription()
        }
    }, [refreshSubscriptionCount])


    // Render
    const renderBanner = () => {
        if (userSubscription?.status === STRIPE_SUBSCRIPTION_STATUS.TRIALING) {
            const trial_end = userSubscription?.trial_end ?  new Date(userSubscription?.trial_end) : new Date(Date.now())
            const payment_method = userSubscription.default_payment_method;


            return (
                <div  className="subscription-nav-item-container" onClick={payment_method ? customerPortal : createCheckoutSession}>
                    <div className='message-container'>
                        <div className='message' >Pro Trial<br /><span>until {formatDate(trial_end)}</span></div>
                    </div>
                </div>
            )
        }
        
        return <div  className="subscription-nav-item-container filler"></div>
    }

    
    return (<>{renderBanner()}</>)
}

const mapStateToProps = (state: any) => {
    return {
        userDetails: state.userInfo.userDetails,
    }
}

export default connect(mapStateToProps, null)(SubscriptionSidebarNavItem)