import { isArray } from 'lodash';
import { STRIPE_SUBSCRIPTION_STATUS } from '../commons/constants/stripe.constants';

export const isValidEmailId = (email: string) => {
  let mailformat =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return email.match(mailformat);
};

export const validatePhoneNo = (number: string) => {
  const regex = /^\d{7,16}$/;
  const validatePhone = regex.test(number);
  return validatePhone;
};

export const validatePassword = (password: string) => {
  const regex = /^.{8,}$/;
  const validatePassword = regex.test(password);
  return validatePassword;
};

export const formatNumber = (number: any) => {
  let parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
    return number;
  }
  const isFloat = parsedNumber % 1 > 0;

  if (parsedNumber < 0.005 && parsedNumber > 0) {
    return '<0.01';
  }

  if (isFloat) {
    return parseFloat(parsedNumber.toFixed(2)).toLocaleString();
  }

  return Math.trunc(parsedNumber).toLocaleString();
};

export const hsqPush = (
  name: string,
  properties: any = '',
  isCustomEvent: boolean = false
) => {
  const _hsq = (window['_hsq'] = window['_hsq'] || []);
  const hsAccount = process.env.REACT_APP_HUBSPOT_ACCOUNT;

  if (isCustomEvent) {
    _hsq.push([
      'trackCustomBehavioralEvent',
      {
        name: `pe${hsAccount}_${name}`,
        properties: properties,
      },
    ]);
  } else {
    if (properties === '') {
      _hsq.push([name]);
    } else {
      _hsq.push([name, properties]);
    }
  }
};

export const checkSubscriptionAvailable = (
  customerData: any,
  projectList: any
) => {
  if (!customerData) {
    return false;
  }

  const subscription = customerData.subscription[0];
  if (!subscription.subscription_items || !isArray(customerData.subscription)) {
    return false;
  }

  if (subscription.status === STRIPE_SUBSCRIPTION_STATUS.CANCELED) {
    return false;
  }

  let totalSubscriptionCount = 0;
  subscription.subscription_items.forEach((element: any) => {
    totalSubscriptionCount += element.quantity;
  });

  const subscribedProjectsCount =
    projectList?.filter((p: any) => p.subscription_id !== null).length || 0;
  return subscribedProjectsCount < totalSubscriptionCount;
};

export const setRefreshStatus = (eventName: string) => {
  window.dispatchEvent(new Event(eventName));
};

export const setStorageRefreshProjectDetails = () => {
  setRefreshStatus('refresh_project_details_updated');
};

export const setStorageRefreshSubscriptionCount = () => {
  setRefreshStatus('refresh_subscription_count_updated');
};

export const formatDateModalMessage = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.toLocaleString('en-US', { weekday: 'long' });
  const month = date.toLocaleString('en-US', { month: 'long' });
  const dayOfMonth = date.getDate();
  const year = date.getFullYear();

  return `${day}, ${month} ${dayOfMonth}, ${year}`;
};

export const PROJECT_NAME_VALIDATION_REGEX = /^(?![\s-])[a-zA-Z0-9_\s-]$/;

export const generateExpandedKeys = (input: string) => {
  const segments = input.split('-');
  const result: any = {};
  let currentPath = [];

  for (let segment of segments) {
    currentPath.push(segment);
    const currentKey = currentPath.join('-');
    result[currentKey] = true;
  }

  return result;
};

export const toTitleCase = (word: string) => {
  return word.charAt(0) + word.substring(1).toLowerCase();
};
