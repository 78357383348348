import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap'
import classnames from "classnames";
import { Modal, ModalBody } from 'reactstrap';
import Select, { createFilter, components } from 'react-select'
import AsyncSelect from 'react-select/async';
import { IconClose } from "../../../commons/components/Icons"
import '../../../../src/styles/account.scss';
import '../portfolio.scss';
import { UsLocations } from '../../../commons/data/cities';
import { hsqPush } from '../../../utils/helpers';
import { API_ROUTES } from '../../../services/api.constants';
import { validateUserData } from '../../../services/baseApi.service';
import { 
    PROJECT_TYPES, 
    PROJECT_STRUCTURAL_FRAME_TYPES, 
    PROJECT_USE_TYPE_POPULATED_VALUES,
    PROJECT_NUMBER_OF_STORIES_TYPES,
    PROJECT_PARKING_TYPES,
    PROJECT_PARKING_VALUES

} from '../../../commons/constants/project.constants';

const filterLocation = (inputValue: string) => {
    return UsLocations.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
};

const loadOptions = (
    inputValue: string,
    callback: (options: any) => void
) => {
    setTimeout(() => {
        callback(filterLocation(inputValue));
    }, 1000);
};

const ReactSelectOption: React.FC<any> = ({ children, ...props }) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = Object.assign(props, { innerProps: rest });
    return (
        <components.Option
            {...newProps}
        >
            {children}
        </components.Option>
    );
};

interface AddProjectProps {
    callbackMethod: Function;
    showCreateProject: boolean;
    onCreateProject: Function;
    loader: boolean;
}

const AddProject: React.FC<AddProjectProps> = ({ callbackMethod, showCreateProject, onCreateProject, loader }) => {
    const ProjectTypes = PROJECT_TYPES;
    const PrimaryStructuralFrameTypes = PROJECT_STRUCTURAL_FRAME_TYPES;
    const UseTypePrePopulatedValues: any = PROJECT_USE_TYPE_POPULATED_VALUES;
    const NumberOfStoriesTypes = PROJECT_NUMBER_OF_STORIES_TYPES;
    const ParkingTypes = PROJECT_PARKING_TYPES;
    const ParkingValues: any = PROJECT_PARKING_VALUES;

    const [selectedData, setSelectedData] = useState<any>({
        name: "",
        location: "",
        project_type: "",
        no_of_units: null,
        gross_square_footage: "",
        construction_type: "",
        primary_structural_frame: "",
        no_of_stories: null,
        net_rentable_square_footage: null,
        below_grade_parking: false,
        above_grade_structured_parking: false
    })

    const [error, setError] = useState<any>({});

    // for us cities
    const [locations, setLocations] = useState<any>([]);
    const [projectCostInsight, setProjectCostInsight] = useState<any>([]);
    const [primaryStructuralFrameOptions, setPrimaryStructuralFrameOptions] = useState<any>(PrimaryStructuralFrameTypes);
    const [parkingOptions, setParkingOptions] = useState<any>(ParkingTypes);
    const [floorEstimateOptions, setFloorEstimateOptions] = useState<any>(NumberOfStoriesTypes);
    const [enableFieldGlow, setEnableFieldGlow] = useState<boolean>(false);
    const [enableErrorGlow, setEnableErrorGlow] = useState<boolean>(false);

    const glowingFields = ['primary_structural_frame', 'parking', 'floor_estimate'];

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            paddingTop: state.hasValue ? '18px' : '0',
            border: state.isFocused ? "" : "1px solid #1B1F22",
            color: '#7E9AB5',
            "&:hover": {
                border: "1px solid #1B1F22",
            },
            cursor: "pointer",
        }),
        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
            ...styles,
            backgroundColor: isFocused ? "#1B1F22" : isSelected,
            color: isDisabled ? '#5a5a5a' : "#fffff",
            cursor: "pointer",
            zIndex: 2,
            "&:hover": {
                backgroundColor: "#7E9AB5",
            },
        }),
        placeholder: (styles: any) => ({ 
            ...styles,
            color: '#7E9AB5',
        })
    };
    
    const getProjectCostInsights = async (useType: any) => {
        const url = API_ROUTES.GET_PROJECT_COST_INSIGHTS
        const tokenDetails: any = localStorage.getItem('tokenDetails');
        const token = JSON.parse(tokenDetails)?.token
        const header = { "Authorization": "Bearer " + token || "" }
        const req = { "use_type": useType || "" }
        return await validateUserData(url, req, "POST", header)
            .then((response: any) => {
                if (response) {
                    if (!response.error && response.response && response.response.data) {
                        setProjectCostInsight(response.response.data)
                        return response.response.data 
                    } else {
                        console.log("error", response.errorMessages)
                    }
                } else {
                    console.log("No project cost insights")
                }
            })
            .catch((error) => {
                console.log("response", error)
            });
    }

    const onInputChange = (e: any, tag: string) => {
        let updatedContent = selectedData;
        updatedContent[tag] = ['below_grade_parking', 'above_grade_structured_parking'].includes(tag) ? e.target.checked : e.target.value
        setSelectedData(updatedContent);
        if (tag === "no_of_units" || tag === "gross_square_footage" || tag === "no_of_stories" || "net_rentable_square_footage") {
            let input = parseInt(e.target.value);
            if (input < 0) {

                if (tag === "no_of_units") {
                    setError({ no_of_units: "Please enter positive values" });
                }
                else if (tag === "no_of_stories") {
                    setError({ no_of_stories: "Please enter positive values" });
                }
                else if (tag === "net_rentable_square_footage") {
                    setError({ net_rentable_square_footage: "Please enter positive values" });
                }
                else {
                    setError({ gross_square_footage: "Please enter positive values" })
                }
            }
            else if (input == 0) {
                if (tag === "no_of_units") {
                    setError({ no_of_units: "Please enter non-zero values" })
                }
                else if (tag === "no_of_units") {
                    setError({ no_of_units: "Please enter non-zero values" })
                }
                else if (tag === "net_rentable_square_footage") {
                    setError({ net_rentable_square_footage: "Please enter non-zero values" })
                }
                else {
                    setError({ gross_square_footage: "Please enter non-zero values" })
                }
            }
            else {
                setError({});
            }
        }
        else {
            setError({});
        }

    }

    const onSelectChange = async (data: any, tag: string) => {
        let updatedContent = selectedData;

        if (glowingFields.includes(tag) && !updatedContent['project_type']) {
            setEnableErrorGlow(true);
            setTimeout(() => {
                setEnableErrorGlow(false);
            }, 1000);
            return;
        }
        
        if (tag === 'project_type') {
            const _projectCostInsight = await getProjectCostInsights(data.value) || [];
            const useTypePrePopulatedValuesData = UseTypePrePopulatedValues[data.value];
            updatedContent.primary_structural_frame = useTypePrePopulatedValuesData?.primary_structural_frame;
            updatedContent.parking = useTypePrePopulatedValuesData?.parking;
            updatedContent.floor_estimate = useTypePrePopulatedValuesData?.floor_estimate;

            const _primaryStructuralFrameOptions = primaryStructuralFrameOptions
            const _parkingOptions = parkingOptions
            const _floorEstimateOptions = floorEstimateOptions

            const newPrimaryStructuralFrameOptions = _primaryStructuralFrameOptions.map((option: any) => {
                const is_insight_exist = _projectCostInsight.find((pci: any) => 
                    pci.primary_structural_frame != null &&
                    pci.primary_structural_frame.toLowerCase() === option.value.toLowerCase())
                option.isDisabled = is_insight_exist ? false : true;
                return option
            })

            const newParkingOptions = _parkingOptions.map((option: any) => { 
                const is_insight_exist = _projectCostInsight.find((pci: any) => 
                    pci.primary_structural_frame != null &&
                    pci.primary_structural_frame.toLowerCase() === updatedContent.primary_structural_frame.toLowerCase() &&
                    pci.above_grade_structured_parking == option.above_grade_structured_parking &&
                    pci.below_grade_parking == option.below_grade_parking)

                option.isDisabled = is_insight_exist ? false : true;
                return option
            })

            const newFloorEstimateOptions = _floorEstimateOptions.map((option: any) => {
                const is_insight_exist = _projectCostInsight.find((pci: any) => 
                    pci.primary_structural_frame != null &&
                    pci.primary_structural_frame.toLowerCase() === updatedContent.primary_structural_frame.toLowerCase() &&
                    pci.floors.toLowerCase() ===  option.value.toLowerCase())

                option.isDisabled = is_insight_exist ? false : true;
                return option
            })

            setPrimaryStructuralFrameOptions(newPrimaryStructuralFrameOptions)
            setParkingOptions(newParkingOptions)
            setFloorEstimateOptions(newFloorEstimateOptions)

            setEnableFieldGlow(true);
            setTimeout(() => {
                setEnableFieldGlow(false);
            }, 1000);
        }

        if (tag === 'primary_structural_frame') {
            const _projectCostInsight = projectCostInsight.length == 0 ? (await getProjectCostInsights(data.value) || []) : projectCostInsight;
            const useTypePrePopulatedValuesData = UseTypePrePopulatedValues[updatedContent.use_type];

            const currentParking = updatedContent.parking;
            const currentFloorEstimate = updatedContent.floor_estimate;

            const _parkingOptions = parkingOptions
            const _floorEstimateOptions = floorEstimateOptions

            let changeParking = false
            const newParkingOptions = _parkingOptions.map((option: any) => { 
                const is_insight_exist = _projectCostInsight.find((pci: any) => 
                    pci.primary_structural_frame != null &&
                    pci.primary_structural_frame.toLowerCase() === data.value.toLowerCase() &&
                    pci.above_grade_structured_parking == option.above_grade_structured_parking &&
                    pci.below_grade_parking == option.below_grade_parking)

                option.isDisabled = is_insight_exist ? false : true;

                if (currentParking === option.value && option.isDisabled) {
                    changeParking = true;
                }
                return option
            })

            // Get first available parking option
            if (changeParking) {
                updatedContent.parking = newParkingOptions.find((option: any) => !option.isDisabled)?.value
            }

            
            let changeFloorEstimate = false
            const newFloorEstimateOptions = _floorEstimateOptions.map((option: any) => {
                const is_insight_exist = _projectCostInsight.find((pci: any) => 
                    pci.primary_structural_frame != null &&
                    pci.primary_structural_frame.toLowerCase() === data.value.toLowerCase() &&
                    pci.floors.toLowerCase() ===  option.value.toLowerCase())

                option.isDisabled = is_insight_exist ? false : true;

                if (currentFloorEstimate === option.value && option.isDisabled) {
                    changeFloorEstimate = true;
                }
                return option
            })

            // Get first available floor estimate option
            if (changeFloorEstimate) {
                updatedContent.floor_estimate = newFloorEstimateOptions.find((option: any) => !option.isDisabled)?.value
            }

            setParkingOptions(newParkingOptions)
            setFloorEstimateOptions(newFloorEstimateOptions)
        }

        updatedContent[tag] = data.value
        setSelectedData(updatedContent);
        setError({});
    }

    const validateForm = () => {
        const parkingValues = ParkingValues[selectedData.parking];
        const below_grade_parking = parkingValues === undefined || parkingValues === null ? false : parkingValues.below_grade_parking;
        const above_grade_structured_parking = parkingValues === undefined || parkingValues === null ? false : parkingValues.above_grade_structured_parking;

        selectedData.below_grade_parking = below_grade_parking;
        selectedData.above_grade_structured_parking = above_grade_structured_parking;

        if (selectedData.name == "") {
            error["name"] = "Please enter a project name";
        }
        if (selectedData.location == "") {
            error["location"] = "Please select a location";
        }
        if (selectedData.project_type == "") {
            error["project_type"] = "Please select a project type";
        }
        if (selectedData.gross_square_footage == "") {
            selectedData.gross_square_footage = 1;
        }
        if (Object.keys(error).length) {
            setError({ ...error });
        } else {
            onCreateProject(selectedData)
        }
    }

    function handleInputChange(input: string) {
        input = input.replaceAll(",", "").toLowerCase();
        if (input.length === 0) {
            setLocations([]);
        }
        if (input.length > 0) {
            setLocations(UsLocations.filter(x => x.label.toLowerCase().includes(input)));
        }
    }

    useEffect(() => {
        hsqPush('setPath', `${window.location.pathname}?create-project-dialog`);
        hsqPush('trackPageView');
    }, [])

    return (
        <Form>
            <Modal isOpen={showCreateProject} fade={false} contentClassName='add-project-section' size="lg" >
                <div className='text-align-end mt-4 me-4 cursor-pointer' onClick={() => callbackMethod()}>
                    <IconClose />
                </div>
                <ModalBody className='add-project-body-section'>
                    <div className='title title-fw-600 mb-20 text-white'>
                        Project Information
                    </div>
                    <div className='row mb-1'>
                        <div className='ss-signup col-6'>
                            <FormGroup floating>
                                <Input
                                    type='text' className='fs-14 fw-400 bg-grey500 input_css'
                                    id="projectName"
                                    placeholder=""
                                    onChange={(e) => onInputChange(e, "name")}
                                    required
                                />
                                <Label className="floating-label input-label-padding-top" for="projectName">
                                    <span className="project-name-label">Project Name</span>
                                    <span className='select-required'>*</span>
                                </Label>
                            </FormGroup>
                            <Label className="label-error" style={{ marginTop: 3 }}>
                                {error.name
                                    ? error.name
                                    : null}
                            </Label>
                        </div>
                        <div className='col-6 position-relative'>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions={UsLocations}
                                filterOption={createFilter({ ignoreAccents: false })}
                                onChange={(e) => onSelectChange(e, "location")}
                                onInputChange={handleInputChange}
                                placeholder='Location'
                                options={UsLocations}
                                name="location"
                                isDisabled={false}
                                isSearchable={true}
                                noOptionsMessage={() => { return null; }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    height: 50,
                                    colors: {
                                        ...theme.colors,
                                        text: "#ffffff",
                                        primary: '#1B1F22', //border color
                                        neutral0: '#1B1F22', //background color
                                        neutral60: 'rgba(126, 154, 181,0.5)', //drodown color
                                        neutral80: '#ffffff', //placeholder color
                                        neutral20: 'rgba(126, 154, 181,0.5)', // seperator color
                                        neutral50: "rgba(126, 154, 181,0.5)", //placeholder color
                                    },
                                    spacing: {
                                        ...theme.spacing,
                                        controlHeight: 58,
                                    },
                                    background: '#1B1F22'
                                })}
                                styles={customStyles}
                                components={{
                                    IndicatorSeparator: () => null,
                                    Option: ReactSelectOption
                                }}
                            />
                            <div className={`select-location-asterisk select-required ${!selectedData?.location ? 'd-flex': 'd-none'}`}>*</div>
                            <Label className={`${selectedData?.location ? 'd-flex' : 'd-none'} floating-label-select`}>Location&nbsp;<span className='select-required'>*</span></Label>
                            <Label className="label-error">
                                {error.location
                                    ? error.location
                                    : null}
                            </Label>
                        </div>
                    </div>
                    <div className='ss-signup row mb-1'>
                        <div className='ss-signup col-6'>
                            <div className="border-glow-effect" style={{ opacity: enableErrorGlow ? "1" : "0" }} />
                            <Select
                                onChange={(e) => onSelectChange(e, "project_type")}
                                placeholder="Use Type"
                                options={ProjectTypes}
                                name="project_type"
                                isDisabled={false}
                                isSearchable={true}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: "#1B1F22",
                                        primary: '#1B1F22', //border color
                                        neutral0: '#1B1F22', //background color
                                        neutral60: 'rgba(126, 154, 181,0.5)', //drodown color
                                        neutral80: '#ffffff', //placeholder color
                                        neutral20: 'rgba(255,255,255)', // seperator color
                                        neutral50: "rgba(126, 154, 181,0.5)", //placeholder color
                                    },
                                    spacing: {
                                        ...theme.spacing,
                                        controlHeight: 58,
                                    },
                                    background: '#1B1F22',
                                })}
                                styles={customStyles}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                required />
                            <div className={`select-use-type-asterisk select-required ${!selectedData?.project_type ? 'd-flex': 'd-none'}`}>*</div>
                            <Label className={`${selectedData?.project_type ? 'd-flex' : 'd-none'} floating-label-select`}>Use Type&nbsp;<span className='select-required'>*</span></Label>
                            <Label className="label-error" style={{ marginTop: 3 }}>
                                {error.project_type
                                    ? error.project_type
                                    : null}
                            </Label>
                        </div>
                        <div className='col-6 position-relative'>
                            <div className="border-glow-effect" style={{ opacity: enableFieldGlow ? "1" : "0" }} />
                            <Select
                                onChange={(e) => onSelectChange(e, "primary_structural_frame")}
                                value={selectedData.primary_structural_frame ? { label: selectedData.primary_structural_frame, value: selectedData.primary_structural_frame } : null}
                                placeholder="Primary Structural Frame"
                                options={primaryStructuralFrameOptions}
                                name="primary_structural_frame"
                                isDisabled={false}
                                id="primary_structural_frame"
                                isSearchable={true}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: "#1B1F22",
                                        primary: '#1B1F22', //border color
                                        neutral0: '#1B1F22', //background color
                                        neutral60: 'rgba(126, 154, 181,0.5)', //drodown color
                                        neutral80: '#ffffff', //placeholder color
                                        neutral20: 'rgba(255,255,255)', // seperator color
                                        neutral50: "rgba(126, 154, 181,0.5)", //placeholder color
                                    },
                                    spacing: {
                                        ...theme.spacing,
                                        controlHeight: 58,
                                    },
                                    background: '#1B1F22'
                                })}
                                styles={customStyles}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                required />
                            <Label className={`${selectedData?.primary_structural_frame ? 'd-flex' : 'd-none'} floating-label-select`}>
                                Primary Structural Frame
                            </Label>
                            <Label className="label-error" style={{ marginTop: 3 }}>
                                {error.primary_structural_frame
                                    ? error.primary_structural_frame
                                    : null}
                            </Label>
                        </div>
                    </div>
                    <div className='row mb-1'>
                        <div className='ss-signup col-6'>
                            <div className="border-glow-effect" style={{ opacity: enableFieldGlow ? "1" : "0" }} />
                            <Select
                                onChange={(e) => onSelectChange(e, "parking")}
                                value={selectedData.parking ? { label: selectedData.parking, value: selectedData.parking } : null}
                                placeholder="Parking"
                                options={parkingOptions}
                                name="parking"
                                isDisabled={false}
                                isSearchable={true}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: "#1B1F22",
                                        primary: '#1B1F22', //border color
                                        neutral0: '#1B1F22', //background color
                                        neutral60: 'rgba(126, 154, 181,0.5)', //drodown color
                                        neutral80: '#ffffff', //placeholder color
                                        neutral20: 'rgba(255,255,255)', // seperator color
                                        neutral50: "rgba(126, 154, 181,0.5)", //placeholder color
                                    },
                                    spacing: {
                                        ...theme.spacing,
                                        controlHeight: 58,
                                    },
                                    background: '#1B1F22'
                                })}
                                styles={customStyles}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                required />
                            <Label className={`${selectedData?.parking ? 'd-flex' : 'd-none'} floating-label-select`}>Parking</Label>
                            <Label className="label-error" style={{ marginTop: 3 }}>
                                {error.parking
                                    ? error.parking
                                    : null}
                            </Label>
                        </div>
                        <div className='ss-signup col-6'>
                            <div className="border-glow-effect" style={{ opacity: enableFieldGlow ? "1" : "0" }} />
                            <Select
                                onChange={(e) => onSelectChange(e, "floor_estimate")}
                                value={selectedData.floor_estimate ? { label: selectedData.floor_estimate, value: selectedData.floor_estimate } : null}
                                placeholder="Number of Stories"
                                options={floorEstimateOptions}
                                name="floor_estimate"
                                isDisabled={false}
                                isSearchable={true}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        text: "#1B1F22",
                                        primary: '#1B1F22', //border color
                                        neutral0: '#1B1F22', //background color
                                        neutral60: 'rgba(126, 154, 181,0.5)', //drodown color
                                        neutral80: '#ffffff', //placeholder color
                                        neutral20: 'rgba(255,255,255)', // seperator color
                                        neutral50: "rgba(126, 154, 181,0.5)", //placeholder color
                                    },
                                    spacing: {
                                        ...theme.spacing,
                                        controlHeight: 58,
                                    },
                                    background: '#1B1F22'
                                })}
                                styles={customStyles}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                required />
                            <Label className={`${selectedData?.floor_estimate ? 'd-flex' : 'd-none'} floating-label-select`}>Number of Stories</Label>
                            <Label className="label-error" style={{ marginTop: 3 }}>
                                {error.floor_estimate
                                    ? error.floor_estimate
                                    : null}
                            </Label>
                        </div>
                    </div>
                    <div className='row mb-1'>
                        <div className='col-4'>
                            <div className='ss-signup'>
                                <FormGroup floating>
                                    <Input
                                        type='number'
                                        className='fs-14 fw-400 bg-grey500 input_css'
                                        style={{ color: "#7E9AB5" }}
                                        id="gross_square_footage"
                                        placeholder=""
                                        onChange={(e) => onInputChange(e, "gross_square_footage")} />
                                    <Label className="floating-label input-label-padding-top" for="gross_square_footage">Gross Square Footage</Label>
                                </FormGroup>
                            </div>
                            <Label className="label-error" style={{ marginTop: 3 }}>
                                {error.gross_square_footage ? error.gross_square_footage : null}
                            </Label>
                        </div>
                        <div className='col-4'>
                            <div className='ss-signup'>
                                <FormGroup floating>
                                    <Input
                                        type='number'
                                        className='fs-14 fw-400 bg-grey500 input_css'
                                        style={{ color: "#7E9AB5" }}
                                        id="net_rentable_square_footage"
                                        placeholder=""
                                        onChange={(e) => onInputChange(e, "net_rentable_square_footage")} />
                                    <Label className="floating-label input-label-padding-top" for="net_rentable_square_footage">Net Square Footage</Label>
                                </FormGroup>
                            </div>
                            <Label className="label-error" style={{ marginTop: 3 }}>
                                {error.net_rentable_square_footage ? error.net_rentable_square_footage : null}
                            </Label>
                        </div>
                        <div className='col-4'>
                            <div className='ss-signup'>
                                <FormGroup floating>
                                    <Input
                                        type='number'
                                        className='fs-14 fw-400 bg-grey500 input_css'
                                        id="units"
                                        placeholder=""
                                        onChange={(e) => onInputChange(e, "no_of_units")} />
                                    <Label className="floating-label input-label-padding-top" for="units"># of Occupiable Units</Label>
                                </FormGroup>
                                <Label className='mt-2 label-error' style={{ marginTop: 3 }}>
                                    {error.no_of_units ? error.no_of_units : null}
                                </Label>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-7 d-inline-flex gap-4 mt-4'>
                            <Button
                                className='nxt-btn save-btn fs-16 fw-700 border-0'
                                onClick={() => validateForm()}
                                disabled={!selectedData?.name || !selectedData?.location || !selectedData?.project_type || error["no_of_units"] || error["gross_square_footage"]}
                                style={{ width: "170px", zIndex: 0 }}
                            >
                                <span>Save {
                                    loader
                                        ? <Spinner
                                            className={classnames({
                                                "position-relative": true,
                                                visible: loader,
                                                invisible: !loader
                                            })}
                                            size="sm"
                                        /> : null
                                }</span>
                            </Button>
                            <Button
                                className='nxt-btn fs-16 fw-700 border-0'
                                onClick={() => callbackMethod()}
                                style={{ width: "170px", zIndex: 0 }}
                            >
                                <span className='inner-nxt-btn bg-black300'>
                                    <span>Cancel</span>
                                </span>
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </Form>
    )
}

export default AddProject