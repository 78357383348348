export const EMAIL_CONSTANTS: any = {
    SUPPORT_EMAIL: 'support@lemosqto.com',
    ESTIMATOR_EMAIL: 'estimator@lemos.ai'
}

export const COST_OVERRIDE_STATUS: any = {
    ACCEPTED: 'accepted',
    REJECTED: 'rejected',
    REGENARATED: 'regenerated',
    GENERATED: 'generated',
}