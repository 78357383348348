export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_SKIP_TOUR = "SET_SKIP_TOUR";
export const SET_SKIP_COST_VIEW_TOUR = "SET_SKIP_COST_VIEW_TOUR";
export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const LOGOUT_ACTION = "LOGOUT_ACTION";

export type UserInfoState = {
    isLoggedIn: boolean;
    auth_token: {
        token: string;
        token_expire: number;
    };
    skipTour: boolean;
    skipCostViewTour: boolean;
    userDetails: {
        id: Number;
        name: string;
        email: string;
    };
    companyDetails: {
        id: string;
        name: String;
    }[]
}

export interface SetUserDetails {
    type: typeof SET_USER_DETAILS,
    payload: { userDetails: { name: string, email: string} }
}

export interface SetLoginStatus {
    type: typeof SET_LOGIN_STATUS,
    payload: { isLoggedIn: boolean }
}

export interface SetAuthToken {
    type: typeof SET_AUTH_TOKEN,
    payload: { auth_token: {token: string, token_expire: number} }
}

export interface SetSkipTour {
    type: typeof SET_SKIP_TOUR,
    payload: { skipTour: boolean }
}

export interface SetSkipCostViewTour {
    type: typeof SET_SKIP_COST_VIEW_TOUR,
    payload: { skipCostViewTour: boolean }
}

export interface SetCompanyDetails {
    type: typeof SET_COMPANY_DETAILS,
    payload: { companyDetails: {id: string, name: string} }
}

export interface logout {
    type: typeof LOGOUT_ACTION,
}

export type UserActionDispatchTypes = SetUserDetails | SetLoginStatus | SetAuthToken | SetSkipTour | SetSkipCostViewTour | SetCompanyDetails | logout