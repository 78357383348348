import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { IconClose, IconQuestionMark } from '../Icons';

interface ModalProps {
    callbackMethod: Function;
    showModal: boolean;
    onConfirm: Function;
    description: any;
}

const ConfirmModal: React.FC<ModalProps> = ({ showModal, callbackMethod, onConfirm, description }) => {
    return (
        <Modal isOpen={showModal} fade={false} contentClassName='modal_content_css' centered={true} >
            <div className='text-align-end m-4' onClick={() => callbackMethod()}>
                <IconClose />
            </div>
            <ModalBody className='text-center m-auto'>
                <div className='round-btn m-auto mb-2 p-2'>
                    <IconQuestionMark />
                </div>
                <div className='fw-700 line-height-xl mb-3 gradient-color' style={{ fontSize: "30px" }}>
                    Are you sure
                </div>
                <div className='mb-4 wcolor opacity-07 inp_header line-hieght-sm'>
                    {description}
                </div>
            </ModalBody>
            <ModalFooter className='text-center m-auto border-top-0'>
                <Button className='btn-css' style={{width: "180px", height: "50px"}} onClick={()=>onConfirm()}>Yes</Button>{' '}
                <Button className='nxt-btn border-0' style={{width: "180px", height: "50px"}} onClick={()=>callbackMethod()}><span className='inner-nxt-btn bg-black300'><span>No</span></span></Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmModal