export const PROJECT_LIST_CONST_KEYS = [
    {
        label: "NAME",
        value: "name",
        search: true,
        searchKey: "name",
    },
    {
        label: "LOCATION",
        value: "location",
        search: false,
        sort: true,
        sort_Type: "",
    },
    {
        label: "PROJECT TYPE",
        value: "project_type",
        search: false,
        sort: true,
        sort_Type: "",
    },
    {
        label: "SQ.FT",
        value: "gross_square_footage",
        sort: true,
        sort_Type: "",
    },
    {
        label: "UNITS",
        value: "no_of_units",
        sort: true,
        sort_Type: "",
    },
    {
        label: "VERSION",
        value: "version_number",
        sort: true,
        sort_Type: "",
    },
    {
        label: "LAST UPDATED",
        value: "updated_on",
        sort: true,
        sort_Type: "DESC",
    },
    {
        label: "FILE SIZE",
        value: "revit_file_size",
        sort: true,
        sort_Type: "",
    },
];

export const PROJECT_LIST_BASED_ON_USER_ROLE_CONST_KEYS = [
    {
        label: "PROJECT NAME",
        value: "name",
        search: true,
        searchKey: "name",
        sort: true,
        sort_Type: "DESC",
    },
    {
        label: "PROJECT STATUS",
        value: "project_status",
        search: false,
        sort: true,
        sort_Type: "",
    },
    {
        label: "MEMBER ROLE",
        value: "member_role",
        search: false,
        sort: true,
        sort_Type: ""
    }
]

export const PROJECT_LIST_BASED_ON_CATEGORY_AND_TAGS = [
    {
        label: "CATEGORIES",
        value: "level_name",
        search: false,
        sort: true,
        
    },
    {
        label: "QUANTITY",
        value: "unit.quantity",
        search: false,
        sort: true,
        sort_Type: "",
    },
    {
        label: "UNIT",
        value: "unit.unit",
        search: false,
        sort: true,
        sort_Type: "",
    },
    {
        label: "TAGS",
        value: "tags",
        search: false,
        sort: true,
        searchKey: "tags",
        sort_Type: "DESC",
    }
]


export const PROJECT_TYPES = [
    { value: 'Single Family Residential', label: 'Single Family Residential' },
    { value: 'Multi-Family Residential', label: 'Multi-Family Residential' },
    { value: 'Civic/Government', label: 'Civic/Government' },
    { value: 'Commercial', label: 'Commercial' },
    { value: 'Educational', label: 'Educational' },
    { value: 'Hotel/Motel', label: 'Hotel/Motel' },
    { value: 'Industrial', label: 'Industrial' },
    { value: 'Medical', label: 'Medical' },
    { value: 'Office', label: 'Office' },
    { value: 'Recreational', label: 'Recreational' },
    { value: 'Religious', label: 'Religious' }
];

export const PROJECT_STRUCTURAL_FRAME_TYPES = [
    { value: 'Wood Frame', label: 'Wood Frame' },
    { value: 'Light Gauge Steel', label: 'Light Gauge Steel' },
    { value: 'Steel', label: 'Steel' },
    { value: 'Concrete', label: 'Concrete' },
    { value: 'Mass Timber', label: 'Mass Timber' },
    { value: 'Podium with Wood Frame above', label: 'Podium with Wood Frame above' },
    { value: 'Podium with Light Gauge Steel above', label: 'Podium with Light Gauge Steel above' },
]

export const PROJECT_USE_TYPE_POPULATED_VALUES = {
    'Single Family Residential': {
        primary_structural_frame: 'Wood Frame',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Multi-Family Residential': {
        primary_structural_frame: 'Wood Frame',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Civic/Government': {
        primary_structural_frame: 'Concrete',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Commercial': {
        primary_structural_frame: 'Light Gauge Steel',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Educational': {
        primary_structural_frame: 'Steel',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Hotel/Motel': {
        primary_structural_frame: 'Steel',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Industrial': {
        primary_structural_frame: 'Steel',
        parking: 'Above Grade Structured Parking',
        floor_estimate: '3 or less'
    },
    'Medical': {
        primary_structural_frame: 'Steel',
        parking: 'Above Grade Structured Parking',
        floor_estimate: '3 or less'
    },
    'Office': {
        primary_structural_frame: 'Light Gauge Steel',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Recreational': {
        primary_structural_frame: 'Steel',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    },
    'Religious': {
        primary_structural_frame: 'Wood Frame',
        parking: 'No Parking or Surface Parking',
        floor_estimate: '3 or less'
    }
}

export const PROJECT_NUMBER_OF_STORIES_TYPES = [
    { value: "3 or less", label: "3 or less" },
    { value: "4 to 8", label: "4 to 8" },
    { value: "9 to 12", label: "9 to 12" },
    { value: "13 or more", label: "13 or more" }
]

export const PROJECT_PARKING_TYPES = [
    { value: "No Parking or Surface Parking", label: "No Parking or Surface Parking",  above_grade_structured_parking: false, below_grade_parking: false },
    { value: "Below Grade Parking Structure", label: "Below Grade Parking Structure",  above_grade_structured_parking: false, below_grade_parking: true  },
    { value: "Above Grade Structured Parking", label: "Above Grade Structured Parking",  above_grade_structured_parking: true, below_grade_parking: false  },
    { value: "Both above and below grade structured parking", label: "Both above and below grade structured parking",  above_grade_structured_parking: true, below_grade_parking: true  }
]

export const PROJECT_PARKING_VALUES = {
    'No Parking or Surface Parking': {
        below_grade_parking: false,
        above_grade_structured_parking: false
    },
    'Below Grade Parking Structure': {
        below_grade_parking: true,
        above_grade_structured_parking: false
    },
    'Above Grade Structured Parking': {
        below_grade_parking: false,
        above_grade_structured_parking: true
    },
    'Both above and below grade structured parking': {
        below_grade_parking: true,
        above_grade_structured_parking: true
    }
}