import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { IconClose, IconDownload, IconSuccess2 } from '../Icons';
import { IconPlus, PopUpBackButton } from '../Icons/index';
import { hsqPush } from '../../../utils/helpers';
import './CreateProjectSuccessModal.scss'

interface ModalProps {
    callbackMethod: Function;
    showModal: boolean;
    myHandler: Function;
}

const CreateProjectSuccessModal: React.FC<ModalProps> = ({ showModal, callbackMethod, myHandler }) => {

    const _myHandler = () => {
        hsqPush('invite_clicked', {}, true)
        myHandler()
    }

    return (
        <Modal isOpen={showModal} fade={false} size="lg" contentClassName='project-success-modal modal_content_css' >
            <div className='text-align-end me-2 cursor-pointer close-button'>
                <div className='text-align-end m-4 cursor-pointer' onClick={() => callbackMethod()}>
                    <IconClose />
                </div>
            </div>
            <ModalBody className='text-center m-auto mb-6'>
                <div className='m-auto mb-2 p-2'>
                    <IconSuccess2 />
                </div>
                <div className='fw-700 line-height-xl mb-2' style={{ fontSize: "30px" }}>
                    Project Created Successfully!
                </div>
            </ModalBody>
            <div style={{width: "83%" }} className='m-auto mb-8'>
                <div className='mb-2'>Invite team members</div>
                <div className='d-flex justify-content-between fs-12' >
                    <div style={{ color: "gray" }} >
                        Invite a team member to your project by adding their email address
                    </div>
                    <div style={{ paddingLeft: "30px", display: "flex", flexDirection: "row", justifyContent: "space-between", paddingRight: "15px", paddingBottom: "0px", alignItems: "center", cursor: "pointer" }} onClick={() => _myHandler()}>
                        <IconPlus /><div style={{ marginLeft: "5px" }}>Invite</div>
                    </div>
                </div>
                <hr className="mt-4 mb-4"/>
                <div className='mb-2'>Connect your Revit model</div>
                <div className='d-flex justify-content-between fs-12 mb-2' >
                    <div style={{ color: "gray" }} className='fs-12'>
                        Use the LEMOS Add-in from your Revit toolbar to add model
                    </div>
                    <div style={{ paddingLeft: "30px", display: "flex", flexDirection: "row", justifyContent: "space-between", paddingRight: "15px", paddingBottom: "0px", alignItems: "center", cursor: "pointer" }} onClick={() => { window.open('https://www.lemosqto.com/downloadaddin') }}>
                        <IconDownload /><div style={{ marginLeft: "5px" }}>Download Revit Add-in</div>
                    </div>
                </div>
            </div>
            <div className='border-bottom-gradient'></div>
        </Modal>
    )
}

export default CreateProjectSuccessModal