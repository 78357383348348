import React, { useState, useEffect } from 'react';
import { Modal, Button, InputGroup, Spinner } from 'reactstrap';
import { IconClose, IconDownArrowSmall, LemosEstimatorIcon } from '../Icons';
import classnames from 'classnames';
import { toTitleCase } from '../../../utils/helpers';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { API_ROUTES } from '../../../services/api.constants';
import { validateUserData } from '../../../services/baseApi.service';
import { toast } from 'react-toastify';
import './InviteStyles.scss';
import { hsqPush } from '../../../utils/helpers';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import MagicButton from '../MagicButton/MagicButton';
import { EMAIL_CONSTANTS } from '../../../services/constant';
import ConfirmModal from './ConfirmModal';
import { useSelector } from 'react-redux';
import { selectUserDetails } from '../../../redux/user/selectors';

interface ModalProps {
  callbackMethod: Function;
  showModal: boolean;
  myHandler: Function;
  projectID: any;
  handleEstimatorButtonClick?: Function;
  projectName?: string;
  removeEstimator?: any;
}

interface User {
  email: string;
  role: Role;
  project_id: number;
  project_member_role: string;
  project_role: string | null;
  is_invited_accepted: boolean;
}

interface Role {
  id: number;
  name: string;
}

const accessRoleOptions = [
  { value: 'EDITOR', label: 'Can edit' },
  { value: 'VIEWER', label: 'Can view' },
  { value: 'OWNER', label: 'Owner' },
  { value: 'REMOVE', label: 'Remove' },
];

const projectRoleOptions = [
  'Architect',
  'Consultant',
  'Contractor',
  'Developer',
  'Subcontractor',
  'Other',
];

const InviteModel: React.FC<ModalProps> = ({
  showModal,
  callbackMethod,
  myHandler,
  projectID,
  handleEstimatorButtonClick,
  projectName,
  removeEstimator
}) => {
  const [userList, setUserList] = useState<any>([]);
  const [usersData, setUsersData] = useState<any>([]);
  const [selectedRole, setSelectedRole] = useState<any>(accessRoleOptions[0]);
  const [emails, setEmails] = React.useState<string[]>([]);
  const [focused, setFocused] = React.useState(false);
  const [projectRole, setProjectRole] = useState<string | null>();
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  const [shouldScrollEmails, setShouldScrollEmails] = useState<boolean>(false);
  const [projectAccessRole, setProjectAccessRole] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [estimatorInvited, setEstimatorInvited] = useState<boolean | null>(
    null
  );
  const [userRemoved, setUserRemoved] = useState<boolean>(false);
  const [projectOwner, setProjectOwner] = useState<any>(null)
  const [currentUserCanEdit, setCurrentUserCanEdit] = useState<any>(null)
  const userDetails = useSelector(selectUserDetails);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState<Function | null>(null);
  const [description, setDescription] = useState('');

  const useDropdown = (initialState = false) => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState);

    const toggleDropdown = () => setIsOpen((prevState: any) => !prevState);

    return [isOpen, toggleDropdown] as const;
  };

  const [dropdownOpen, toggleDropdown] = useDropdown(false);
  const [projectDropdownOpen, toggleProjectDropdown] = useDropdown(false);

  const [accessDropdownStates, setAccessDropdownStates] = useState(
    userList.map(() => false)
  );

  const toggleAccessDropdown = (index: number) => {
    setAccessDropdownStates((prevStates: any) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const sendInvite = async () => {
    setLoader(true);
    const tokenDetails: any = localStorage.getItem('tokenDetails');
    const token = JSON.parse(tokenDetails)?.token;
    const url = API_ROUTES.INVITE_USERS;

    const reqData = emails.map((email) => {
      return {
        email: email,
        role: selectedRole.value,
        project_role: projectRole?.toUpperCase(),
        project_id: projectID,
      };
    });

    const header = { Authorization: 'Bearer ' + token || '' };
    await validateUserData(url, reqData, 'POST', header)
      .then((response: any) => {
        if (response && !response.error && response.response) {
          hsqPush('invite_completed', {}, true);
          toast.success(response.response[0].message);
          callbackMethod();
        } else {
          if (response.errorMessages.length) {
            if (Object.keys(response.errorMessages[0]).includes('other')) {
              toast.error(response.errorMessages[0].other);
            } else if (response.code === 403) {
              if (
                response.errorMessages &&
                Array.isArray(response.errorMessages)
              )
                response.errorMessages.forEach((element: any) => {
                  toast.error(`${Object.values(element)[0]}`);
                });
            } else {
              toast.error(response.errorMessages[0].user);
            }
          }
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const updateTeamMemberPermissions = async (
    newRole: any,
    userID: number,
    projectID: number
  ) => {
    const tokenDetails: any = localStorage.getItem('tokenDetails');
    const token = JSON.parse(tokenDetails)?.token;
    const url = API_ROUTES.INVITE_USERS;

    const request = {
      role: newRole,
      id: userID,
      project_id: projectID,
    };
    const header = { Authorization: 'Bearer ' + token || '' };
    await validateUserData(url, request, 'PUT', header)
      .then((response: any) => {
        if (response && !response.error && response.response) {
          hsqPush('access_permission_updated', {}, true);
          toast.success(response.response);
        } else {
          if (response.errorMessages.length) {
            if (Object.keys(response.errorMessages[0]).includes('other')) {
              toast.error(response.errorMessages[0].other);
            } else if (response.code === 403) {
              if (
                response.errorMessages &&
                Array.isArray(response.errorMessages)
              )
                response.errorMessages.forEach((element: any) => {
                  toast.error(`${Object.values(element)[0]}`);
                });
            } else {
              toast.error(response.errorMessages[0].user);
            }
          }
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const removeProjectUser = async (userId: any, email: any) => {
    const tokenDetails: any = localStorage.getItem('tokenDetails');
    const token = JSON.parse(tokenDetails)?.token;
    const url = API_ROUTES.INVITE_USERS;
    const request = {
      project_id: projectID,
      id: userId,
      email: email,
    };
    const header = { Authorization: 'Bearer ' + token || '' };

    await validateUserData(url, request, 'DELETE', header)
      .then((response: any) => {
        if (response && !response.error && response.response) {
          if (email == EMAIL_CONSTANTS.ESTIMATOR_EMAIL) {
            setEstimatorInvited(false);
            removeEstimator()
          }
          hsqPush('project_user_removed_from_project', {}, true);
          toast.success(response.response);
        } else {
          if (response.errorMessages.length) {
            if (Object.keys(response.errorMessages[0]).includes('other')) {
              toast.error(response.errorMessages[0].other);
            } else if (response.code === 403) {
              if (
                response.errorMessages &&
                Array.isArray(response.errorMessages)
              )
                response.errorMessages.forEach((element: any) => {
                  toast.error(`${Object.values(element)[0]}`);
                });
            } else {
              toast.error(response.errorMessages[0].user);
            }
          }
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const sortProjectUsers = (users: any) => {
    const ownerIndex = users.findIndex(
      (user: { project_member_role: string }) =>
        user.project_member_role === 'OWNER'
    );

    setProjectOwner(users[ownerIndex])
    if (ownerIndex !== -1) {
      const ownerUser = users.splice(ownerIndex, 1)[0];
      users.unshift(ownerUser);
    }

    users.sort((a: { project_role: string }, b: { project_role: string }) => {
      if (a.project_role && b.project_role) {
        return a.project_role.localeCompare(b.project_role);
      } else {
        return 0;
      }
    });

    const estimatorIndex = users.findIndex(
      (user: { email: string }) => user.email === EMAIL_CONSTANTS.ESTIMATOR_EMAIL
    );
    if (estimatorIndex !== -1) {
      const estimatorUser = users.splice(estimatorIndex, 1)[0];
      users.push(estimatorUser);
    }

    return users;
  };

  const resetAccess = () => {
    if (userRemoved && projectAccessRole) {
      setUserRemoved(false);
      setProjectAccessRole(null);
    } else if (projectAccessRole) {
      setProjectAccessRole(null);
    } else if (userRemoved) {
      setUserRemoved(false);
    }
  };

  const getUserMailIDs = async () => {
    const tokenDetails: any = localStorage.getItem('tokenDetails');
    const token = JSON.parse(tokenDetails)?.token;
    const url = `${API_ROUTES.GET_PROJECT_USERS}/${projectID}`;
    const header = { Authorization: 'Bearer ' + token || '' };
  
    await validateUserData(url, {}, 'GET', header)
      .then((response: any) => {
        if (response) {
          if (!response.error && response.response && response.response.users) {
            const users = response.response.users;
            const invitedUsers = sortProjectUsers(users);
            setUserList(invitedUsers);
            setEstimatorInvited(
              invitedUsers.find(
                (user: any) => user.email === EMAIL_CONSTANTS.ESTIMATOR_EMAIL
              )
                ? true
                : false
            );
            const currentUser = invitedUsers.find((user: any) => user.id === userDetails.id);
            const canEdit = currentUser && (currentUser.project_member_role === 'EDITOR' || currentUser.project_member_role === 'OWNER');
  
            setCurrentUserCanEdit(canEdit);
            setUsersData(invitedUsers?.map((user: User) => user.email));
            invitedUsers && invitedUsers.length > 5
              ? setShouldScroll(true)
              : setShouldScroll(false);
            resetAccess();
          } else {
            setUserList([]);
            toast.error('Something went wrong');
          }
        } else {
          toast.error('Something went wrong');
        }
      })
      .catch((error) => {
        console.log('response', error);
      });
  };

  const _myHandler = () => {
    hsqPush('invite_clicked', {}, true);
    myHandler();
  };

  const handleConfirmAction = () => {
    if (confirmAction) {
      confirmAction(); 
      setShowConfirmModal(false); 
      setConfirmAction(null); 
      setDescription('');
    }
  };

  const handleRoleChange = (
    newAccessRole: any,
    userId: number,
    project_member_role: any,
    email: any,
    projectName: any
  ) => {
    const confirmActionHandler = (action: Function, desc: string) => {
      setDescription(desc);
      setConfirmAction(() => action);
      setShowConfirmModal(true);
    };
    if (newAccessRole === project_member_role) {
      return;
    }
    if (newAccessRole !== 'REMOVE') {
      setProjectAccessRole(newAccessRole);
      updateTeamMemberPermissions(newAccessRole, userId, projectID);
    } else {
      if (email == EMAIL_CONSTANTS.ESTIMATOR_EMAIL) {
        setUserRemoved(true);
        removeProjectUser(userId, email);
      } else {
        const confirmAction = () => {
          setUserRemoved(true);
          removeProjectUser(userId, email);
        };
        const description = `Are you sure you want to remove team member from ${projectName}?`;
        confirmActionHandler(confirmAction, description);
      }
    }
  };

  useEffect(() => {
    hsqPush('setPath', `${window.location.pathname}?invite-dialog`);
    hsqPush('trackPageView');
    getUserMailIDs();
  }, [projectAccessRole, userRemoved]);

  return (
    <Modal
      isOpen={showModal}
      fade={false}
      size='lg'
      contentClassName='invite-modal'
      style={{
        width: '731px',
      }}
    >
      <div className='d-flex mb-4 header-container'>
        <div className='invite-header'>Invite project members</div>
        <div className='close-icon-container' onClick={() => callbackMethod()}>
          <IconClose className='icon-close' />
        </div>
      </div>
      <div>
        <div className='d-flex mb-3 invite-container-parent'>
          <div className='invite-container'>
            <div className='email-input-container'>
              <InputGroup className='email-input-group'>
                <ReactMultiEmail
                  className={`react-email-container ${
                    shouldScrollEmails && 'email-scroll'
                  }`}
                  placeholder='Email, comma separated'
                  emails={emails}
                  onChange={(_emails: string[]) => {
                    setEmails(_emails);
                    _emails.length >= 3
                      ? setShouldScrollEmails(true)
                      : setShouldScrollEmails(false);
                  }}
                  autoFocus={true}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
                <Dropdown
                  className='access-role-dropdown'
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                >
                  <DropdownToggle className='custom-access-toggle'>
                    <div className='selected-role-box'>
                      {selectedRole
                        ? selectedRole.label.toLowerCase()
                        : accessRoleOptions[0].label.toLowerCase()}
                    </div>
                    <div>
                      <span className='access-icon-down'>
                        <IconDownArrowSmall />
                      </span>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className='custom-access-dropdown-menu'>
                    {accessRoleOptions.map((option, index) => {
                      if (
                        option.label !== 'Owner' &&
                        option.label !== 'Remove'
                      ) {
                        return (
                          <DropdownItem
                            key={`access-role-${index}`}
                            className='custom-dropdown-item-overrides access-table-dropdown-item'
                            onClick={() => setSelectedRole(option)}
                          >
                            <div className='access-item-label-container'>
                              <div className='access-label'>
                                {option.label.toLowerCase()}
                              </div>
                              <div>
                                <span className='right-arrow'>{'>'}</span>
                              </div>
                            </div>
                          </DropdownItem>
                        );
                      }
                    })}
                  </DropdownMenu>
                </Dropdown>
              </InputGroup>
            </div>
            <Dropdown
              isOpen={projectDropdownOpen}
              toggle={toggleProjectDropdown}
            >
              <DropdownToggle className='custom-project-dropdown-toggle'>
                <div className='project-role-box'>
                  {projectRole ? projectRole : 'Role'}
                </div>
                <div>
                  <span className='project-icon-down'>
                    <IconDownArrowSmall />
                  </span>
                </div>
              </DropdownToggle>
              <DropdownMenu className='custom-dropdown-menu'>
                {projectRoleOptions.map((projectRole, index) => {
                  return (
                    <DropdownItem
                      key={`project-role-${index}`}
                      className='project-dropdown-item'
                      onClick={() => setProjectRole(projectRole)}
                    >
                      <div className='project-item-container'>
                        <div className='project-role-text'>{projectRole}</div>
                        <div>
                          <span>{'>'}</span>
                        </div>
                      </div>
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
            <Button
              disabled={!emails.length}
              className='invite-project-button'
              onClick={() => sendInvite()}
            >
              <span>
                Invite
                {loader ? (
                  <Spinner
                    style={{ marginLeft: '3px' }}
                    className={classnames({
                      'position-relative': true,
                      visible: loader,
                      invisible: !loader,
                    })}
                    size='sm'
                  />
                ) : null}
              </span>
            </Button>
          </div>
        </div>
      </div>
      {estimatorInvited === false && (
        <MagicButton
          onClick={() => {
            handleEstimatorButtonClick && handleEstimatorButtonClick();
          }}
        />
      )}
      <div className='mt-3 members-container'>
        <div className='title-container'>
          <div className='manage-text'>Manage</div>
        </div>
        <div className='d-flex justify-content-between fs-12 manage-column-header'>
          <div className='column-name'>Members</div>
          <div className='role-container'>
            <div
              className='d-flex role-div'
              onClick={() => _myHandler()}
              style={{ cursor: 'pointer' }}
            >
              <div className='fs-12 column-name role-text'>Role</div>
            </div>
            <div
              className='d-flex access-div'
              onClick={() => _myHandler()}
              style={{ cursor: 'pointer' }}
            >
              <div className='fs-12 column-name access-text'>Access</div>
            </div>
          </div>
        </div>
        <hr className='table-border' />
      </div>

      <div className='mb-5'>
        <div className={`user-list-container ${shouldScroll ? 'scroll' : ''}`}>
          {userList &&
            userList.map((user: any, index: number) => (
              <div key={`user-invite-${index}`} className='invited-user-card'>
                {user.fullName === 'Estimator LemosQTO.ai' ||
                user.email === EMAIL_CONSTANTS.ESTIMATOR_EMAIL ? (
                  <div className='d-flex align member-name-container'>
                    <div className='icon-name-container'>
                      <div className='estimator-icon-container'>
                        <div className='estimator-icon'>
                          <LemosEstimatorIcon />
                        </div>
                      </div>
                      <h1 className='ml-2 user-name'>
                        LEMOS Estimator
                      </h1>
                    </div>
                    <div className='pending-text-container'>
                      {(!user.is_invite_accepted &&
                        user.project_member_role !== 'OWNER') ||
                        (user.email === EMAIL_CONSTANTS.ESTIMATOR_EMAIL && (
                          <span className='pending-text'>
                            pending acceptance
                          </span>
                        ))}
                    </div>
                  </div>
                ) : user.fullName !== '' ? (
                  <div className='d-flex align member-name-container'>
                    <div className='icon-name-container'>
                      <div className='activity-icon'>
                        {user.fullName !== '' && user.fullName[0].toUpperCase()}
                      </div>
                      <h1 className='ml-2 user-name'>
                        {user.fullName !== '' && user.fullName}
                      </h1>
                    </div>
                    <div className='pending-text-container'>
                      {!user.is_invite_accepted &&
                        user.project_member_role !== 'OWNER' && (
                          <span className='pending-text'>
                            pending acceptance
                          </span>
                        )}
                    </div>
                  </div>
                ) : (
                  <div className='d-flex align member-name-container'>
                    <div className='icon-name-container'>
                      <div className='activity-icon'>
                        {user.email !== '' && user.email[0].toUpperCase()}
                      </div>
                      <h1 className='ml-2 user-email'>
                        {user.email !== '' && user.email}
                      </h1>
                    </div>
                    <div className='pending-text-container'>
                      {!user.is_invite_accepted &&
                        user.project_member_role !== 'OWNER' && (
                          <span className='pending-text'>
                            pending acceptance
                          </span>
                        )}
                    </div>
                  </div>
                )}
                <div className='role-text-container'>
                  <div className='project-role-container'>
                    <h1 className='project-role-table-text'>
                      {user?.project_role !== null &&
                        toTitleCase(user.project_role)}
                    </h1>
                  </div>
                  <div className='access-role-container'>
                    {user.fullName === 'LEMOS Estimator' ||
                    user.email === EMAIL_CONSTANTS.ESTIMATOR_EMAIL ? (
                      <Dropdown
                        isOpen={accessDropdownStates[index]}
                        toggle={() => toggleAccessDropdown(index)}
                      >
                        <DropdownToggle className='table-access-toggle'>
                          <div>
                            <h1 className='access-role-table-text'>can edit</h1>
                          </div>
                          <div className='table-access-icon-container'>
                            <span className='access-icon-down'>
                              <IconDownArrowSmall />
                            </span>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className='access-table-dropdown-menu'>
                          {accessRoleOptions.map((option, index) => {
                            if (
                              option.label !== 'Owner' &&
                              option.label !== 'Can view'
                            ) {
                              const isDisabled = option.label === 'Can edit';
                              return (
                                <DropdownItem
                                  key={`access-role-${index}`}
                                  className={`custom-dropdown-item-overrides access-table-dropdown-item ${
                                    isDisabled ? 'disabled' : ''
                                  }`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (!isDisabled) {
                                      handleRoleChange(
                                        option.value,
                                        user.id,
                                        user.project_member_role,
                                        user.email,
                                        projectName
                                      );
                                    }
                                  }}
                                  disabled={isDisabled}
                                >
                                  <div className='access-item-label-table-container'>
                                    <div className='access-label-table'>
                                      {option.label.toLowerCase()}
                                    </div>
                                    <div>
                                      <span className='right-arrow-access-table'>
                                        {'>'}
                                      </span>
                                    </div>
                                  </div>
                                </DropdownItem>
                              );
                            }
                            return null;
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    ) : user.project_member_role === 'OWNER' ? (
                      <h1 className='access-role-table-text owner-label'>
                        {user.project_member_role.toLowerCase()}
                      </h1>
                    ) : (
                      <Dropdown
                        isOpen={accessDropdownStates[index]}
                        toggle={() => toggleAccessDropdown(index)}
                      >
                        <DropdownToggle className='table-access-toggle'>
                          <div>
                            {
                              <h1 className='access-role-table-text'>
                                {user.project_member_role === 'EDITOR'
                                  ? 'can edit'
                                  : user.project_member_role === 'VIEWER'
                                  ? 'can view'
                                  : null}
                              </h1>
                            }
                          </div>
                          <div className='table-access-icon-container'>
                            <span className='access-icon-down'>
                              <IconDownArrowSmall />
                            </span>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className='access-table-dropdown-menu'>
                          {accessRoleOptions.map((option, index) => {
                            if (option.label !== 'Owner') {
                              const isDisabled = !currentUserCanEdit;
                              return (
                                <DropdownItem
                                  key={`access-role-${index}`}
                                  className={`custom-dropdown-item-overrides access-table-dropdown-item ${isDisabled ? 'disabled' : ''}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if(!isDisabled) {
                                      handleRoleChange(
                                        option.value,
                                        user.id,
                                        user.project_member_role,
                                        user.email,
                                        projectName
                                      );
                                    }
                                  }}
                                  disabled={isDisabled}
                                >
                                  <div className='access-item-label-table-container'>
                                    <div className='access-label-table'>
                                      {option.label.toLowerCase()}
                                    </div>
                                    <div>
                                      <span className='right-arrow-access-table'>
                                        {'>'}
                                      </span>
                                    </div>
                                  </div>
                                </DropdownItem>
                              );
                            }
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                    <ConfirmModal
                      showModal={showConfirmModal}
                      callbackMethod={() => setShowConfirmModal(false)}
                      onConfirm={handleConfirmAction}
                      description={description}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default InviteModel;
