const baseUrl = process.env.REACT_APP_API_BASE_ENDPOINT;

export const makeRequestAuth = (reqData:any, extraHeaders:any, callback:any) => {
    const { variables: {add_data = {}} = {}, url, type } = reqData

    let headers = {
        "Content-Type": "application/json",
    };

    if(!extraHeaders){
        extraHeaders = {}
    }
    
    let getFetchData = {}
    if(type === "GET" || type === "PATCH"){
        getFetchData ={
            method: type,
            headers: {...headers, ...extraHeaders},
        }
    } else {
        getFetchData = {
            method: type,
            body: JSON.stringify(add_data || {}),
            headers: {...headers, ...extraHeaders},
        }
    }
    fetch(url, {...getFetchData})
        .then(function (res) {
            return res.json();
        })
        .catch(function (error) {
            callback({ error: 1, error_msg: error });
        })
        .then(function (response) {
            callback(response);
        });
};

/*
* @description : makeBaseUrl
* @param string endPoint
* @returns string
*/

export const makeBaseUrl = (endPoint: string): string => {
 return `${baseUrl}${endPoint}`;
};