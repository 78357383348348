import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Container, Row, Col } from "reactstrap"
import { ROUTE_PATHS } from "./commons/constants/routes.constants";
import { PageLoader, Signup, ValidateOTP, Login, ForgotPassword, CreatePassword } from "./commons/constants/loadableImports";
import Sidebar from "./commons/components/sidebar";
import PrivateRoutes from "./privateRoutes";
import Welcome from "./commons/components/Welcome"
import Header from "./commons/components/Header"
import { runLogoutTimer } from "./services/auth.service";
import { useDispatch, useSelector } from "react-redux";


interface PageLayoutProps {
    children: any;
}

interface RoutesProps {
    isLoggedIn: boolean;
    skipTour: boolean;
    skipCostViewTour: boolean;
    auth_token: any;
    userDetails: any;
    companyDetails: any;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const tokenDetails:any = localStorage.getItem('tokenDetails');
        const timer = (JSON.parse(tokenDetails)?.loginTime)/1000-(new Date().getTime()/1000)+(JSON.parse(tokenDetails)?.token_expire)
        runLogoutTimer(dispatch, Math.round(timer)*1000);
    }, [])
    
    return(
    <Container fluid>
        <Row>
            <div className="bg-black p-0 zIndexHigh h-100 position-fixed" style={{width: "160px" , top: 0}}>
                <Sidebar />
            </div>
            <div className="bg-black400 pt-0 min-vh-100 position-relative h-100 overflow-hidden" style={{marginLeft: "160px"}}>
                {children}
            </div>
        </Row>
    </Container>
)};

const SignUpLayout: React.FC<PageLayoutProps> = ({ children }) => (
    <Container className="vh-100" fluid>
        <Row>
            <Col xs="5" className="bg-black ps-0 min-vh-100 pageFixed-css">
                <Welcome />
            </Col>
            <Col xs="7" className='bg-semi-black min-vh-100 page-overflow'>
                {children}
            </Col>
        </Row>
    </Container>
);


const RoutesApp : React.FC<RoutesProps> = ({ isLoggedIn, auth_token, skipTour, skipCostViewTour, userDetails, companyDetails }) => {
    const location = useLocation();
    const searchParam = location.search;

    return (
        <Suspense fallback={<PageLoader />}>
            {isLoggedIn ?
                <PageLayout>
                    <PrivateRoutes isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} skipCostViewTour={skipCostViewTour} companyDetails={companyDetails} userDetails={userDetails} />
                </PageLayout>
                :
                <SignUpLayout>
                    <Routes>
                        <Route
                            path={`${ROUTE_PATHS.SIGNUP}`}
                            element={<Signup isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} />}
                        />
                        {/* <Route
                            path={`${ROUTE_PATHS.VALIDATE_OTP}`}
                            element={<ValidateOTP isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} userDetails={userDetails} />}
                        /> */}
                        <Route
                            path={`${ROUTE_PATHS.LOGIN}`}
                            element={<Login isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} userDetails={userDetails} />}
                        />
                        <Route
                            path={`${ROUTE_PATHS.FORGOT_PASSWORD}`}
                            element={<ForgotPassword isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} />}
                        />
                        <Route
                            path={`${ROUTE_PATHS.CREATE_PASSWORD}`}
                            element={<CreatePassword isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={`${searchParam ? '/' + searchParam : '/'}`} replace />}
                        />
                    </Routes>
                </SignUpLayout>
            }
        </Suspense>
    )
}

export default RoutesApp

