import React from 'react'
import { BsQuestionSquare, BsPlusLg } from 'react-icons/bs';
import { Button, Nav, NavItem } from "reactstrap"
import SubscriptionSidebarNavItem from '../Subscription/SubscriptionSidebarNavItem';

interface SidebarFooterProps {
  onNewProject: Function;
}

const SidebarFooter: React.FC<SidebarFooterProps> = ( { onNewProject }) => {
  const howToVideoOnClick = () => {
    window.open('https://www.lemosqto.com/resources')
  }

  return (
    <>
      <Nav className="nav-stacked nav-tabs nav-footer" style={{ userSelect: "none", marginTop: '-15px' }}>
        <NavItem>
          <SubscriptionSidebarNavItem />
        </NavItem>
        <NavItem>
          <Button className="nav-link sidebar-footer wcolor fw-500 fs-12 mb-0 how-to-video" onClick={() => onNewProject()}>
            <div style={{ display: "inline-flex", alignItems: 'center' }}>
              <BsPlusLg style={{ width: '20px', height: '20px' }} />
              <span style={{ paddingLeft: "5px", marginTop: "3px", opacity: ".6" }}>New Project</span>
            </div>
          </Button>
        </NavItem>
        <NavItem>
          <Button className="nav-link sidebar-footer wcolor fw-500 fs-12 mb-0 how-to-video" onClick={howToVideoOnClick}>
            <div style={{ display: "inline-flex" }}>
              <div className="notification-circle"></div>
              <BsQuestionSquare style={{ fontSize: "22px", marginLeft: "2px" }} />
              <span style={{ paddingLeft: "5px", marginTop: "3px", opacity: ".6" }}>How-To Video</span>
            </div>
          </Button>
        </NavItem>
      </Nav>
    </>
  )
}

export default SidebarFooter