import { ACL_MODULE_MAPPING } from "../commons/constants/routes.constants";
import ACCOUNTS_SUB_NAV from "./subNavList";
import {
    IconPortfolio,
    IconProjectView,
    IconCompare,
    // IconSupport,
    // IconSetting
    CostViewIcon
} from "../commons/components/Icons";


interface NavLink {
    name: string;
    link: string;
    icon: (props: any) => JSX.Element;
    moduleId: number;
    subModules?: {
        name: string,
        link: string,
    }[];
}

export default [
    {
        name: "Portfolio",
        link: "/dashboard",
        icon: IconPortfolio,
        moduleId: ACL_MODULE_MAPPING.PORTFOLIO,
    },
    {
        name: "Cost view",
        link: "/cost-view",
        icon: CostViewIcon,
        moduleId: ACL_MODULE_MAPPING.COST_VIEW,
    },
    {
        name: "Model view",
        link: "/model-view",
        icon: IconProjectView,
        moduleId: ACL_MODULE_MAPPING.PROJECT_VIEW,
    },
    {
        name: "Compare",
        link: "/compare",
        icon: IconCompare,
        moduleId: ACL_MODULE_MAPPING.COMPARE,
    },
    // {
    //     name: "Support",
    //     link: "/support",
    //     icon: IconSupport,
    //     moduleId: ACL_MODULE_MAPPING.SUPPORT,
    // },
    // {
    //     name: "Management",
    //     link: "/management",
    //     icon: IconSetting,
    //     moduleId: ACL_MODULE_MAPPING.MANAGEMENT,
    //     subModules: ACCOUNTS_SUB_NAV,
    // },
] as NavLink[];
