import Loadable from "react-loadable";
import Loader from "../components/Loader";

export const PageNotFound = Loadable({
  loader: () => import("../components/PageNotFound"),
  loading: Loader,
});

export const PageLoader = Loadable({
  loader: () => import("../components/Loader/PageLoader"),
  loading: Loader,
});

export const Signup = Loadable({
  loader: () => import("../../pages/signup"),
  loading: Loader,
});

export const ValidateOTP = Loadable({
  loader: () => import("../../pages/signup/components/ValidateOtp"),
  loading: Loader,
});

export const Login = Loadable({
  loader: () => import("../../pages/login"),
  loading: Loader,
});

export const Stripe = Loadable({
  loader: () => import("../../pages/stripe"),
  loading: Loader,
});

export const ForgotPassword = Loadable({
  loader: () => import("../../pages/login/components/ForgotPassword"),
  loading: Loader,
});

export const CreatePassword = Loadable({
  loader: () => import("../../pages/login/components/CreatePassword"),
  loading: Loader,
});

export const Dashboard = Loadable({
  loader: () => import("../../modules/Portfolio"),
  loading: Loader,
});

export const Profile = Loadable({
  loader: () => import("../../modules/UserProfile"),
  loading: Loader,
});

export const ProjectView = Loadable({
  loader: () => import("../../modules/ProjectView"),
  loading: PageLoader,
});

export const ProjectCategoriesMapping = Loadable({
  loader: () => import("../../modules/ProjectMapping"),
  loading: PageLoader
})

export const Compare = Loadable({
  loader: () => import("../../modules/Compare"),
  loading: PageLoader,
});

export const Support = Loadable({
  loader: () => import("../../modules/Support"),
  loading: PageLoader,
});

export const UserManagement = Loadable({
  loader: () => import("../../modules/UserManagement"),
  loading: PageLoader,
});

export const PaymentInfo = Loadable({
  loader: () => import("../../modules/PaymentInfo"),
  loading: PageLoader,
});

export const CostView = Loadable({
  loader: () => import("../../modules/CostView"),
  loading: PageLoader,
});
