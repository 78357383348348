import React from 'react';
import { Button } from 'reactstrap';
import { MagicButtonSparkleIcon } from '../Icons';
import './MagicButton.scss';

interface Props {
  onClick: () => void;
}

const MagicButton: React.FC<Props> = ({ onClick }) => {
  return (
    <div className='magic-button-container'>
      <Button color='primary' onClick={onClick} className='magic-button'>
        <div className='magic-icon'>
          <MagicButtonSparkleIcon />
        </div>
        <span className='magic-invite-text'>Invite LEMOS estimator</span>
      </Button>
      <span className='tooltiptext'>The LEMOS estimator can generate costs for your project. You can always make changes.</span>
    </div>
  );
};

export default MagicButton;
