import React, { Component } from "react";
import "./loaders.scss";

class Loader extends Component {
  render() {
    const styles: any = {
      position: "relative",
      minHeight: "100px",
      textAlign: "center",
      marginTop: "20px",
    };

    return (
      <div className="col-lg-12 compLoader" style={styles}>
        <div className="loading">
          <img src="/img/loading.gif" alt="loader" width="30" height="30" />
        </div>
      </div>
    );
  }
}

export const FullPageLoader = () => {
  return <div className="fullscreenLoader">Loading&#8230;</div>;
};

export default Loader;
