import { SET_AUTH_TOKEN, SET_SKIP_TOUR, SET_SKIP_COST_VIEW_TOUR, SET_LOGIN_STATUS, SET_USER_DETAILS, SET_COMPANY_DETAILS, LOGOUT_ACTION, UserActionDispatchTypes } from "./types";

export const setLoginStatus = (isLoggedIn: boolean): UserActionDispatchTypes => {
  return {
    type: SET_LOGIN_STATUS,
    payload: { isLoggedIn },
  }
};

export const setAuthToken = (auth_token: any): UserActionDispatchTypes => {
  return {
    type: SET_AUTH_TOKEN,
    payload: { auth_token },
  }
};

export const setSkipTour = (skipTour: boolean): UserActionDispatchTypes => {
  return {
    type: SET_SKIP_TOUR,
    payload: { skipTour },
  }
};

export const setSkipCostViewTour = (skipCostViewTour: boolean): UserActionDispatchTypes => {
  return {
    type: SET_SKIP_COST_VIEW_TOUR,
    payload: { skipCostViewTour },
  }
}

export const setUserDetails = (userDetails: any): UserActionDispatchTypes => {
  return {
    type: SET_USER_DETAILS,
    payload: { userDetails: userDetails },
  }
};

export const setCompanyDetails = (companyDetails: any): UserActionDispatchTypes => {
  return {
    type: SET_COMPANY_DETAILS,
    payload: { companyDetails: companyDetails },
  }
};

export function logout() {
  localStorage.removeItem('tokenDetails');
  localStorage.removeItem('selectedProjectCache')
  return {
      type: LOGOUT_ACTION,
  };
}