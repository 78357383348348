import React from 'react'
import { NavLink } from "react-router-dom";
import { NavItem } from "reactstrap";
import navList from "../../../config/navList";
import SideBarSubNav from "./sidebarSubNav";

const SidebarNav = () => {
  const handleOnClick = (e: any, data: any) => {
    const currentPathName = window.location?.pathname?.match(/\/[^/]+/)?.[0];
    if (currentPathName === data.link) {
      e.preventDefault()
    }
  }

  return (
    <div className="main-nav w-100" style={{userSelect: "none"}}>
      {navList.map((data, index) => {
        if (data.subModules) {
          return (
            <SideBarSubNav
              navInfo={data}
              key={data.name + index}
            />
          );
        }

        return (
          <NavItem key={data.name + index}>
            <NavLink
              onClick={(e: any)  => {handleOnClick(e, data)}}
              to={data.link}
              className={({ isActive }) =>
                [
                  "nav-link light-gray-400 my-3",
                  isActive ? "active" : "inactive",
                ]
                  .filter(Boolean)
                  .join(" ")
              }
            >
              <span>
                <data.icon />
              </span>
              <span className="ms-2 fs-12" style={{marginTop: "4px"}}>{data.name}</span>
            </NavLink>
          </NavItem>
        );
      })}
    </div>
  )
}

export default SidebarNav