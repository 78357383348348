export const ROUTE_PATHS = {
    LOGIN: "/",
    SIGNUP: "/signup",
    STRIPE: "/stripe",
    FORGOT_PASSWORD: "/forgot-password",
    CREATE_PASSWORD: "/create-password",
    DASHBOARD: "/dashboard",
    PROFILE: "/user/profile",
    PROJECT_VIEW: "/model-view",
    PROJECT_VIEW2: "/model-view/:project_id/:version_id",
    PROJECT_CATEGORIES_MAPPING: "/model-view/mapping/:project_id/:version_id",
    COMPARE: "/compare",
    SUPPORT: "/support",
    USER_MANAGEMENT: "/user/management",
    PAYMENT_INFO: "/payment/info",
    VALIDATE_OTP: "/user/validate",
    COST_VIEW: "/cost-view",
    COST_VIEW2: "/cost-view/:project_id/:version_id",
};


export const DEFAULT_PREFIX = {
    DEFAULT: "lemos",
};

export const ACL_MODULE_MAPPING = {
    PORTFOLIO: 0,
    PROJECT_VIEW: 1,
    COMPARE: 2,
    SUPPORT: 3,
    MANAGEMENT: 6,
    COST_VIEW: 5
};
