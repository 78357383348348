import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import userInfoReducer from "./user/reducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userInfo']
};

const RootReducer = combineReducers({
  userInfo: userInfoReducer
});

export default persistReducer(persistConfig, RootReducer);