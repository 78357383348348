import { Modal } from 'reactstrap';
import './IntroducingAraModal.scss';
import { IconCloseWhite, MagicButtonSparkleIconLarge } from '../Icons';

interface ModalProps {
  showModal: boolean;
  handleClose: Function;
  projectID: any;
}

export const IntroducingAraModal: React.FC<ModalProps> = ({
  showModal,
  handleClose,
  projectID,
}) => {
  return (
    <Modal
      isOpen={showModal}
      fade={false}
      size='lg'
      contentClassName='ara-modal'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div className='background-overlay'></div>
      <div className='close-icon-container' onClick={() => handleClose()}>
        <IconCloseWhite className='icon-close' />
      </div>
      <div className='ara-content-wrapper'>
        <div className='magic-icon-container'>
          <MagicButtonSparkleIconLarge />
        </div>
        <div>
          <span className='lemos-ai-tag'>LEMOS AI</span>
          <h1 className='ara-intro-header'>
            <span className='ara-name'>Introducing Ara,</span>
            <br /> your new team member
          </h1>
          <div className='ara-intro-content'>
            Thank you for adding me to your project. My goal is to{' '}
            <b>save you time</b>. To start, I will suggest costs for any blank
            model-based quantities for your approval. Here's what to anticipate
            from me:{' '}
            <br/><br/>
            <div className='ara-attributes'>
              <ul>
                <li>
                  <b>Time</b>: costs will be generated within 2 business days.
                </li>
                <li>
                  <b>Cost Editor</b>: I’ll add a purple bubble to the cost
                  editor column so you know where I’ve entered suggested costs.
                </li>
                <li>
                  <b>Changes</b>: you are in control. Accept, reject or make
                  changes to any suggested costs whenever you want.
                </li>
                <li>
                  <b>On call</b>: at any point, click on a purple bubble or the
                  3 dots next to a model-based item and select “Generate cost” -
                  I will receive a notification and will enter a cost for you.
                </li>
                <li>
                  <b>Target costs</b>: I’ve provided target cost ranges and will
                  continue to refine as I learn.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
