import React from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import { PageNotFound, Dashboard, ProjectView, Compare, Support, UserManagement, PaymentInfo, Profile, ProjectCategoriesMapping, CostView, Stripe } from "./commons/constants/loadableImports";
import { ROUTE_PATHS } from "./commons/constants/routes.constants";

interface PrivateRouteProps {
    isLoggedIn: boolean;
    skipTour: boolean;
    skipCostViewTour: boolean;
    auth_token: any;
    companyDetails: any;
    userDetails: any;
}

const privateRoutes: React.FC<PrivateRouteProps> = ({ isLoggedIn, auth_token, skipTour, skipCostViewTour, companyDetails, userDetails }) => {
    return (
        <Routes>
            <Route
                path={`${ROUTE_PATHS.DASHBOARD}`}
                element={<Dashboard isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} userDetails={userDetails} />}

            />
            <Route
                path={`${ROUTE_PATHS.PROFILE}`}
                element={<Profile isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} />}
            />
            <Route
                path={`${ROUTE_PATHS.PROJECT_VIEW}`}
                element={<ProjectView isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} />}
            />
            <Route
                path={`${ROUTE_PATHS.PROJECT_VIEW2}`}
                element={<ProjectView isLoggedIn={isLoggedIn} auth_token={auth_token} userDetails={userDetails} skipTour={skipTour} companyDetails={companyDetails} />}
            />
            <Route
                path={`${ROUTE_PATHS.COMPARE}`}
                element={<Compare isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} />}
            />
            {/* <Route
                path={`${ROUTE_PATHS.SUPPORT}`}
                element={<Support isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} />}
            />
            <Route
                path={`${ROUTE_PATHS.USER_MANAGEMENT}`}
                element={<UserManagement isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} />}
            /> */}
            <Route
                path={`${ROUTE_PATHS.PAYMENT_INFO}`}
                element={<PaymentInfo isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} />}
            />
            <Route 
                path={`${ROUTE_PATHS.PROJECT_CATEGORIES_MAPPING}`} 
                element={<ProjectCategoriesMapping isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} />} 
            />
            <Route
                path={`${ROUTE_PATHS.COST_VIEW}`}
                element={<CostView isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} />}
            />
            <Route
                path={`${ROUTE_PATHS.COST_VIEW2}`}
                element={<CostView isLoggedIn={isLoggedIn} auth_token={auth_token} userDetails={userDetails} skipTour={skipTour} skipCostViewTour={skipCostViewTour} companyDetails={companyDetails} />}
            />
            <Route
                path={`${ROUTE_PATHS.STRIPE}`}
                element={<Stripe isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} />}
            />
            <Route
                path={"*"}
                element={<Dashboard isLoggedIn={isLoggedIn} auth_token={auth_token} skipTour={skipTour} companyDetails={companyDetails} userDetails={userDetails} />}
            />
        </Routes>
    )
}

export default privateRoutes