import { makeRequestAuth, makeBaseUrl } from "../utils/globals";

export const validateUserData = async(endPoint: string, payload: any, type: string, extraHeaders: any) => {
    return await new Promise((resolve, reject) => {
        const url: string = makeBaseUrl(endPoint)
        const request_data = {
            url: url,
            variables: {
                add_data: payload,
            },
            type: type
        };
        makeRequestAuth(request_data, extraHeaders, (response: IResponse) => {
            if (response) {
                resolve(response);
            } else {
                resolve({});
            }
        });
    });
}

interface IResponse {
    response: any;
    error: boolean;
    errorMessages?: string[];
}