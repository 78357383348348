import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import companyIcon from "../Icons/companyIcon.svg";
import { API_ROUTES } from "../../../services/api.constants";
import { validateUserData } from '../../../services/baseApi.service'
import { isArray } from 'lodash';
import { STRIPE_SUBSCRIPTION_STATUS } from '../../constants/stripe.constants';

interface Props {
    userDetails: any;
    projectListCallback: Function;
}

const SidebarHeader: React.FC<Props> = (props) => {

    const location = useLocation();
    const userDetails = props.userDetails;

    return (
        <div className='sidebar-header mt-4 ms-3 me-3'>
            <img src={companyIcon} alt="company-icon" className="company-img-css" width={70} height={22} />
            <Link to="/user/profile" style={{ marginTop: "60px" }} className="d-flex align-items-center link-no-text-decoration wcolor link">
                <div className='icon_css me-2'>
                {userDetails.name[0] || ""}
                </div>
                <div>
                    <span className='des-css line-height-md fw-500 '>{userDetails.name}</span>
                </div>
            </Link>
            <hr style={{ borderBottom: "1px solid #16181a" }} className="mt-4 mb-2" />
        </div>
    )
}

export default SidebarHeader