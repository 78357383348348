import React, { useState } from 'react';
import { Nav } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SidebarNav from './sidebarNav';
import SidebarFooter from './sidebarFooter';
import SidebarHeader from './sidebarHeader';
import './sidebar.scss';
import { connect } from 'react-redux';
import { API_ROUTES } from '../../../services/api.constants';
import { validateUserData } from '../../../services/baseApi.service';
import AddProject from '../../../modules/Portfolio/components/addProject';
import CreateProjectSuccessModal from '../Modals/CreateProjectSuccessModal';
import {
  hsqPush,
  setStorageRefreshProjectDetails,
  setStorageRefreshSubscriptionCount,
  checkSubscriptionAvailable,
} from '../../../utils/helpers';
import { ROUTE_PATHS } from '../../constants/routes.constants';
import InviteModel from '../Modals/InviteModel';
import { toast } from 'react-toastify';
import { IntroducingAraModal } from '../Modals/IntroducingAraModal';
import { EMAIL_CONSTANTS } from '../../../services/constant';

interface Props {
  userDetails: any;
}

const Sidebar = ({ userDetails }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showSucessModal, setShowSuccessModal] = useState(false);
  const [projectList, setProjectList] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [projectId, setProjectID] = useState(false);
  const [showInviteMembersModal, setShowInviteMembersModal] = useState(false);
  const [showIntroducingAraModal, setShowIntroducingAraModal] = useState(false);
  const [projectName, setProjectName] = useState<any>()

  const onAddProject = async () => {
    setShowCreateModal(true);
  };

  const onCloseCreateProjectModal = () => {
    setShowCreateModal(false);
  };

  const projectListCallback = (list: any) => {
    setProjectList(list);
  };

  const sendInviteToEstimator = async () => {
    const tokenDetails: any = localStorage.getItem('tokenDetails');
    const token = JSON.parse(tokenDetails)?.token;
    const url = API_ROUTES.INVITE_USERS;

    const reqData = [
      {
        email: EMAIL_CONSTANTS.ESTIMATOR_EMAIL,
        role: 'EDITOR',
        project_role: 'OTHER',
        project_id: projectId,
      },
    ];

    const header = { Authorization: 'Bearer ' + token || '' };
    await validateUserData(url, reqData, 'POST', header)
      .then((response: any) => {
        if (response && !response.error && response.response) {
          toast.success('LEMOS estimator invited successfully');
          setShowIntroducingAraModal(true);
        } else {
          if (response.errorMessages.length) {
            if (Object.keys(response.errorMessages[0]).includes('other')) {
              toast.error(response.errorMessages[0].other);
            } else if (response.code === 403) {
              if (
                response.errorMessages &&
                Array.isArray(response.errorMessages)
              )
                response.errorMessages.forEach((element: any) => {
                  toast.error(`${Object.values(element)[0]}`);
                });
            } else {
              toast.error(response.errorMessages[0].user);
            }
          }
        }
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const handleAraModalClose = () => setShowIntroducingAraModal(false);

  const handleEstimatorButtonClicked = async () => {
    try {
      await sendInviteToEstimator()
      setShowInviteMembersModal(false);
    } catch (e) {
      setShowInviteMembersModal(true);
    }
  };

  const onCreateProject = async (selectedData: any) => {
    setLoader(true);
    const url = API_ROUTES.CREATE_PROJECT;
    const tokenDetails: any = localStorage.getItem('tokenDetails');
    const token = JSON.parse(tokenDetails)?.token;
    const header = { Authorization: 'Bearer ' + token || '' };
    const reqData = { ...selectedData };
    await validateUserData(url, reqData, 'POST', header)
      .then(async (response: any) => {
        if (response) {
          if (!response.error && response.response && response.response.id) {
            setLoader(false);
            setProjectID(response.response.id);
            setProjectName(response.response.name)
            setShowCreateModal(false);
            setShowSuccessModal(true);
            hsqPush('create_project_completed', {}, true);
            await setStorageRefreshProjectDetails();
            await setStorageRefreshSubscriptionCount();
            navigate(ROUTE_PATHS.DASHBOARD);
          } else {
            setLoader(false);
            console.log('error', response.errorMessages);
          }
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log('response', error);
      });
  };

  const onInviteButtonClick = () => {
    setShowInviteMembersModal(true);
  };

  const handleInviteMembersModalClick = () => {
    setShowInviteMembersModal(false);
  };

  return (
    <div className='sidebarSection sidebar bg-black' id='sidebarSection'>
      <SidebarHeader
        userDetails={userDetails}
        projectListCallback={projectListCallback}
      />
      <Nav className='nav-stacked nav-tabs'>
        <SidebarNav />
      </Nav>
      <SidebarFooter onNewProject={() => onAddProject()} />
      {showCreateModal && (
        <AddProject
          callbackMethod={onCloseCreateProjectModal}
          showCreateProject={showCreateModal}
          onCreateProject={onCreateProject}
          loader={loader}
        />
      )}
      {setShowSuccessModal && (
        <CreateProjectSuccessModal
          callbackMethod={() => setShowSuccessModal(!showSucessModal)}
          showModal={showSucessModal}
          myHandler={onInviteButtonClick}
        />
      )}
      {showInviteMembersModal ? (
        <InviteModel
          callbackMethod={() =>
            setShowInviteMembersModal(!showInviteMembersModal)
          }
          showModal={showInviteMembersModal}
          myHandler={handleInviteMembersModalClick}
          handleEstimatorButtonClick={handleEstimatorButtonClicked}
          projectID={projectId}
          projectName={projectName}
        />
      ) : showIntroducingAraModal ? (
        <IntroducingAraModal
          handleClose={() => handleAraModalClose()}
          showModal={showIntroducingAraModal}
          projectID={projectId}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    userDetails: state.userInfo.userDetails,
  };
};

export default connect(mapStateToProps, null)(Sidebar);
