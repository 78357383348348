// Taken from https://gist.github.com/Lwdthe1/81818d30d23f012628aac1cdf672627d
// This is a short term solution to get a list of us cities into the application.

const USLocationsData = 
[
    {city: "Alabaster", state: "Alabama"},
    {city: "Albertville", state: "Alabama"},
    {city: "Alexander City", state: "Alabama"},
    {city: "Andalusia", state: "Alabama"},
    {city: "Anniston", state: "Alabama"},
    {city: "Arab", state: "Alabama"},
    {city: "Athens", state: "Alabama"},
    {city: "Atmore", state: "Alabama"},
    {city: "Attalla", state: "Alabama"},
    {city: "Auburn", state: "Alabama"},
    {city: "Bay Minette", state: "Alabama"},
    {city: "Bessemer", state: "Alabama"},
    {city: "Birmingham", state: "Alabama"},
    {city: "Boaz", state: "Alabama"},
    {city: "Center Point", state: "Alabama"},
    {city: "Chickasaw", state: "Alabama"},
    {city: "Clanton", state: "Alabama"},
    {city: "Cullman", state: "Alabama"},
    {city: "Daphne", state: "Alabama"},
    {city: "Decatur", state: "Alabama"},
    {city: "Demopolis", state: "Alabama"},
    {city: "Dothan", state: "Alabama"},
    {city: "Enterprise", state: "Alabama"},
    {city: "Eufaula", state: "Alabama"},
    {city: "Fairfield", state: "Alabama"},
    {city: "Fairhope", state: "Alabama"},
    {city: "Florence", state: "Alabama"},
    {city: "Foley", state: "Alabama"},
    {city: "Forestdale", state: "Alabama"},
    {city: "Fort Payne", state: "Alabama"},
    {city: "Fort Rucker", state: "Alabama"},
    {city: "Fultondale", state: "Alabama"},
    {city: "Gadsden", state: "Alabama"},
    {city: "Gardendale", state: "Alabama"},
    {city: "Greenville", state: "Alabama"},
    {city: "Guntersville", state: "Alabama"},
    {city: "Hamilton", state: "Alabama"},
    {city: "Hartselle", state: "Alabama"},
    {city: "Helena", state: "Alabama"},
    {city: "Homewood", state: "Alabama"},
    {city: "Hoover", state: "Alabama"},
    {city: "Hueytown", state: "Alabama"},
    {city: "Huntsville", state: "Alabama"},
    {city: "Irondale", state: "Alabama"},
    {city: "Jacksonville", state: "Alabama"},
    {city: "Jasper", state: "Alabama"},
    {city: "Lanett", state: "Alabama"},
    {city: "Leeds", state: "Alabama"},
    {city: "Madison", state: "Alabama"},
    {city: "Millbrook", state: "Alabama"},
    {city: "Mobile", state: "Alabama"},
    {city: "Monroeville", state: "Alabama"},
    {city: "Montgomery", state: "Alabama"},
    {city: "Moody", state: "Alabama"},
    {city: "Mountain Brook", state: "Alabama"},
    {city: "Muscle Shoals", state: "Alabama"},
    {city: "Northport", state: "Alabama"},
    {city: "Opelika", state: "Alabama"},
    {city: "Opp", state: "Alabama"},
    {city: "Oxford", state: "Alabama"},
    {city: "Ozark", state: "Alabama"},
    {city: "Pelham", state: "Alabama"},
    {city: "Pell City", state: "Alabama"},
    {city: "Phenix City", state: "Alabama"},
    {city: "Pleasant Grove", state: "Alabama"},
    {city: "Prattville", state: "Alabama"},
    {city: "Prichard", state: "Alabama"},
    {city: "Rainbow City", state: "Alabama"},
    {city: "Roanoke", state: "Alabama"},
    {city: "Russellville", state: "Alabama"},
    {city: "Saks", state: "Alabama"},
    {city: "Saraland", state: "Alabama"},
    {city: "Scottsboro", state: "Alabama"},
    {city: "Selma", state: "Alabama"},
    {city: "Sheffield", state: "Alabama"},
    {city: "Smiths", state: "Alabama"},
    {city: "Southside", state: "Alabama"},
    {city: "Sylacauga", state: "Alabama"},
    {city: "Talladega", state: "Alabama"},
    {city: "Tarrant", state: "Alabama"},
    {city: "Theodore", state: "Alabama"},
    {city: "Tillmans Corner", state: "Alabama"},
    {city: "Troy", state: "Alabama"},
    {city: "Trussville", state: "Alabama"},
    {city: "Tuscaloosa", state: "Alabama"},
    {city: "Tuscumbia", state: "Alabama"},
    {city: "Tuskegee", state: "Alabama"},
    {city: "Valley", state: "Alabama"},
    {city: "Vestavia Hills", state: "Alabama"},
    {city: "Anchorage", state: "Alaska"},
    {city: "College", state: "Alaska"},
    {city: "Fairbanks", state: "Alaska"},
    {city: "Juneau", state: "Alaska"},
    {city: "Kenai", state: "Alaska"},
    {city: "Ketchikan", state: "Alaska"},
    {city: "Knik-Fairview", state: "Alaska"},
    {city: "Kodiak", state: "Alaska"},
    {city: "Lakes", state: "Alaska"},
    {city: "Nome", state: "Alaska"},
    {city: "Sitka", state: "Alaska"},
    {city: "Apache Junction", state: "Arizona"},
    {city: "Avondale", state: "Arizona"},
    {city: "Bisbee", state: "Arizona"},
    {city: "Buckeye", state: "Arizona"},
    {city: "Bullhead City", state: "Arizona"},
    {city: "Camp Verde", state: "Arizona"},
    {city: "Casa Grande", state: "Arizona"},
    {city: "Casas Adobes", state: "Arizona"},
    {city: "Catalina", state: "Arizona"},
    {city: "Catalina Foothills", state: "Arizona"},
    {city: "Chandler", state: "Arizona"},
    {city: "Chino Valley", state: "Arizona"},
    {city: "Clifton", state: "Arizona"},
    {city: "Coolidge", state: "Arizona"},
    {city: "Cottonwood", state: "Arizona"},
    {city: "Cottonwood-Verde Village", state: "Arizona"},
    {city: "Dewey-Humboldt", state: "Arizona"},
    {city: "Douglas", state: "Arizona"},
    {city: "Drexel Heights", state: "Arizona"},
    {city: "El Mirage", state: "Arizona"},
    {city: "Eloy", state: "Arizona"},
    {city: "Flagstaff", state: "Arizona"},
    {city: "Florence", state: "Arizona"},
    {city: "Flowing Wells", state: "Arizona"},
    {city: "Fortuna Foothills", state: "Arizona"},
    {city: "Fountain Hills", state: "Arizona"},
    {city: "Gilbert", state: "Arizona"},
    {city: "Glendale", state: "Arizona"},
    {city: "Globe", state: "Arizona"},
    {city: "Gold Camp", state: "Arizona"},
    {city: "Goodyear", state: "Arizona"},
    {city: "Green Valley", state: "Arizona"},
    {city: "Kingman", state: "Arizona"},
    {city: "Lake Havasu City", state: "Arizona"},
    {city: "Marana", state: "Arizona"},
    {city: "Mesa", state: "Arizona"},
    {city: "Mohave Valley", state: "Arizona"},
    {city: "New Kingman-Butler", state: "Arizona"},
    {city: "New River", state: "Arizona"},
    {city: "Nogales", state: "Arizona"},
    {city: "Oro Valley", state: "Arizona"},
    {city: "Page", state: "Arizona"},
    {city: "Paradise Valley", state: "Arizona"},
    {city: "Payson", state: "Arizona"},
    {city: "Peoria", state: "Arizona"},
    {city: "Phoenix", state: "Arizona"},
    {city: "Picture Rocks", state: "Arizona"},
    {city: "Prescott", state: "Arizona"},
    {city: "Prescott Valley", state: "Arizona"},
    {city: "Safford", state: "Arizona"},
    {city: "San Luis", state: "Arizona"},
    {city: "Scottsdale", state: "Arizona"},
    {city: "Sedona", state: "Arizona"},
    {city: "Show Low", state: "Arizona"},
    {city: "Sierra Vista", state: "Arizona"},
    {city: "Sierra Vista Southeast", state: "Arizona"},
    {city: "Somerton", state: "Arizona"},
    {city: "Sun City", state: "Arizona"},
    {city: "Sun City West", state: "Arizona"},
    {city: "Sun Lakes", state: "Arizona"},
    {city: "Surprise", state: "Arizona"},
    {city: "Tanque Verde", state: "Arizona"},
    {city: "Tempe", state: "Arizona"},
    {city: "Tuba City", state: "Arizona"},
    {city: "Tucson", state: "Arizona"},
    {city: "Tucson Estates", state: "Arizona"},
    {city: "Winslow", state: "Arizona"},
    {city: "Yuma", state: "Arizona"},
    {city: "Arkadelphia", state: "Arkansas"},
    {city: "Batesville", state: "Arkansas"},
    {city: "Bella Vista", state: "Arkansas"},
    {city: "Benton", state: "Arkansas"},
    {city: "Bentonville", state: "Arkansas"},
    {city: "Blytheville", state: "Arkansas"},
    {city: "Bryant", state: "Arkansas"},
    {city: "Cabot", state: "Arkansas"},
    {city: "Camden", state: "Arkansas"},
    {city: "Clarksville", state: "Arkansas"},
    {city: "Conway", state: "Arkansas"},
    {city: "Crossett", state: "Arkansas"},
    {city: "El Dorado", state: "Arkansas"},
    {city: "Fayetteville", state: "Arkansas"},
    {city: "Forrest City", state: "Arkansas"},
    {city: "Fort Smith", state: "Arkansas"},
    {city: "Greenwood", state: "Arkansas"},
    {city: "Harrison", state: "Arkansas"},
    {city: "Heber Springs", state: "Arkansas"},
    {city: "Helena", state: "Arkansas"},
    {city: "Hope", state: "Arkansas"},
    {city: "Hot Springs", state: "Arkansas"},
    {city: "Hot Springs Village", state: "Arkansas"},
    {city: "Jacksonville", state: "Arkansas"},
    {city: "Jonesboro", state: "Arkansas"},
    {city: "Little Rock", state: "Arkansas"},
    {city: "Magnolia", state: "Arkansas"},
    {city: "Malvern", state: "Arkansas"},
    {city: "Marion", state: "Arkansas"},
    {city: "Maumelle", state: "Arkansas"},
    {city: "Monticello", state: "Arkansas"},
    {city: "Morrilton", state: "Arkansas"},
    {city: "Mountain Home", state: "Arkansas"},
    {city: "Newport", state: "Arkansas"},
    {city: "North Little Rock", state: "Arkansas"},
    {city: "Osceola", state: "Arkansas"},
    {city: "Paragould", state: "Arkansas"},
    {city: "Pine Bluff", state: "Arkansas"},
    {city: "Pocahontas", state: "Arkansas"},
    {city: "Rogers", state: "Arkansas"},
    {city: "Russellville", state: "Arkansas"},
    {city: "Searcy", state: "Arkansas"},
    {city: "Sherwood", state: "Arkansas"},
    {city: "Siloam Springs", state: "Arkansas"},
    {city: "Springdale", state: "Arkansas"},
    {city: "Stuttgart", state: "Arkansas"},
    {city: "Texarkana", state: "Arkansas"},
    {city: "Trumann", state: "Arkansas"},
    {city: "Van Buren", state: "Arkansas"},
    {city: "Warren", state: "Arkansas"},
    {city: "West Helena", state: "Arkansas"},
    {city: "West Memphis", state: "Arkansas"},
    {city: "Wynne", state: "Arkansas"},
    {city: "Adelanto", state: "California"},
    {city: "Agoura Hills", state: "California"},
    {city: "Alameda", state: "California"},
    {city: "Alamo", state: "California"},
    {city: "Albany", state: "California"},
    {city: "Alhambra", state: "California"},
    {city: "Aliso Viejo", state: "California"},
    {city: "Alondra Park", state: "California"},
    {city: "Alpine", state: "California"},
    {city: "Alta Sierra", state: "California"},
    {city: "Altadena", state: "California"},
    {city: "Alum Rock", state: "California"},
    {city: "American Canyon", state: "California"},
    {city: "Anaheim", state: "California"},
    {city: "Anderson", state: "California"},
    {city: "Antioch", state: "California"},
    {city: "Apple Valley", state: "California"},
    {city: "Aptos", state: "California"},
    {city: "Arcadia", state: "California"},
    {city: "Arcata", state: "California"},
    {city: "Arden-Arcade", state: "California"},
    {city: "Arroyo Grande", state: "California"},
    {city: "Artesia", state: "California"},
    {city: "Arvin", state: "California"},
    {city: "Ashland", state: "California"},
    {city: "Atascadero", state: "California"},
    {city: "Atherton", state: "California"},
    {city: "Atwater", state: "California"},
    {city: "Auburn", state: "California"},
    {city: "August", state: "California"},
    {city: "Avenal", state: "California"},
    {city: "Avocado Heights", state: "California"},
    {city: "Azusa", state: "California"},
    {city: "Bakersfield", state: "California"},
    {city: "Baldwin Park", state: "California"},
    {city: "Banning", state: "California"},
    {city: "Barstow", state: "California"},
    {city: "Bay Point", state: "California"},
    {city: "Baywood-Los Osos", state: "California"},
    {city: "Beaumont", state: "California"},
    {city: "Bell", state: "California"},
    {city: "Bell Gardens", state: "California"},
    {city: "Bellflower", state: "California"},
    {city: "Belmont", state: "California"},
    {city: "Benicia", state: "California"},
    {city: "Berkeley", state: "California"},
    {city: "Bermuda Dunes", state: "California"},
    {city: "Beverly Hills", state: "California"},
    {city: "Blackhawk-Camino Tassajara", state: "California"},
    {city: "Bloomington", state: "California"},
    {city: "Blythe", state: "California"},
    {city: "Bonadelle Ranchos-Madera Ranchos", state: "California"},
    {city: "Bonita", state: "California"},
    {city: "Bostonia", state: "California"},
    {city: "Boyes Hot Springs", state: "California"},
    {city: "Brawley", state: "California"},
    {city: "Brea", state: "California"},
    {city: "Brentwood", state: "California"},
    {city: "Buena Park", state: "California"},
    {city: "Burbank", state: "California"},
    {city: "Burlingame", state: "California"},
    {city: "Calabasas", state: "California"},
    {city: "Calexico", state: "California"},
    {city: "California City", state: "California"},
    {city: "Calimesa", state: "California"},
    {city: "Calipatria", state: "California"},
    {city: "Camarillo", state: "California"},
    {city: "Cambria", state: "California"},
    {city: "Cameron Park", state: "California"},
    {city: "Camp Pendleton North", state: "California"},
    {city: "Camp Pendleton South", state: "California"},
    {city: "Campbell", state: "California"},
    {city: "Canyon Lake", state: "California"},
    {city: "Capitola", state: "California"},
    {city: "Carlsbad", state: "California"},
    {city: "Carmichael", state: "California"},
    {city: "Carpinteria", state: "California"},
    {city: "Carson", state: "California"},
    {city: "Casa de Oro-Mount Helix", state: "California"},
    {city: "Castro Valley", state: "California"},
    {city: "Castroville", state: "California"},
    {city: "Cathedral City", state: "California"},
    {city: "Ceres", state: "California"},
    {city: "Cerritos", state: "California"},
    {city: "Charter Oak", state: "California"},
    {city: "Cherryland", state: "California"},
    {city: "Chico", state: "California"},
    {city: "Chino", state: "California"},
    {city: "Chino Hills", state: "California"},
    {city: "Chowchilla", state: "California"},
    {city: "Chula Vista", state: "California"},
    {city: "Citrus", state: "California"},
    {city: "Citrus Heights", state: "California"},
    {city: "Claremont", state: "California"},
    {city: "Clayton", state: "California"},
    {city: "Clearlake", state: "California"},
    {city: "Cloverdale", state: "California"},
    {city: "Clovis", state: "California"},
    {city: "Coachella", state: "California"},
    {city: "Coalinga", state: "California"},
    {city: "Colton", state: "California"},
    {city: "Commerce", state: "California"},
    {city: "Compton", state: "California"},
    {city: "Concord", state: "California"},
    {city: "Corcoran", state: "California"},
    {city: "Corning", state: "California"},
    {city: "Corona", state: "California"},
    {city: "Coronado", state: "California"},
    {city: "Corte Madera", state: "California"},
    {city: "Costa Mesa", state: "California"},
    {city: "Cotati", state: "California"},
    {city: "Coto de Caza", state: "California"},
    {city: "Country Club", state: "California"},
    {city: "Covina", state: "California"},
    {city: "Crestline", state: "California"},
    {city: "Cudahy", state: "California"},
    {city: "Culver City", state: "California"},
    {city: "Cupertino", state: "California"},
    {city: "Cypress", state: "California"},
    {city: "Daly City", state: "California"},
    {city: "Dana Point", state: "California"},
    {city: "Danville", state: "California"},
    {city: "Davis", state: "California"},
    {city: "Del Aire", state: "California"},
    {city: "Delano", state: "California"},
    {city: "Delhi", state: "California"},
    {city: "Desert Hot Springs", state: "California"},
    {city: "Diamond Bar", state: "California"},
    {city: "Dinuba", state: "California"},
    {city: "Discovery Bay", state: "California"},
    {city: "Dixon", state: "California"},
    {city: "Downey", state: "California"},
    {city: "Duarte", state: "California"},
    {city: "Dublin", state: "California"},
    {city: "Earlimart", state: "California"},
    {city: "East Compton", state: "California"},
    {city: "East Foothills", state: "California"},
    {city: "East Hemet", state: "California"},
    {city: "East La Mirada", state: "California"},
    {city: "East Los Angeles", state: "California"},
    {city: "East Palo Alto", state: "California"},
    {city: "East Pasadena", state: "California"},
    {city: "East Porterville", state: "California"},
    {city: "East San Gabriel", state: "California"},
    {city: "El Cajon", state: "California"},
    {city: "El Centro", state: "California"},
    {city: "El Cerrito", state: "California"},
    {city: "El Dorado Hills", state: "California"},
    {city: "El Monte", state: "California"},
    {city: "El Paso de Robles", state: "California"},
    {city: "El Rio", state: "California"},
    {city: "El Segundo", state: "California"},
    {city: "El Sobrante", state: "California"},
    {city: "Elk Grove", state: "California"},
    {city: "Emeryville", state: "California"},
    {city: "Encinitas", state: "California"},
    {city: "Escondido", state: "California"},
    {city: "Eureka", state: "California"},
    {city: "Exeter", state: "California"},
    {city: "Fair Oaks", state: "California"},
    {city: "Fairfax", state: "California"},
    {city: "Fairfield", state: "California"},
    {city: "Fairview", state: "California"},
    {city: "Fallbrook", state: "California"},
    {city: "Farmersville", state: "California"},
    {city: "Fillmore", state: "California"},
    {city: "Florence-Graham", state: "California"},
    {city: "Florin", state: "California"},
    {city: "Folsom", state: "California"},
    {city: "Fontana", state: "California"},
    {city: "Foothill Farms", state: "California"},
    {city: "Foothill Ranch", state: "California"},
    {city: "Fort Bragg", state: "California"},
    {city: "Fortuna", state: "California"},
    {city: "Foster City", state: "California"},
    {city: "Fountain Valley", state: "California"},
    {city: "Fremont", state: "California"},
    {city: "Fresno", state: "California"},
    {city: "Fullerton", state: "California"},
    {city: "Galt", state: "California"},
    {city: "Garden Acres", state: "California"},
    {city: "Garden Grove", state: "California"},
    {city: "Gardena", state: "California"},
    {city: "Gilroy", state: "California"},
    {city: "Glen Avon", state: "California"},
    {city: "Glendale", state: "California"},
    {city: "Glendora", state: "California"},
    {city: "Gold River", state: "California"},
    {city: "Golden Hills", state: "California"},
    {city: "Goleta", state: "California"},
    {city: "Gonzales", state: "California"},
    {city: "Grand Terrace", state: "California"},
    {city: "Granite Bay", state: "California"},
    {city: "Grass Valley", state: "California"},
    {city: "Greenfield", state: "California"},
    {city: "Grover Beach", state: "California"},
    {city: "Hacienda Heights", state: "California"},
    {city: "Half Moon Bay", state: "California"},
    {city: "Hanford", state: "California"},
    {city: "Hawaiian Gardens", state: "California"},
    {city: "Hawthorne", state: "California"},
    {city: "Hayward", state: "California"},
    {city: "Healdsburg", state: "California"},
    {city: "Hemet", state: "California"},
    {city: "Hercules", state: "California"},
    {city: "Hermosa Beach", state: "California"},
    {city: "Hesperia", state: "California"},
    {city: "Highland", state: "California"},
    {city: "Hillsborough", state: "California"},
    {city: "Hollister", state: "California"},
    {city: "Home Gardens", state: "California"},
    {city: "Huntington Beach", state: "California"},
    {city: "Huntington Park", state: "California"},
    {city: "Huron", state: "California"},
    {city: "Imperial", state: "California"},
    {city: "Imperial Beach", state: "California"},
    {city: "Indio", state: "California"},
    {city: "Inglewood", state: "California"},
    {city: "Interlaken", state: "California"},
    {city: "Ione", state: "California"},
    {city: "Irvine", state: "California"},
    {city: "Isla Vista", state: "California"},
    {city: "Kentfield", state: "California"},
    {city: "Kerman", state: "California"},
    {city: "King City", state: "California"},
    {city: "Kingsburg", state: "California"},
    {city: "La Canada Flintridge", state: "California"},
    {city: "La Crescenta-Montrose", state: "California"},
    {city: "La Habra", state: "California"},
    {city: "La Mesa", state: "California"},
    {city: "La Mirada", state: "California"},
    {city: "La Palma", state: "California"},
    {city: "La Presa", state: "California"},
    {city: "La Puente", state: "California"},
    {city: "La Quinta", state: "California"},
    {city: "La Riviera", state: "California"},
    {city: "La Verne", state: "California"},
    {city: "Ladera Heights", state: "California"},
    {city: "Lafayette", state: "California"},
    {city: "Laguna", state: "California"},
    {city: "Laguna Beach", state: "California"},
    {city: "Laguna Hills", state: "California"},
    {city: "Laguna Niguel", state: "California"},
    {city: "Laguna West-Lakeside", state: "California"},
    {city: "Laguna Woods", state: "California"},
    {city: "Lake Arrowhead", state: "California"},
    {city: "Lake Elsinore", state: "California"},
    {city: "Lake Forest", state: "California"},
    {city: "Lake Los Angeles", state: "California"},
    {city: "Lakeside", state: "California"},
    {city: "Lakewood", state: "California"},
    {city: "Lamont", state: "California"},
    {city: "Lancaster", state: "California"},
    {city: "Larkfield-Wikiup", state: "California"},
    {city: "Larkspur", state: "California"},
    {city: "Lathrop", state: "California"},
    {city: "Lawndale", state: "California"},
    {city: "Lemon Grove", state: "California"},
    {city: "Lemoore", state: "California"},
    {city: "Lennox", state: "California"},
    {city: "Lincoln", state: "California"},
    {city: "Linda", state: "California"},
    {city: "Lindsay", state: "California"},
    {city: "Live Oak", state: "California"},
    {city: "Livermore", state: "California"},
    {city: "Livingston", state: "California"},
    {city: "Lodi", state: "California"},
    {city: "Loma Linda", state: "California"},
    {city: "Lomita", state: "California"},
    {city: "Lompoc", state: "California"},
    {city: "Long Beach", state: "California"},
    {city: "Loomis", state: "California"},
    {city: "Los Alamitos", state: "California"},
    {city: "Los Altos", state: "California"},
    {city: "Los Altos Hills", state: "California"},
    {city: "Los Angeles", state: "California"},
    {city: "Los Banos", state: "California"},
    {city: "Los Gatos", state: "California"},
    {city: "Lucas Valley-Marinwood", state: "California"},
    {city: "Lynwood", state: "California"},
    {city: "Madera", state: "California"},
    {city: "Madera Acres", state: "California"},
    {city: "Magalia", state: "California"},
    {city: "Malibu", state: "California"},
    {city: "Mammoth Lakes", state: "California"},
    {city: "Manhattan Beach", state: "California"},
    {city: "Manteca", state: "California"},
    {city: "Marina", state: "California"},
    {city: "Marina del Rey", state: "California"},
    {city: "Martinez", state: "California"},
    {city: "Marysville", state: "California"},
    {city: "Maywood", state: "California"},
    {city: "McFarland", state: "California"},
    {city: "McKinleyville", state: "California"},
    {city: "Mendota", state: "California"},
    {city: "Menlo Park", state: "California"},
    {city: "Mentone", state: "California"},
    {city: "Merced", state: "California"},
    {city: "Mill Valley", state: "California"},
    {city: "Millbrae", state: "California"},
    {city: "Milpitas", state: "California"},
    {city: "Mira Loma", state: "California"},
    {city: "Mira Monte", state: "California"},
    {city: "Mission Viejo", state: "California"},
    {city: "Modesto", state: "California"},
    {city: "Monrovia", state: "California"},
    {city: "Montclair", state: "California"},
    {city: "Montebello", state: "California"},
    {city: "Montecito", state: "California"},
    {city: "Monterey", state: "California"},
    {city: "Monterey Park", state: "California"},
    {city: "Moorpark", state: "California"},
    {city: "Moraga", state: "California"},
    {city: "Moreno Valley", state: "California"},
    {city: "Morgan Hill", state: "California"},
    {city: "Morro Bay", state: "California"},
    {city: "Mountain View", state: "California"},
    {city: "Murrieta", state: "California"},
    {city: "Muscoy", state: "California"},
    {city: "Napa", state: "California"},
    {city: "National City", state: "California"},
    {city: "Newark", state: "California"},
    {city: "Newman", state: "California"},
    {city: "Newport Beach", state: "California"},
    {city: "Nipomo", state: "California"},
    {city: "Norco", state: "California"},
    {city: "North Auburn", state: "California"},
    {city: "North Fair Oaks", state: "California"},
    {city: "North Highlands", state: "California"},
    {city: "Norwalk", state: "California"},
    {city: "Novato", state: "California"},
    {city: "Oakdale", state: "California"},
    {city: "Oakland", state: "California"},
    {city: "Oakley", state: "California"},
    {city: "Oceano", state: "California"},
    {city: "Oceanside", state: "California"},
    {city: "Oildale", state: "California"},
    {city: "Ojai", state: "California"},
    {city: "Olivehurst", state: "California"},
    {city: "Ontario", state: "California"},
    {city: "Opal Cliffs", state: "California"},
    {city: "Orange", state: "California"},
    {city: "Orange Cove", state: "California"},
    {city: "Orangevale", state: "California"},
    {city: "Orcutt", state: "California"},
    {city: "Orinda", state: "California"},
    {city: "Orland", state: "California"},
    {city: "Orosi", state: "California"},
    {city: "Oroville", state: "California"},
    {city: "Oroville East", state: "California"},
    {city: "Oxnard", state: "California"},
    {city: "Pacific Grove", state: "California"},
    {city: "Pacifica", state: "California"},
    {city: "Palm Desert", state: "California"},
    {city: "Palm Springs", state: "California"},
    {city: "Palmdale", state: "California"},
    {city: "Palo Alto", state: "California"},
    {city: "Palos Verdes Estates", state: "California"},
    {city: "Paradise", state: "California"},
    {city: "Paramount", state: "California"},
    {city: "Parkway-South Sacramento", state: "California"},
    {city: "Parlier", state: "California"},
    {city: "Pasadena", state: "California"},
    {city: "Patterson", state: "California"},
    {city: "Pedley", state: "California"},
    {city: "Perris", state: "California"},
    {city: "Petaluma", state: "California"},
    {city: "Pico Rivera", state: "California"},
    {city: "Piedmont", state: "California"},
    {city: "Pinole", state: "California"},
    {city: "Pismo Beach", state: "California"},
    {city: "Pittsburg", state: "California"},
    {city: "Placentia", state: "California"},
    {city: "Placerville", state: "California"},
    {city: "Pleasant Hill", state: "California"},
    {city: "Pleasanton", state: "California"},
    {city: "Pomona", state: "California"},
    {city: "Port Hueneme", state: "California"},
    {city: "Porterville", state: "California"},
    {city: "Portola Hills", state: "California"},
    {city: "Poway", state: "California"},
    {city: "Prunedale", state: "California"},
    {city: "Quartz Hill", state: "California"},
    {city: "Ramona", state: "California"},
    {city: "Rancho Cordova", state: "California"},
    {city: "Rancho Cucamonga", state: "California"},
    {city: "Rancho Mirage", state: "California"},
    {city: "Rancho Palos Verdes", state: "California"},
    {city: "Rancho San Diego", state: "California"},
    {city: "Rancho Santa Margarita", state: "California"},
    {city: "Red Bluff", state: "California"},
    {city: "Redding", state: "California"},
    {city: "Redlands", state: "California"},
    {city: "Redondo Beach", state: "California"},
    {city: "Redwood City", state: "California"},
    {city: "Reedley", state: "California"},
    {city: "Rialto", state: "California"},
    {city: "Richmond", state: "California"},
    {city: "Ridgecrest", state: "California"},
    {city: "Rio del Mar", state: "California"},
    {city: "Rio Linda", state: "California"},
    {city: "Ripon", state: "California"},
    {city: "Riverbank", state: "California"},
    {city: "Riverside", state: "California"},
    {city: "Rocklin", state: "California"},
    {city: "Rodeo", state: "California"},
    {city: "Rohnert Park", state: "California"},
    {city: "Rolling Hills Estates", state: "California"},
    {city: "Rosamond", state: "California"},
    {city: "Rosedale", state: "California"},
    {city: "Roseland", state: "California"},
    {city: "Rosemead", state: "California"},
    {city: "Rosemont", state: "California"},
    {city: "Roseville", state: "California"},
    {city: "Rossmoor", state: "California"},
    {city: "Rowland Heights", state: "California"},
    {city: "Rubidoux", state: "California"},
    {city: "Sacramento", state: "California"},
    {city: "Salida", state: "California"},
    {city: "Salinas", state: "California"},
    {city: "San Anselmo", state: "California"},
    {city: "San Bernardino", state: "California"},
    {city: "San Bruno", state: "California"},
    {city: "San Carlos", state: "California"},
    {city: "San Clemente", state: "California"},
    {city: "San Diego", state: "California"},
    {city: "San Diego Country Estates", state: "California"},
    {city: "San Dimas", state: "California"},
    {city: "San Fernando", state: "California"},
    {city: "San Francisco", state: "California"},
    {city: "San Gabriel", state: "California"},
    {city: "San Jacinto", state: "California"},
    {city: "San Jose", state: "California"},
    {city: "San Juan Capistrano", state: "California"},
    {city: "San Leandro", state: "California"},
    {city: "San Lorenzo", state: "California"},
    {city: "San Luis Obispo", state: "California"},
    {city: "San Marcos", state: "California"},
    {city: "San Marino", state: "California"},
    {city: "San Mateo", state: "California"},
    {city: "San Pablo", state: "California"},
    {city: "San Rafael", state: "California"},
    {city: "San Ramon", state: "California"},
    {city: "Sanger", state: "California"},
    {city: "Santa Ana", state: "California"},
    {city: "Santa Barbara", state: "California"},
    {city: "Santa Clara", state: "California"},
    {city: "Santa Clarita", state: "California"},
    {city: "Santa Cruz", state: "California"},
    {city: "Santa Fe Springs", state: "California"},
    {city: "Santa Maria", state: "California"},
    {city: "Santa Monica", state: "California"},
    {city: "Santa Paula", state: "California"},
    {city: "Santa Rosa", state: "California"},
    {city: "Santee", state: "California"},
    {city: "Saratoga", state: "California"},
    {city: "Sausalito", state: "California"},
    {city: "Scotts Valley", state: "California"},
    {city: "Seal Beach", state: "California"},
    {city: "Seaside", state: "California"},
    {city: "Sebastopol", state: "California"},
    {city: "Selma", state: "California"},
    {city: "Shafter", state: "California"},
    {city: "Shasta Lake", state: "California"},
    {city: "Sierra Madre", state: "California"},
    {city: "Signal Hill", state: "California"},
    {city: "Simi Valley", state: "California"},
    {city: "Solana Beach", state: "California"},
    {city: "Soledad", state: "California"},
    {city: "Sonoma", state: "California"},
    {city: "South El Monte", state: "California"},
    {city: "South Gate", state: "California"},
    {city: "South Lake Tahoe", state: "California"},
    {city: "South Oroville", state: "California"},
    {city: "South Pasadena", state: "California"},
    {city: "South San Francisco", state: "California"},
    {city: "South San Gabriel", state: "California"},
    {city: "South San Jose Hills", state: "California"},
    {city: "South Whittier", state: "California"},
    {city: "South Yuba City", state: "California"},
    {city: "Spring Valley", state: "California"},
    {city: "Stanford", state: "California"},
    {city: "Stanton", state: "California"},
    {city: "Stockton", state: "California"},
    {city: "Suisun City", state: "California"},
    {city: "Sun City", state: "California"},
    {city: "Sunnyvale", state: "California"},
    {city: "Susanville", state: "California"},
    {city: "Taft", state: "California"},
    {city: "Tamalpais-Homestead Valley", state: "California"},
    {city: "Tehachapi", state: "California"},
    {city: "Temecula", state: "California"},
    {city: "Temple City", state: "California"},
    {city: "Thermalito", state: "California"},
    {city: "Thousand Oaks", state: "California"},
    {city: "Tiburon", state: "California"},
    {city: "Torrance", state: "California"},
    {city: "Tracy", state: "California"},
    {city: "Truckee", state: "California"},
    {city: "Tulare", state: "California"},
    {city: "Turlock", state: "California"},
    {city: "Tustin", state: "California"},
    {city: "Tustin Foothills", state: "California"},
    {city: "Twentynine Palms", state: "California"},
    {city: "Twentynine Palms Base", state: "California"},
    {city: "Ukiah", state: "California"},
    {city: "Union City", state: "California"},
    {city: "Upland", state: "California"},
    {city: "Vacaville", state: "California"},
    {city: "Valinda", state: "California"},
    {city: "Valle Vista", state: "California"},
    {city: "Vallejo", state: "California"},
    {city: "Valley Center", state: "California"},
    {city: "Vandenberg AFB", state: "California"},
    {city: "Ventura", state: "California"},
    {city: "Victorville", state: "California"},
    {city: "View Park-Windsor Hills", state: "California"},
    {city: "Vincent", state: "California"},
    {city: "Vineyard", state: "California"},
    {city: "Visalia", state: "California"},
    {city: "Vista", state: "California"},
    {city: "Walnut", state: "California"},
    {city: "Walnut Creek", state: "California"},
    {city: "Walnut Park", state: "California"},
    {city: "Wasco", state: "California"},
    {city: "Waterford", state: "California"},
    {city: "Watsonville", state: "California"},
    {city: "West Athens", state: "California"},
    {city: "West Carson", state: "California"},
    {city: "West Covina", state: "California"},
    {city: "West Hollywood", state: "California"},
    {city: "West Modesto", state: "California"},
    {city: "West Puente Valley", state: "California"},
    {city: "West Sacramento", state: "California"},
    {city: "West Whittier-Los Nietos", state: "California"},
    {city: "Westlake Village", state: "California"},
    {city: "Westminster", state: "California"},
    {city: "Westmont", state: "California"},
    {city: "Whittier", state: "California"},
    {city: "Wildomar", state: "California"},
    {city: "Willowbrook", state: "California"},
    {city: "Willows", state: "California"},
    {city: "Windsor", state: "California"},
    {city: "Winter Gardens", state: "California"},
    {city: "Winters", state: "California"},
    {city: "Winton", state: "California"},
    {city: "Woodcrest", state: "California"},
    {city: "Woodlake", state: "California"},
    {city: "Woodland", state: "California"},
    {city: "Yolo", state: "California"},
    {city: "Yorba Linda", state: "California"},
    {city: "Yreka", state: "California"},
    {city: "Yuba City", state: "California"},
    {city: "Yucaipa", state: "California"},
    {city: "Yucca Valley", state: "California"},
    {city: "Air Force Academy", state: "Colorado"},
    {city: "Alamosa", state: "Colorado"},
    {city: "Applewood", state: "Colorado"},
    {city: "Arvada", state: "Colorado"},
    {city: "Aurora", state: "Colorado"},
    {city: "Berkley", state: "Colorado"},
    {city: "Black Forest", state: "Colorado"},
    {city: "Boulder", state: "Colorado"},
    {city: "Brighton", state: "Colorado"},
    {city: "Broomfield", state: "Colorado"},
    {city: "Canon City", state: "Colorado"},
    {city: "Castle Rock", state: "Colorado"},
    {city: "Castlewood", state: "Colorado"},
    {city: "Centennial", state: "Colorado"},
    {city: "Cimarron Hills", state: "Colorado"},
    {city: "Clifton", state: "Colorado"},
    {city: "Colorado Springs", state: "Colorado"},
    {city: "Columbine", state: "Colorado"},
    {city: "Commerce City", state: "Colorado"},
    {city: "Cortez", state: "Colorado"},
    {city: "Craig", state: "Colorado"},
    {city: "Delta", state: "Colorado"},
    {city: "Denver", state: "Colorado"},
    {city: "Derby", state: "Colorado"},
    {city: "Durango", state: "Colorado"},
    {city: "Edwards", state: "Colorado"},
    {city: "Englewood", state: "Colorado"},
    {city: "Erie", state: "Colorado"},
    {city: "Evans", state: "Colorado"},
    {city: "Evergreen", state: "Colorado"},
    {city: "Federal Heights", state: "Colorado"},
    {city: "Fort Carson", state: "Colorado"},
    {city: "Fort Collins", state: "Colorado"},
    {city: "Fort Lupton", state: "Colorado"},
    {city: "Fort Morgan", state: "Colorado"},
    {city: "Fountain", state: "Colorado"},
    {city: "Fruita", state: "Colorado"},
    {city: "Fruitvale", state: "Colorado"},
    {city: "Glenwood Springs", state: "Colorado"},
    {city: "Golden", state: "Colorado"},
    {city: "Grand Junction", state: "Colorado"},
    {city: "Greeley", state: "Colorado"},
    {city: "Greenwood Village", state: "Colorado"},
    {city: "Gunbarrel", state: "Colorado"},
    {city: "Highlands Ranch", state: "Colorado"},
    {city: "Ken Caryl", state: "Colorado"},
    {city: "La Junta", state: "Colorado"},
    {city: "Lafayette", state: "Colorado"},
    {city: "Lakewood", state: "Colorado"},
    {city: "Lamar", state: "Colorado"},
    {city: "Littleton", state: "Colorado"},
    {city: "Longmont", state: "Colorado"},
    {city: "Louisville", state: "Colorado"},
    {city: "Loveland", state: "Colorado"},
    {city: "Montrose", state: "Colorado"},
    {city: "Northglenn", state: "Colorado"},
    {city: "Orchard Mesa", state: "Colorado"},
    {city: "Parker", state: "Colorado"},
    {city: "Pueblo", state: "Colorado"},
    {city: "Pueblo West", state: "Colorado"},
    {city: "Redlands", state: "Colorado"},
    {city: "Rifle", state: "Colorado"},
    {city: "Security-Widefield", state: "Colorado"},
    {city: "Sherrelwood", state: "Colorado"},
    {city: "Southglenn", state: "Colorado"},
    {city: "Steamboat Springs", state: "Colorado"},
    {city: "Sterling", state: "Colorado"},
    {city: "Stonegate", state: "Colorado"},
    {city: "Stratmoor", state: "Colorado"},
    {city: "Superior", state: "Colorado"},
    {city: "The Pinery", state: "Colorado"},
    {city: "Thornton", state: "Colorado"},
    {city: "Trinidad", state: "Colorado"},
    {city: "Twin Lakes", state: "Colorado"},
    {city: "Welby", state: "Colorado"},
    {city: "Westminster", state: "Colorado"},
    {city: "Wheat Ridge", state: "Colorado"},
    {city: "Windsor", state: "Colorado"},
    {city: "Woodland Park", state: "Colorado"},
    {city: "Woodmoor", state: "Colorado"},
    {city: "Ansonia", state: "Connecticut"},
    {city: "Avon", state: "Connecticut"},
    {city: "Berlin", state: "Connecticut"},
    {city: "Bethel", state: "Connecticut"},
    {city: "Bloomfield", state: "Connecticut"},
    {city: "Branford", state: "Connecticut"},
    {city: "Bridgeport", state: "Connecticut"},
    {city: "Bristol", state: "Connecticut"},
    {city: "Brookfield", state: "Connecticut"},
    {city: "Brooklyn", state: "Connecticut"},
    {city: "Burlington", state: "Connecticut"},
    {city: "Canton", state: "Connecticut"},
    {city: "Central Manchester", state: "Connecticut"},
    {city: "Cheshire", state: "Connecticut"},
    {city: "Clinton", state: "Connecticut"},
    {city: "Colchester", state: "Connecticut"},
    {city: "Conning Towers-Nautilus Park", state: "Connecticut"},
    {city: "Coventry", state: "Connecticut"},
    {city: "Cromwell", state: "Connecticut"},
    {city: "Danbury", state: "Connecticut"},
    {city: "Darien", state: "Connecticut"},
    {city: "Derby", state: "Connecticut"},
    {city: "Durham", state: "Connecticut"},
    {city: "East Haddam", state: "Connecticut"},
    {city: "East Hampton", state: "Connecticut"},
    {city: "East Hartford", state: "Connecticut"},
    {city: "East Haven", state: "Connecticut"},
    {city: "East Lyme", state: "Connecticut"},
    {city: "East Windsor", state: "Connecticut"},
    {city: "Easton", state: "Connecticut"},
    {city: "Ellington", state: "Connecticut"},
    {city: "Enfield", state: "Connecticut"},
    {city: "Essex", state: "Connecticut"},
    {city: "Fairfield", state: "Connecticut"},
    {city: "Farmington", state: "Connecticut"},
    {city: "Glastonbury", state: "Connecticut"},
    {city: "Glastonbury Center", state: "Connecticut"},
    {city: "Granby", state: "Connecticut"},
    {city: "Greenwich", state: "Connecticut"},
    {city: "Griswold", state: "Connecticut"},
    {city: "Groton", state: "Connecticut"},
    {city: "Guilford", state: "Connecticut"},
    {city: "Haddam", state: "Connecticut"},
    {city: "Hamden", state: "Connecticut"},
    {city: "Hartford", state: "Connecticut"},
    {city: "Hebron", state: "Connecticut"},
    {city: "Kensington", state: "Connecticut"},
    {city: "Killingly", state: "Connecticut"},
    {city: "Killingworth", state: "Connecticut"},
    {city: "Lebanon", state: "Connecticut"},
    {city: "Ledyard", state: "Connecticut"},
    {city: "Litchfield", state: "Connecticut"},
    {city: "Madison", state: "Connecticut"},
    {city: "Manchester", state: "Connecticut"},
    {city: "Mansfield", state: "Connecticut"},
    {city: "Meriden", state: "Connecticut"},
    {city: "Middlebury", state: "Connecticut"},
    {city: "Middletown", state: "Connecticut"},
    {city: "Milford", state: "Connecticut"},
    {city: "Monroe", state: "Connecticut"},
    {city: "Montville", state: "Connecticut"},
    {city: "Naugatuck", state: "Connecticut"},
    {city: "New Britain", state: "Connecticut"},
    {city: "New Canaan", state: "Connecticut"},
    {city: "New Fairfield", state: "Connecticut"},
    {city: "New Hartford", state: "Connecticut"},
    {city: "New Haven", state: "Connecticut"},
    {city: "New London", state: "Connecticut"},
    {city: "New Milford", state: "Connecticut"},
    {city: "Newington", state: "Connecticut"},
    {city: "Newtown", state: "Connecticut"},
    {city: "North Branford", state: "Connecticut"},
    {city: "North Haven", state: "Connecticut"},
    {city: "Norwalk", state: "Connecticut"},
    {city: "Norwich", state: "Connecticut"},
    {city: "Oakville", state: "Connecticut"},
    {city: "Old Lyme", state: "Connecticut"},
    {city: "Old Saybrook", state: "Connecticut"},
    {city: "Orange", state: "Connecticut"},
    {city: "Oxford", state: "Connecticut"},
    {city: "Plainfield", state: "Connecticut"},
    {city: "Plainville", state: "Connecticut"},
    {city: "Plymouth", state: "Connecticut"},
    {city: "Portland", state: "Connecticut"},
    {city: "Prospect", state: "Connecticut"},
    {city: "Putnam", state: "Connecticut"},
    {city: "Putnam District", state: "Connecticut"},
    {city: "Redding", state: "Connecticut"},
    {city: "Ridgefield", state: "Connecticut"},
    {city: "Rockville", state: "Connecticut"},
    {city: "Rocky Hill", state: "Connecticut"},
    {city: "Seymour", state: "Connecticut"},
    {city: "Shelton", state: "Connecticut"},
    {city: "Simsbury", state: "Connecticut"},
    {city: "Somers", state: "Connecticut"},
    {city: "South Windsor", state: "Connecticut"},
    {city: "Southbury", state: "Connecticut"},
    {city: "Southington", state: "Connecticut"},
    {city: "Southwood Acres", state: "Connecticut"},
    {city: "Stafford", state: "Connecticut"},
    {city: "Stamford", state: "Connecticut"},
    {city: "Stonington", state: "Connecticut"},
    {city: "Storrs", state: "Connecticut"},
    {city: "Stratford", state: "Connecticut"},
    {city: "Suffield", state: "Connecticut"},
    {city: "Thomaston", state: "Connecticut"},
    {city: "Thompson", state: "Connecticut"},
    {city: "Thompsonville", state: "Connecticut"},
    {city: "Tolland", state: "Connecticut"},
    {city: "Torrington", state: "Connecticut"},
    {city: "Trumbull", state: "Connecticut"},
    {city: "Vernon", state: "Connecticut"},
    {city: "Wallingford", state: "Connecticut"},
    {city: "Wallingford Center", state: "Connecticut"},
    {city: "Waterbury", state: "Connecticut"},
    {city: "Waterford", state: "Connecticut"},
    {city: "Watertown", state: "Connecticut"},
    {city: "West Hartford", state: "Connecticut"},
    {city: "West Haven", state: "Connecticut"},
    {city: "Westbrook", state: "Connecticut"},
    {city: "Weston", state: "Connecticut"},
    {city: "Westport", state: "Connecticut"},
    {city: "Wethersfield", state: "Connecticut"},
    {city: "Willimantic", state: "Connecticut"},
    {city: "Wilton", state: "Connecticut"},
    {city: "Winchester", state: "Connecticut"},
    {city: "Windham", state: "Connecticut"},
    {city: "Windsor", state: "Connecticut"},
    {city: "Windsor Locks", state: "Connecticut"},
    {city: "Winsted", state: "Connecticut"},
    {city: "Wolcott", state: "Connecticut"},
    {city: "Woodbridge", state: "Connecticut"},
    {city: "Woodbury", state: "Connecticut"},
    {city: "Woodstock", state: "Connecticut"},
    {city: "Bear", state: "Delaware"},
    {city: "Brookside", state: "Delaware"},
    {city: "Claymont", state: "Delaware"},
    {city: "Dover", state: "Delaware"},
    {city: "Glasgow", state: "Delaware"},
    {city: "Hockessin", state: "Delaware"},
    {city: "Middletown", state: "Delaware"},
    {city: "Milford", state: "Delaware"},
    {city: "Newark", state: "Delaware"},
    {city: "North Star", state: "Delaware"},
    {city: "Pike Creek", state: "Delaware"},
    {city: "Seaford", state: "Delaware"},
    {city: "Wilmington", state: "Delaware"},
    {city: "Wilmington Manor", state: "Delaware"},
    {city: "Washington DC", state: "District of Columbia"},
    {city: "Alachua", state: "Florida"},
    {city: "Altamonte Springs", state: "Florida"},
    {city: "Andover", state: "Florida"},
    {city: "Apollo Beach", state: "Florida"},
    {city: "Apopka", state: "Florida"},
    {city: "Arcadia", state: "Florida"},
    {city: "Atlantic Beach", state: "Florida"},
    {city: "Auburndale", state: "Florida"},
    {city: "Aventura", state: "Florida"},
    {city: "Avon Park", state: "Florida"},
    {city: "Azalea Park", state: "Florida"},
    {city: "Bartow", state: "Florida"},
    {city: "Bayonet Point", state: "Florida"},
    {city: "Bayshore Gardens", state: "Florida"},
    {city: "Beacon Square", state: "Florida"},
    {city: "Bee Ridge", state: "Florida"},
    {city: "Bellair-Meadowbrook Terrace", state: "Florida"},
    {city: "Belle Glade", state: "Florida"},
    {city: "Bellview", state: "Florida"},
    {city: "Beverly Hills", state: "Florida"},
    {city: "Bloomingdale", state: "Florida"},
    {city: "Boca Del Mar", state: "Florida"},
    {city: "Boca Raton", state: "Florida"},
    {city: "Bonita Springs", state: "Florida"},
    {city: "Boynton Beach", state: "Florida"},
    {city: "Bradenton", state: "Florida"},
    {city: "Brandon", state: "Florida"},
    {city: "Brent", state: "Florida"},
    {city: "Broadview Park", state: "Florida"},
    {city: "Brooksville", state: "Florida"},
    {city: "Brownsville", state: "Florida"},
    {city: "Callaway", state: "Florida"},
    {city: "Cape Canaveral", state: "Florida"},
    {city: "Cape Coral", state: "Florida"},
    {city: "Carol City", state: "Florida"},
    {city: "Casselberry", state: "Florida"},
    {city: "Century Village", state: "Florida"},
    {city: "Cheval", state: "Florida"},
    {city: "Citrus Park", state: "Florida"},
    {city: "Citrus Ridge", state: "Florida"},
    {city: "Clearwater", state: "Florida"},
    {city: "Clermont", state: "Florida"},
    {city: "Clewiston", state: "Florida"},
    {city: "Cocoa", state: "Florida"},
    {city: "Cocoa Beach", state: "Florida"},
    {city: "Coconut Creek", state: "Florida"},
    {city: "Collier Manor-Cresthaven", state: "Florida"},
    {city: "Conway", state: "Florida"},
    {city: "Cooper City", state: "Florida"},
    {city: "Coral Gables", state: "Florida"},
    {city: "Coral Springs", state: "Florida"},
    {city: "Coral Terrace", state: "Florida"},
    {city: "Country Club", state: "Florida"},
    {city: "Country Walk", state: "Florida"},
    {city: "Crestview", state: "Florida"},
    {city: "Cutler", state: "Florida"},
    {city: "Cutler Ridge", state: "Florida"},
    {city: "Cypress Gardens", state: "Florida"},
    {city: "Cypress Lake", state: "Florida"},
    {city: "Dade City", state: "Florida"},
    {city: "Dania Beach", state: "Florida"},
    {city: "Davie", state: "Florida"},
    {city: "Daytona Beach", state: "Florida"},
    {city: "De Bary", state: "Florida"},
    {city: "De Land", state: "Florida"},
    {city: "Deerfield Beach", state: "Florida"},
    {city: "Delray Beach", state: "Florida"},
    {city: "Deltona", state: "Florida"},
    {city: "Destin", state: "Florida"},
    {city: "Doctor Phillips", state: "Florida"},
    {city: "Doral", state: "Florida"},
    {city: "Dunedin", state: "Florida"},
    {city: "East Lake", state: "Florida"},
    {city: "East Perrine", state: "Florida"},
    {city: "Edgewater", state: "Florida"},
    {city: "Eglin AFB", state: "Florida"},
    {city: "Egypt Lake-Leto", state: "Florida"},
    {city: "Elfers", state: "Florida"},
    {city: "Englewood", state: "Florida"},
    {city: "Ensley", state: "Florida"},
    {city: "Estero", state: "Florida"},
    {city: "Eustis", state: "Florida"},
    {city: "Fairview Shores", state: "Florida"},
    {city: "Fern Park", state: "Florida"},
    {city: "Fernandina Beach", state: "Florida"},
    {city: "Ferry Pass", state: "Florida"},
    {city: "Florida City", state: "Florida"},
    {city: "Florida Ridge", state: "Florida"},
    {city: "Forest City", state: "Florida"},
    {city: "Fort Lauderdale", state: "Florida"},
    {city: "Fort Myers", state: "Florida"},
    {city: "Fort Myers Beach", state: "Florida"},
    {city: "Fort Pierce", state: "Florida"},
    {city: "Fort Pierce North", state: "Florida"},
    {city: "Fort Walton Beach", state: "Florida"},
    {city: "Fountainbleau", state: "Florida"},
    {city: "Fruit Cove", state: "Florida"},
    {city: "Fruitville", state: "Florida"},
    {city: "Gainesville", state: "Florida"},
    {city: "Gibsonton", state: "Florida"},
    {city: "Gifford", state: "Florida"},
    {city: "Gladeview", state: "Florida"},
    {city: "Glenvar Heights", state: "Florida"},
    {city: "Golden Gate", state: "Florida"},
    {city: "Golden Glades", state: "Florida"},
    {city: "Golden Lakes", state: "Florida"},
    {city: "Goldenrod", state: "Florida"},
    {city: "Gonzalez", state: "Florida"},
    {city: "Goulds", state: "Florida"},
    {city: "Greater Carrollwood", state: "Florida"},
    {city: "Greater Northdale", state: "Florida"},
    {city: "Greater Sun Center", state: "Florida"},
    {city: "Greenacres", state: "Florida"},
    {city: "Gulf Gate Estates", state: "Florida"},
    {city: "Gulfport", state: "Florida"},
    {city: "Haines City", state: "Florida"},
    {city: "Hallandale", state: "Florida"},
    {city: "Hamptons at Boca Raton", state: "Florida"},
    {city: "Hernando", state: "Florida"},
    {city: "Hialeah", state: "Florida"},
    {city: "Hialeah Gardens", state: "Florida"},
    {city: "Hobe Sound", state: "Florida"},
    {city: "Holiday", state: "Florida"},
    {city: "Holly Hill", state: "Florida"},
    {city: "Hollywood", state: "Florida"},
    {city: "Homestead", state: "Florida"},
    {city: "Homosassa Springs", state: "Florida"},
    {city: "Hudson", state: "Florida"},
    {city: "Hunters Creek", state: "Florida"},
    {city: "Immokalee", state: "Florida"},
    {city: "Indian Harbour Beach", state: "Florida"},
    {city: "Inverness", state: "Florida"},
    {city: "Inwood", state: "Florida"},
    {city: "Iona", state: "Florida"},
    {city: "Islamorada", state: "Florida"},
    {city: "Ives Estates", state: "Florida"},
    {city: "Jacksonville", state: "Florida"},
    {city: "Jacksonville Beach", state: "Florida"},
    {city: "Jasmine Estates", state: "Florida"},
    {city: "Jensen Beach", state: "Florida"},
    {city: "Jupiter", state: "Florida"},
    {city: "Kendale Lakes", state: "Florida"},
    {city: "Kendall", state: "Florida"},
    {city: "Kendall West", state: "Florida"},
    {city: "Key Biscayne", state: "Florida"},
    {city: "Key Largo", state: "Florida"},
    {city: "Key West", state: "Florida"},
    {city: "Keystone", state: "Florida"},
    {city: "Kings Point", state: "Florida"},
    {city: "Kissimmee", state: "Florida"},
    {city: "Lady Lake", state: "Florida"},
    {city: "Lake Butter", state: "Florida"},
    {city: "Lake City", state: "Florida"},
    {city: "Lake Lorraine", state: "Florida"},
    {city: "Lake Lucerne", state: "Florida"},
    {city: "Lake Magdalene", state: "Florida"},
    {city: "Lake Mary", state: "Florida"},
    {city: "Lake Park", state: "Florida"},
    {city: "Lake Wales", state: "Florida"},
    {city: "Lake Worth", state: "Florida"},
    {city: "Lake Worth Corridor", state: "Florida"},
    {city: "Lakeland", state: "Florida"},
    {city: "Lakeland Highlands", state: "Florida"},
    {city: "Lakes by the Bay", state: "Florida"},
    {city: "Lakeside", state: "Florida"},
    {city: "Lakewood Park", state: "Florida"},
    {city: "Land O’ Lakes", state: "Florida"},
    {city: "Lantana", state: "Florida"},
    {city: "Largo", state: "Florida"},
    {city: "Lauderdale Lakes", state: "Florida"},
    {city: "Lauderhill", state: "Florida"},
    {city: "Laurel", state: "Florida"},
    {city: "Lealman", state: "Florida"},
    {city: "Leesburg", state: "Florida"},
    {city: "Lehigh Acres", state: "Florida"},
    {city: "Leisure City", state: "Florida"},
    {city: "Lighthouse Point", state: "Florida"},
    {city: "Live Oak", state: "Florida"},
    {city: "Lockhart", state: "Florida"},
    {city: "Longboat Key", state: "Florida"},
    {city: "Longwood", state: "Florida"},
    {city: "Lutz", state: "Florida"},
    {city: "Lynn Haven", state: "Florida"},
    {city: "Maitland", state: "Florida"},
    {city: "Mango", state: "Florida"},
    {city: "Marathon", state: "Florida"},
    {city: "Marco Island", state: "Florida"},
    {city: "Margate", state: "Florida"},
    {city: "Marianna", state: "Florida"},
    {city: "McGregor", state: "Florida"},
    {city: "Meadow Woods", state: "Florida"},
    {city: "Medulla", state: "Florida"},
    {city: "Melbourne", state: "Florida"},
    {city: "Melrose Park", state: "Florida"},
    {city: "Memphis", state: "Florida"},
    {city: "Merritt Island", state: "Florida"},
    {city: "Miami", state: "Florida"},
    {city: "Miami Beach", state: "Florida"},
    {city: "Miami Lakes", state: "Florida"},
    {city: "Miami Shores", state: "Florida"},
    {city: "Miami Springs", state: "Florida"},
    {city: "Micco", state: "Florida"},
    {city: "Middleburg", state: "Florida"},
    {city: "Milton", state: "Florida"},
    {city: "Mims", state: "Florida"},
    {city: "Miramar", state: "Florida"},
    {city: "Mount Dora", state: "Florida"},
    {city: "Myrtle Grove", state: "Florida"},
    {city: "Naples", state: "Florida"},
    {city: "Naples Park", state: "Florida"},
    {city: "Neptune Beach", state: "Florida"},
    {city: "New Port Richey", state: "Florida"},
    {city: "New Port Richey East", state: "Florida"},
    {city: "New Smyrna Beach", state: "Florida"},
    {city: "Niceville", state: "Florida"},
    {city: "Norland", state: "Florida"},
    {city: "North Andrews Gardens", state: "Florida"},
    {city: "North Bay Village", state: "Florida"},
    {city: "North Fort Myers", state: "Florida"},
    {city: "North Lauderdale", state: "Florida"},
    {city: "North Miami", state: "Florida"},
    {city: "North Miami Beach", state: "Florida"},
    {city: "North Palm Beach", state: "Florida"},
    {city: "North Port", state: "Florida"},
    {city: "North Sarasota", state: "Florida"},
    {city: "Oak Ridge", state: "Florida"},
    {city: "Oakland Park", state: "Florida"},
    {city: "Ocala", state: "Florida"},
    {city: "Ocoee", state: "Florida"},
    {city: "Ojus", state: "Florida"},
    {city: "Oldsmar", state: "Florida"},
    {city: "Olympia Heights", state: "Florida"},
    {city: "Opa-locka", state: "Florida"},
    {city: "Opa-locka North", state: "Florida"},
    {city: "Orange City", state: "Florida"},
    {city: "Orange Park", state: "Florida"},
    {city: "Orlando", state: "Florida"},
    {city: "Orlovista", state: "Florida"},
    {city: "Ormond Beach", state: "Florida"},
    {city: "Ormond-By-The-Sea", state: "Florida"},
    {city: "Oviedo", state: "Florida"},
    {city: "Pace", state: "Florida"},
    {city: "Palatka", state: "Florida"},
    {city: "Palm Bay", state: "Florida"},
    {city: "Palm Beach", state: "Florida"},
    {city: "Palm Beach Gardens", state: "Florida"},
    {city: "Palm City", state: "Florida"},
    {city: "Palm Coast", state: "Florida"},
    {city: "Palm Harbor", state: "Florida"},
    {city: "Palm River-Clair Mel", state: "Florida"},
    {city: "Palm Springs", state: "Florida"},
    {city: "Palm Valley", state: "Florida"},
    {city: "Palmetto", state: "Florida"},
    {city: "Palmetto Estates", state: "Florida"},
    {city: "Panama City", state: "Florida"},
    {city: "Panama City Beach", state: "Florida"},
    {city: "Parkland", state: "Florida"},
    {city: "Pembroke Park", state: "Florida"},
    {city: "Pembroke Pines", state: "Florida"},
    {city: "Pensacola", state: "Florida"},
    {city: "Perry", state: "Florida"},
    {city: "Pine Castle", state: "Florida"},
    {city: "Pine Hills", state: "Florida"},
    {city: "Pinecrest", state: "Florida"},
    {city: "Pinellas Park", state: "Florida"},
    {city: "Pinewood", state: "Florida"},
    {city: "Plant City", state: "Florida"},
    {city: "Plantation", state: "Florida"},
    {city: "Poinciana", state: "Florida"},
    {city: "Pompano Beach", state: "Florida"},
    {city: "Pompano Beach Highlands", state: "Florida"},
    {city: "Port Charlotte", state: "Florida"},
    {city: "Port Orange", state: "Florida"},
    {city: "Port Salerno", state: "Florida"},
    {city: "Port St. John", state: "Florida"},
    {city: "Port St. Lucie", state: "Florida"},
    {city: "Princeton", state: "Florida"},
    {city: "Punta Gorda", state: "Florida"},
    {city: "Quincy", state: "Florida"},
    {city: "Richmond Heights", state: "Florida"},
    {city: "Richmond West", state: "Florida"},
    {city: "Riverview", state: "Florida"},
    {city: "Riviera Beach", state: "Florida"},
    {city: "Rockledge", state: "Florida"},
    {city: "Rotonda", state: "Florida"},
    {city: "Royal Palm Beach", state: "Florida"},
    {city: "Ruskin", state: "Florida"},
    {city: "Safety Harbor", state: "Florida"},
    {city: "San Carlos Park", state: "Florida"},
    {city: "Sandalfoot Cove", state: "Florida"},
    {city: "Sanford", state: "Florida"},
    {city: "Sanibel", state: "Florida"},
    {city: "Sarasota", state: "Florida"},
    {city: "Sarasota Springs", state: "Florida"},
    {city: "Satellite Beach", state: "Florida"},
    {city: "Scott Lake", state: "Florida"},
    {city: "Sebastian", state: "Florida"},
    {city: "Sebring", state: "Florida"},
    {city: "Seminole", state: "Florida"},
    {city: "Shady Hills", state: "Florida"},
    {city: "Siesta Key", state: "Florida"},
    {city: "Silver Springs Shores", state: "Florida"},
    {city: "South Bradenton", state: "Florida"},
    {city: "South Daytona", state: "Florida"},
    {city: "South Highpoint", state: "Florida"},
    {city: "South Miami", state: "Florida"},
    {city: "South Miami Heights", state: "Florida"},
    {city: "South Patrick Shores", state: "Florida"},
    {city: "South Venice", state: "Florida"},
    {city: "Southeast Arcadia", state: "Florida"},
    {city: "Southgate", state: "Florida"},
    {city: "Spring Hill", state: "Florida"},
    {city: "Springfield", state: "Florida"},
    {city: "St. Augustine", state: "Florida"},
    {city: "St. Cloud", state: "Florida"},
    {city: "St. Pete Beach", state: "Florida"},
    {city: "St. Petersburg", state: "Florida"},
    {city: "Stuart", state: "Florida"},
    {city: "Sugarmill Woods", state: "Florida"},
    {city: "Sunny Isles Beach", state: "Florida"},
    {city: "Sunrise", state: "Florida"},
    {city: "Sunset", state: "Florida"},
    {city: "Sweetwater", state: "Florida"},
    {city: "Tallahassee", state: "Florida"},
    {city: "Tamarac", state: "Florida"},
    {city: "Tamiami", state: "Florida"},
    {city: "Tampa", state: "Florida"},
    {city: "Tarpon Springs", state: "Florida"},
    {city: "Tavares", state: "Florida"},
    {city: "Temple Terrace", state: "Florida"},
    {city: "The Crossings", state: "Florida"},
    {city: "The Hammocks", state: "Florida"},
    {city: "The Villages", state: "Florida"},
    {city: "Wildwood", state: "Florida"},
    {city: "Thonotosassa", state: "Florida"},
    {city: "Three Lakes", state: "Florida"},
    {city: "Titusville", state: "Florida"},
    {city: "Town 'n' Country", state: "Florida"},
    {city: "Treasure Island", state: "Florida"},
    {city: "Union Park", state: "Florida"},
    {city: "University", state: "Florida"},
    {city: "University Park", state: "Florida"},
    {city: "Upper Grand Lagoon", state: "Florida"},
    {city: "Valparaiso", state: "Florida"},
    {city: "Valrico", state: "Florida"},
    {city: "Venice", state: "Florida"},
    {city: "Venice Gardens", state: "Florida"},
    {city: "Vero Beach", state: "Florida"},
    {city: "Vero Beach South", state: "Florida"},
    {city: "Villas", state: "Florida"},
    {city: "Warrington", state: "Florida"},
    {city: "Wekiwa Springs", state: "Florida"},
    {city: "Wellington", state: "Florida"},
    {city: "West Little River", state: "Florida"},
    {city: "West Melbourne", state: "Florida"},
    {city: "West Palm Beach", state: "Florida"},
    {city: "West Pensacola", state: "Florida"},
    {city: "West Perrine", state: "Florida"},
    {city: "West Vero Corridor", state: "Florida"},
    {city: "Westchase", state: "Florida"},
    {city: "Westchester", state: "Florida"},
    {city: "Westgate-Belvedere Homes", state: "Florida"},
    {city: "Weston", state: "Florida"},
    {city: "Westview", state: "Florida"},
    {city: "Westwood Lakes", state: "Florida"},
    {city: "Williamsburg", state: "Florida"},
    {city: "Wilton Manors", state: "Florida"},
    {city: "Winston", state: "Florida"},
    {city: "Winter Garden", state: "Florida"},
    {city: "Winter Haven", state: "Florida"},
    {city: "Winter Park", state: "Florida"},
    {city: "Winter Springs", state: "Florida"},
    {city: "Wright", state: "Florida"},
    {city: "Yeehaw Junction", state: "Florida"},
    {city: "Yulee", state: "Florida"},
    {city: "Zephyrhills", state: "Florida"},
    {city: "Acworth", state: "Georgia"},
    {city: "Albany", state: "Georgia"},
    {city: "Alpharetta", state: "Georgia"},
    {city: "Americus", state: "Georgia"},
    {city: "Athens", state: "Georgia"},
    {city: "Atlanta", state: "Georgia"},
    {city: "Auburn", state: "Georgia"},
    {city: "Augusta", state: "Georgia"},
    {city: "Bainbridge", state: "Georgia"},
    {city: "Belvedere Park", state: "Georgia"},
    {city: "Brunswick", state: "Georgia"},
    {city: "Buford", state: "Georgia"},
    {city: "Cairo", state: "Georgia"},
    {city: "Calhoun", state: "Georgia"},
    {city: "Candler-McAfee", state: "Georgia"},
    {city: "Canton", state: "Georgia"},
    {city: "Carrollton", state: "Georgia"},
    {city: "Cartersville", state: "Georgia"},
    {city: "Cedartown", state: "Georgia"},
    {city: "Chamblee", state: "Georgia"},
    {city: "Clarkston", state: "Georgia"},
    {city: "College Park", state: "Georgia"},
    {city: "Columbus", state: "Georgia"},
    {city: "Conley", state: "Georgia"},
    {city: "Conyers", state: "Georgia"},
    {city: "Cordele", state: "Georgia"},
    {city: "Country Club Estates", state: "Georgia"},
    {city: "Covington", state: "Georgia"},
    {city: "Dalton", state: "Georgia"},
    {city: "Decatur", state: "Georgia"},
    {city: "Dock Junction", state: "Georgia"},
    {city: "Doraville", state: "Georgia"},
    {city: "Douglas", state: "Georgia"},
    {city: "Douglasville", state: "Georgia"},
    {city: "Druid Hills", state: "Georgia"},
    {city: "Dublin", state: "Georgia"},
    {city: "Duluth", state: "Georgia"},
    {city: "Dunwoody", state: "Georgia"},
    {city: "East Point", state: "Georgia"},
    {city: "Eatonton", state: "Georgia"},
    {city: "Evans", state: "Georgia"},
    {city: "Fair Oaks", state: "Georgia"},
    {city: "Fairview", state: "Georgia"},
    {city: "Fayetteville", state: "Georgia"},
    {city: "Fitzgerald", state: "Georgia"},
    {city: "Forest Park", state: "Georgia"},
    {city: "Fort Benning South", state: "Georgia"},
    {city: "Fort Oglethorpe", state: "Georgia"},
    {city: "Fort Stewart", state: "Georgia"},
    {city: "Fort Valley", state: "Georgia"},
    {city: "Gainesville", state: "Georgia"},
    {city: "Garden City", state: "Georgia"},
    {city: "Georgetown", state: "Georgia"},
    {city: "Gresham Park", state: "Georgia"},
    {city: "Griffin", state: "Georgia"},
    {city: "Grovetown", state: "Georgia"},
    {city: "Hapeville", state: "Georgia"},
    {city: "Hephzibah", state: "Georgia"},
    {city: "Hinesville", state: "Georgia"},
    {city: "Irondale", state: "Georgia"},
    {city: "Jesup", state: "Georgia"},
    {city: "Kennesaw", state: "Georgia"},
    {city: "Kingsland", state: "Georgia"},
    {city: "La Fayette", state: "Georgia"},
    {city: "LaGrange", state: "Georgia"},
    {city: "Lawrenceville", state: "Georgia"},
    {city: "Lilburn", state: "Georgia"},
    {city: "Mableton", state: "Georgia"},
    {city: "Macon", state: "Georgia"},
    {city: "Marietta", state: "Georgia"},
    {city: "Martinez", state: "Georgia"},
    {city: "McDonough", state: "Georgia"},
    {city: "Milledgeville", state: "Georgia"},
    {city: "Monroe", state: "Georgia"},
    {city: "Moultrie", state: "Georgia"},
    {city: "Mountain Park", state: "Georgia"},
    {city: "Newnan", state: "Georgia"},
    {city: "Norcross", state: "Georgia"},
    {city: "North Atlanta", state: "Georgia"},
    {city: "North Decatur", state: "Georgia"},
    {city: "North Druid Hills", state: "Georgia"},
    {city: "Panthersville", state: "Georgia"},
    {city: "Peachtree City", state: "Georgia"},
    {city: "Perry", state: "Georgia"},
    {city: "Pooler", state: "Georgia"},
    {city: "Powder Springs", state: "Georgia"},
    {city: "Redan", state: "Georgia"},
    {city: "Richmond Hill", state: "Georgia"},
    {city: "Riverdale", state: "Georgia"},
    {city: "Rome", state: "Georgia"},
    {city: "Roswell", state: "Georgia"},
    {city: "Sandersville", state: "Georgia"},
    {city: "Sandy Springs", state: "Georgia"},
    {city: "Savannah", state: "Georgia"},
    {city: "Scottdale", state: "Georgia"},
    {city: "Skidaway Island", state: "Georgia"},
    {city: "Smyrna", state: "Georgia"},
    {city: "Snellville", state: "Georgia"},
    {city: "St. Marys", state: "Georgia"},
    {city: "St. Simons", state: "Georgia"},
    {city: "Statesboro", state: "Georgia"},
    {city: "Stockbridge", state: "Georgia"},
    {city: "Stone Mountain", state: "Georgia"},
    {city: "Sugar Hill", state: "Georgia"},
    {city: "Suwanee", state: "Georgia"},
    {city: "Swainsboro", state: "Georgia"},
    {city: "Thomaston", state: "Georgia"},
    {city: "Thomasville", state: "Georgia"},
    {city: "Thomson", state: "Georgia"},
    {city: "Tifton", state: "Georgia"},
    {city: "Toccoa", state: "Georgia"},
    {city: "Tucker", state: "Georgia"},
    {city: "Union City", state: "Georgia"},
    {city: "Valdosta", state: "Georgia"},
    {city: "Vidalia", state: "Georgia"},
    {city: "Vinings", state: "Georgia"},
    {city: "Warner Robins", state: "Georgia"},
    {city: "Waycross", state: "Georgia"},
    {city: "Wilmington Island", state: "Georgia"},
    {city: "Winder", state: "Georgia"},
    {city: "Winterville", state: "Georgia"},
    {city: "Woodstock", state: "Georgia"},
    {city: "Ahuimanu", state: "Hawaii"},
    {city: "Aiea", state: "Hawaii"},
    {city: "Ewa Beach", state: "Hawaii"},
    {city: "Haiku-Pauwela", state: "Hawaii"},
    {city: "Halawa", state: "Hawaii"},
    {city: "Hawaiian Paradise Park", state: "Hawaii"},
    {city: "Hilo", state: "Hawaii"},
    {city: "Holualoa", state: "Hawaii"},
    {city: "Honolulu", state: "Hawaii"},
    {city: "Kahului", state: "Hawaii"},
    {city: "Kailua", state: "Hawaii"},
    {city: "Kalaoa", state: "Hawaii"},
    {city: "Kaneohe", state: "Hawaii"},
    {city: "Kaneohe Station", state: "Hawaii"},
    {city: "Kapaa", state: "Hawaii"},
    {city: "Kihei", state: "Hawaii"},
    {city: "Lahaina", state: "Hawaii"},
    {city: "Makaha", state: "Hawaii"},
    {city: "Makakilo City", state: "Hawaii"},
    {city: "Makawao", state: "Hawaii"},
    {city: "Maui", state: "Hawaii"},
    {city: "Mililani Town", state: "Hawaii"},
    {city: "Nanakuli", state: "Hawaii"},
    {city: "Napili-Honokowai", state: "Hawaii"},
    {city: "Pearl City", state: "Hawaii"},
    {city: "Pukalani", state: "Hawaii"},
    {city: "Schofield Barracks", state: "Hawaii"},
    {city: "Village Park", state: "Hawaii"},
    {city: "Wahiawa", state: "Hawaii"},
    {city: "Waianae", state: "Hawaii"},
    {city: "Waihee-Waiehu", state: "Hawaii"},
    {city: "Wailuku", state: "Hawaii"},
    {city: "Waimalu", state: "Hawaii"},
    {city: "Waimea", state: "Hawaii"},
    {city: "Waipahu", state: "Hawaii"},
    {city: "Waipio", state: "Hawaii"},
    {city: "Ammon", state: "Idaho"},
    {city: "Blackfoot", state: "Idaho"},
    {city: "Boise", state: "Idaho"},
    {city: "Burley", state: "Idaho"},
    {city: "Caldwell", state: "Idaho"},
    {city: "Chubbuck", state: "Idaho"},
    {city: "Coeur d’Alene", state: "Idaho"},
    {city: "Eagle", state: "Idaho"},
    {city: "Garden City", state: "Idaho"},
    {city: "Hailey", state: "Idaho"},
    {city: "Hayden", state: "Idaho"},
    {city: "Idaho Falls", state: "Idaho"},
    {city: "Jerome", state: "Idaho"},
    {city: "Lewiston", state: "Idaho"},
    {city: "Meridian", state: "Idaho"},
    {city: "Moscow", state: "Idaho"},
    {city: "Mountain Home", state: "Idaho"},
    {city: "Mountain Home AFB", state: "Idaho"},
    {city: "Nampa", state: "Idaho"},
    {city: "Payette", state: "Idaho"},
    {city: "Pocatello", state: "Idaho"},
    {city: "Post Falls", state: "Idaho"},
    {city: "Rexburg", state: "Idaho"},
    {city: "Salmon", state: "Idaho"},
    {city: "Sandpoint", state: "Idaho"},
    {city: "St. Anthony", state: "Idaho"},
    {city: "St. Maries", state: "Idaho"},
    {city: "Twin Falls", state: "Idaho"},
    {city: "Addison", state: "Illinois"},
    {city: "Algonquin", state: "Illinois"},
    {city: "Alsip", state: "Illinois"},
    {city: "Alton", state: "Illinois"},
    {city: "Antioch", state: "Illinois"},
    {city: "Arlington Heights", state: "Illinois"},
    {city: "Aurora", state: "Illinois"},
    {city: "Barrington", state: "Illinois"},
    {city: "Bartlett", state: "Illinois"},
    {city: "Bartonville", state: "Illinois"},
    {city: "Batavia", state: "Illinois"},
    {city: "Beach Park", state: "Illinois"},
    {city: "Belleville", state: "Illinois"},
    {city: "Bellwood", state: "Illinois"},
    {city: "Belvidere", state: "Illinois"},
    {city: "Bensenville", state: "Illinois"},
    {city: "Benton", state: "Illinois"},
    {city: "Berwyn", state: "Illinois"},
    {city: "Bethalto", state: "Illinois"},
    {city: "Bloomingdale", state: "Illinois"},
    {city: "Bloomington", state: "Illinois"},
    {city: "Blue Island", state: "Illinois"},
    {city: "Bolingbrook", state: "Illinois"},
    {city: "Boulder Hill", state: "Illinois"},
    {city: "Bourbonnais", state: "Illinois"},
    {city: "Bradley", state: "Illinois"},
    {city: "Bridgeview", state: "Illinois"},
    {city: "Broadview", state: "Illinois"},
    {city: "Brookfield", state: "Illinois"},
    {city: "Buffalo Grove", state: "Illinois"},
    {city: "Burbank", state: "Illinois"},
    {city: "Burr Ridge", state: "Illinois"},
    {city: "Cahokia", state: "Illinois"},
    {city: "Calumet City", state: "Illinois"},
    {city: "Calumet Park", state: "Illinois"},
    {city: "Canton", state: "Illinois"},
    {city: "Carbondale", state: "Illinois"},
    {city: "Carol Stream", state: "Illinois"},
    {city: "Carpentersville", state: "Illinois"},
    {city: "Cary", state: "Illinois"},
    {city: "Centralia", state: "Illinois"},
    {city: "Champaign", state: "Illinois"},
    {city: "Channahon", state: "Illinois"},
    {city: "Charleston", state: "Illinois"},
    {city: "Chatham", state: "Illinois"},
    {city: "Chicago", state: "Illinois"},
    {city: "Chicago Heights", state: "Illinois"},
    {city: "Chicago Ridge", state: "Illinois"},
    {city: "Cicero", state: "Illinois"},
    {city: "Clarendon Hills", state: "Illinois"},
    {city: "Clinton", state: "Illinois"},
    {city: "Collinsville", state: "Illinois"},
    {city: "Columbia", state: "Illinois"},
    {city: "Country Club Hills", state: "Illinois"},
    {city: "Crest Hill", state: "Illinois"},
    {city: "Crestwood", state: "Illinois"},
    {city: "Crete", state: "Illinois"},
    {city: "Crystal Lake", state: "Illinois"},
    {city: "Danville", state: "Illinois"},
    {city: "Darien", state: "Illinois"},
    {city: "Decatur", state: "Illinois"},
    {city: "Deerfield", state: "Illinois"},
    {city: "DeKalb", state: "Illinois"},
    {city: "Des Plaines", state: "Illinois"},
    {city: "Dixon", state: "Illinois"},
    {city: "Dolton", state: "Illinois"},
    {city: "Downers Grove", state: "Illinois"},
    {city: "Du Quoin", state: "Illinois"},
    {city: "East Alton", state: "Illinois"},
    {city: "East Moline", state: "Illinois"},
    {city: "East Peoria", state: "Illinois"},
    {city: "East St. Louis", state: "Illinois"},
    {city: "Edwardsville", state: "Illinois"},
    {city: "Effingham", state: "Illinois"},
    {city: "Elgin", state: "Illinois"},
    {city: "Elk Grove Village", state: "Illinois"},
    {city: "Elmhurst", state: "Illinois"},
    {city: "Elmwood Park", state: "Illinois"},
    {city: "Evanston", state: "Illinois"},
    {city: "Evergreen Park", state: "Illinois"},
    {city: "Fairview Heights", state: "Illinois"},
    {city: "Flossmoor", state: "Illinois"},
    {city: "Forest Park", state: "Illinois"},
    {city: "Fox Lake", state: "Illinois"},
    {city: "Frankfort", state: "Illinois"},
    {city: "Frankfort Square", state: "Illinois"},
    {city: "Franklin Park", state: "Illinois"},
    {city: "Freeport", state: "Illinois"},
    {city: "Gages Lake", state: "Illinois"},
    {city: "Galesburg", state: "Illinois"},
    {city: "Geneseo", state: "Illinois"},
    {city: "Geneva", state: "Illinois"},
    {city: "Glen Carbon", state: "Illinois"},
    {city: "Glen Ellyn", state: "Illinois"},
    {city: "Glencoe", state: "Illinois"},
    {city: "Glendale Heights", state: "Illinois"},
    {city: "Glenview", state: "Illinois"},
    {city: "Glenwood", state: "Illinois"},
    {city: "Godfrey", state: "Illinois"},
    {city: "Goodings Grove", state: "Illinois"},
    {city: "Granite City", state: "Illinois"},
    {city: "Grayslake", state: "Illinois"},
    {city: "Greenville", state: "Illinois"},
    {city: "Gurnee", state: "Illinois"},
    {city: "Hanover Park", state: "Illinois"},
    {city: "Harrisburg", state: "Illinois"},
    {city: "Harvard", state: "Illinois"},
    {city: "Harvey", state: "Illinois"},
    {city: "Harwood Heights", state: "Illinois"},
    {city: "Hawthorn Woods", state: "Illinois"},
    {city: "Hazel Crest", state: "Illinois"},
    {city: "Herrin", state: "Illinois"},
    {city: "Hickory Hills", state: "Illinois"},
    {city: "Highland", state: "Illinois"},
    {city: "Highland Park", state: "Illinois"},
    {city: "Hillside", state: "Illinois"},
    {city: "Hinsdale", state: "Illinois"},
    {city: "Hoffman Estates", state: "Illinois"},
    {city: "Homewood", state: "Illinois"},
    {city: "Inverness", state: "Illinois"},
    {city: "Island Lake", state: "Illinois"},
    {city: "Itasca", state: "Illinois"},
    {city: "Jacksonville", state: "Illinois"},
    {city: "Jerseyville", state: "Illinois"},
    {city: "Joliet", state: "Illinois"},
    {city: "Justice", state: "Illinois"},
    {city: "Kankakee", state: "Illinois"},
    {city: "Kewanee", state: "Illinois"},
    {city: "La Grange", state: "Illinois"},
    {city: "La Grange Park", state: "Illinois"},
    {city: "La Salle", state: "Illinois"},
    {city: "Lake Bluff", state: "Illinois"},
    {city: "Lake Forest", state: "Illinois"},
    {city: "Lake in the Hills", state: "Illinois"},
    {city: "Lake Zurich", state: "Illinois"},
    {city: "Lansing", state: "Illinois"},
    {city: "Lemont", state: "Illinois"},
    {city: "Libertyville", state: "Illinois"},
    {city: "Lincoln", state: "Illinois"},
    {city: "Lincolnshire", state: "Illinois"},
    {city: "Lincolnwood", state: "Illinois"},
    {city: "Lindenhurst", state: "Illinois"},
    {city: "Lisle", state: "Illinois"},
    {city: "Litchfield", state: "Illinois"},
    {city: "Lockport", state: "Illinois"},
    {city: "Lombard", state: "Illinois"},
    {city: "Long Grove", state: "Illinois"},
    {city: "Loves Park", state: "Illinois"},
    {city: "Lynwood", state: "Illinois"},
    {city: "Lyons", state: "Illinois"},
    {city: "Machesney Park", state: "Illinois"},
    {city: "Macomb", state: "Illinois"},
    {city: "Manteno", state: "Illinois"},
    {city: "Marengo", state: "Illinois"},
    {city: "Marion", state: "Illinois"},
    {city: "Markham", state: "Illinois"},
    {city: "Matteson", state: "Illinois"},
    {city: "Mattoon", state: "Illinois"},
    {city: "Maywood", state: "Illinois"},
    {city: "McHenry", state: "Illinois"},
    {city: "Melrose Park", state: "Illinois"},
    {city: "Mendota", state: "Illinois"},
    {city: "Metropolis", state: "Illinois"},
    {city: "Midlothian", state: "Illinois"},
    {city: "Mokena", state: "Illinois"},
    {city: "Moline", state: "Illinois"},
    {city: "Monmouth", state: "Illinois"},
    {city: "Morris", state: "Illinois"},
    {city: "Morton", state: "Illinois"},
    {city: "Morton Grove", state: "Illinois"},
    {city: "Mount Carmel", state: "Illinois"},
    {city: "Mount Prospect", state: "Illinois"},
    {city: "Mount Vernon", state: "Illinois"},
    {city: "Mundelein", state: "Illinois"},
    {city: "Murphysboro", state: "Illinois"},
    {city: "Naperville", state: "Illinois"},
    {city: "New Lenox", state: "Illinois"},
    {city: "Niles", state: "Illinois"},
    {city: "Normal", state: "Illinois"},
    {city: "Norridge", state: "Illinois"},
    {city: "North Aurora", state: "Illinois"},
    {city: "North Chicago", state: "Illinois"},
    {city: "North Riverside", state: "Illinois"},
    {city: "Northbrook", state: "Illinois"},
    {city: "Northlake", state: "Illinois"},
    {city: "O’Fallon", state: "Illinois"},
    {city: "Oak Brook", state: "Illinois"},
    {city: "Oak Forest", state: "Illinois"},
    {city: "Oak Lawn", state: "Illinois"},
    {city: "Oak Park", state: "Illinois"},
    {city: "Olney", state: "Illinois"},
    {city: "Orland Hills", state: "Illinois"},
    {city: "Orland Park", state: "Illinois"},
    {city: "Oswego", state: "Illinois"},
    {city: "Ottawa", state: "Illinois"},
    {city: "Palatine", state: "Illinois"},
    {city: "Palos Heights", state: "Illinois"},
    {city: "Palos Hills", state: "Illinois"},
    {city: "Paris", state: "Illinois"},
    {city: "Park City", state: "Illinois"},
    {city: "Park Forest", state: "Illinois"},
    {city: "Park Ridge", state: "Illinois"},
    {city: "Pekin", state: "Illinois"},
    {city: "Peoria", state: "Illinois"},
    {city: "Peoria Heights", state: "Illinois"},
    {city: "Peru", state: "Illinois"},
    {city: "Plainfield", state: "Illinois"},
    {city: "Pontiac", state: "Illinois"},
    {city: "Princeton", state: "Illinois"},
    {city: "Prospect Heights", state: "Illinois"},
    {city: "Quincy", state: "Illinois"},
    {city: "Rantoul", state: "Illinois"},
    {city: "Richton Park", state: "Illinois"},
    {city: "River Forest", state: "Illinois"},
    {city: "River Grove", state: "Illinois"},
    {city: "Riverdale", state: "Illinois"},
    {city: "Riverside", state: "Illinois"},
    {city: "Robbins", state: "Illinois"},
    {city: "Robinson", state: "Illinois"},
    {city: "Rochelle", state: "Illinois"},
    {city: "Rock Falls", state: "Illinois"},
    {city: "Rock Island", state: "Illinois"},
    {city: "Rockford", state: "Illinois"},
    {city: "Rolling Meadows", state: "Illinois"},
    {city: "Romeoville", state: "Illinois"},
    {city: "Roscoe", state: "Illinois"},
    {city: "Roselle", state: "Illinois"},
    {city: "Round Lake Beach", state: "Illinois"},
    {city: "Round Lake Park", state: "Illinois"},
    {city: "Salem", state: "Illinois"},
    {city: "Sandwich", state: "Illinois"},
    {city: "Sauk Village", state: "Illinois"},
    {city: "Schaumburg", state: "Illinois"},
    {city: "Schiller Park", state: "Illinois"},
    {city: "Shiloh", state: "Illinois"},
    {city: "Shorewood", state: "Illinois"},
    {city: "Silvis", state: "Illinois"},
    {city: "Skokie", state: "Illinois"},
    {city: "South Elgin", state: "Illinois"},
    {city: "South Holland", state: "Illinois"},
    {city: "Springfield", state: "Illinois"},
    {city: "St. Charles", state: "Illinois"},
    {city: "Steger", state: "Illinois"},
    {city: "Sterling", state: "Illinois"},
    {city: "Stickney", state: "Illinois"},
    {city: "Streamwood", state: "Illinois"},
    {city: "Streator", state: "Illinois"},
    {city: "Summit", state: "Illinois"},
    {city: "Swansea", state: "Illinois"},
    {city: "Sycamore", state: "Illinois"},
    {city: "Taylorville", state: "Illinois"},
    {city: "Tinley Park", state: "Illinois"},
    {city: "Troy", state: "Illinois"},
    {city: "University Park", state: "Illinois"},
    {city: "Urbana", state: "Illinois"},
    {city: "Vandalia", state: "Illinois"},
    {city: "Vernon Hills", state: "Illinois"},
    {city: "Villa Park", state: "Illinois"},
    {city: "Warrenville", state: "Illinois"},
    {city: "Washington", state: "Illinois"},
    {city: "Waterloo", state: "Illinois"},
    {city: "Wauconda", state: "Illinois"},
    {city: "Waukegan", state: "Illinois"},
    {city: "West Chicago", state: "Illinois"},
    {city: "West Frankfort", state: "Illinois"},
    {city: "Westchester", state: "Illinois"},
    {city: "Western Springs", state: "Illinois"},
    {city: "Westmont", state: "Illinois"},
    {city: "Wheaton", state: "Illinois"},
    {city: "Wheeling", state: "Illinois"},
    {city: "Willowbrook", state: "Illinois"},
    {city: "Wilmette", state: "Illinois"},
    {city: "Winfield", state: "Illinois"},
    {city: "Winnetka", state: "Illinois"},
    {city: "Winthrop Harbor", state: "Illinois"},
    {city: "Wonder Lake", state: "Illinois"},
    {city: "Wood Dale", state: "Illinois"},
    {city: "Wood River", state: "Illinois"},
    {city: "Woodridge", state: "Illinois"},
    {city: "Woodstock", state: "Illinois"},
    {city: "Worth", state: "Illinois"},
    {city: "Yorkville", state: "Illinois"},
    {city: "Zion", state: "Illinois"},
    {city: "Alexandria", state: "Indiana"},
    {city: "Anderson", state: "Indiana"},
    {city: "Angola", state: "Indiana"},
    {city: "Auburn", state: "Indiana"},
    {city: "Avon", state: "Indiana"},
    {city: "Batesville", state: "Indiana"},
    {city: "Bedford", state: "Indiana"},
    {city: "Beech Grove", state: "Indiana"},
    {city: "Bloomington", state: "Indiana"},
    {city: "Bluffton", state: "Indiana"},
    {city: "Boonville", state: "Indiana"},
    {city: "Brazil", state: "Indiana"},
    {city: "Brownsburg", state: "Indiana"},
    {city: "Carmel", state: "Indiana"},
    {city: "Cedar Lake", state: "Indiana"},
    {city: "Chesterton", state: "Indiana"},
    {city: "Clarksville", state: "Indiana"},
    {city: "Columbia City", state: "Indiana"},
    {city: "Columbus", state: "Indiana"},
    {city: "Connersville", state: "Indiana"},
    {city: "Crawfordsville", state: "Indiana"},
    {city: "Crown Point", state: "Indiana"},
    {city: "Danville", state: "Indiana"},
    {city: "Decatur", state: "Indiana"},
    {city: "Dyer", state: "Indiana"},
    {city: "East Chicago", state: "Indiana"},
    {city: "Elkhart", state: "Indiana"},
    {city: "Elwood", state: "Indiana"},
    {city: "Evansville", state: "Indiana"},
    {city: "Fishers", state: "Indiana"},
    {city: "Fort Wayne", state: "Indiana"},
    {city: "Frankfort", state: "Indiana"},
    {city: "Franklin", state: "Indiana"},
    {city: "Gary", state: "Indiana"},
    {city: "Goshen", state: "Indiana"},
    {city: "Granger", state: "Indiana"},
    {city: "Greencastle", state: "Indiana"},
    {city: "Greenfield", state: "Indiana"},
    {city: "Greensburg", state: "Indiana"},
    {city: "Greenwood", state: "Indiana"},
    {city: "Griffith", state: "Indiana"},
    {city: "Hammond", state: "Indiana"},
    {city: "Hartford City", state: "Indiana"},
    {city: "Highland", state: "Indiana"},
    {city: "Hobart", state: "Indiana"},
    {city: "Huntington", state: "Indiana"},
    {city: "Indianapolis", state: "Indiana"},
    {city: "Jasper", state: "Indiana"},
    {city: "Jeffersonville", state: "Indiana"},
    {city: "Kendallville", state: "Indiana"},
    {city: "Kokomo", state: "Indiana"},
    {city: "La Porte", state: "Indiana"},
    {city: "Lafayette", state: "Indiana"},
    {city: "Lake Station", state: "Indiana"},
    {city: "Lakes of the Four Seasons", state: "Indiana"},
    {city: "Lawrence", state: "Indiana"},
    {city: "Lebanon", state: "Indiana"},
    {city: "Logansport", state: "Indiana"},
    {city: "Lowell", state: "Indiana"},
    {city: "Madison", state: "Indiana"},
    {city: "Marion", state: "Indiana"},
    {city: "Martinsville", state: "Indiana"},
    {city: "Merrillville", state: "Indiana"},
    {city: "Michigan City", state: "Indiana"},
    {city: "Mishawaka", state: "Indiana"},
    {city: "Mooresville", state: "Indiana"},
    {city: "Mount Vernon", state: "Indiana"},
    {city: "Muncie", state: "Indiana"},
    {city: "Munster", state: "Indiana"},
    {city: "Nappanee", state: "Indiana"},
    {city: "New Albany", state: "Indiana"},
    {city: "New Castle", state: "Indiana"},
    {city: "New Haven", state: "Indiana"},
    {city: "Noblesville", state: "Indiana"},
    {city: "North Manchester", state: "Indiana"},
    {city: "North Vernon", state: "Indiana"},
    {city: "Peru", state: "Indiana"},
    {city: "Plainfield", state: "Indiana"},
    {city: "Plymouth", state: "Indiana"},
    {city: "Portage", state: "Indiana"},
    {city: "Portland", state: "Indiana"},
    {city: "Princeton", state: "Indiana"},
    {city: "Richmond", state: "Indiana"},
    {city: "Rochester", state: "Indiana"},
    {city: "Salem", state: "Indiana"},
    {city: "Schererville", state: "Indiana"},
    {city: "Scottsburg", state: "Indiana"},
    {city: "Sellersburg", state: "Indiana"},
    {city: "Seymour", state: "Indiana"},
    {city: "Shelbyville", state: "Indiana"},
    {city: "South Bend", state: "Indiana"},
    {city: "Speedway", state: "Indiana"},
    {city: "St. John", state: "Indiana"},
    {city: "Tell City", state: "Indiana"},
    {city: "Terre Haute", state: "Indiana"},
    {city: "Valparaiso", state: "Indiana"},
    {city: "Vincennes", state: "Indiana"},
    {city: "Wabash", state: "Indiana"},
    {city: "Warsaw", state: "Indiana"},
    {city: "Washington", state: "Indiana"},
    {city: "West Lafayette", state: "Indiana"},
    {city: "Westfield", state: "Indiana"},
    {city: "Zionsville", state: "Indiana"},
    {city: "Altoona", state: "Iowa"},
    {city: "Ames", state: "Iowa"},
    {city: "Ankeny", state: "Iowa"},
    {city: "Atlantic", state: "Iowa"},
    {city: "Bettendorf", state: "Iowa"},
    {city: "Boone", state: "Iowa"},
    {city: "Burlington", state: "Iowa"},
    {city: "Carroll", state: "Iowa"},
    {city: "Cedar Falls", state: "Iowa"},
    {city: "Cedar Rapids", state: "Iowa"},
    {city: "Charles City", state: "Iowa"},
    {city: "Clear Lake", state: "Iowa"},
    {city: "Clinton", state: "Iowa"},
    {city: "Clive", state: "Iowa"},
    {city: "Coralville", state: "Iowa"},
    {city: "Council Bluffs", state: "Iowa"},
    {city: "Creston", state: "Iowa"},
    {city: "Davenport", state: "Iowa"},
    {city: "Moline", state: "Iowa"},
    {city: "Rock Island", state: "Iowa"},
    {city: "Decorah", state: "Iowa"},
    {city: "Denison", state: "Iowa"},
    {city: "Des Moines", state: "Iowa"},
    {city: "Dubuque", state: "Iowa"},
    {city: "Estherville", state: "Iowa"},
    {city: "Fairfield", state: "Iowa"},
    {city: "Fort Dodge", state: "Iowa"},
    {city: "Fort Madison", state: "Iowa"},
    {city: "Grinnell", state: "Iowa"},
    {city: "Hiawatha", state: "Iowa"},
    {city: "Independence", state: "Iowa"},
    {city: "Indianola", state: "Iowa"},
    {city: "Iowa City", state: "Iowa"},
    {city: "Johnston", state: "Iowa"},
    {city: "Keokuk", state: "Iowa"},
    {city: "Knoxville", state: "Iowa"},
    {city: "Le Mars", state: "Iowa"},
    {city: "Maquoketa", state: "Iowa"},
    {city: "Marion", state: "Iowa"},
    {city: "Marshalltown", state: "Iowa"},
    {city: "Mason City", state: "Iowa"},
    {city: "Mount Pleasant", state: "Iowa"},
    {city: "Muscatine", state: "Iowa"},
    {city: "Nevada", state: "Iowa"},
    {city: "Newton", state: "Iowa"},
    {city: "Norwalk", state: "Iowa"},
    {city: "Oelwein", state: "Iowa"},
    {city: "Oskaloosa", state: "Iowa"},
    {city: "Ottumwa", state: "Iowa"},
    {city: "Pella", state: "Iowa"},
    {city: "Perry", state: "Iowa"},
    {city: "Red Oak", state: "Iowa"},
    {city: "Sioux Center", state: "Iowa"},
    {city: "Sioux City", state: "Iowa"},
    {city: "Spencer", state: "Iowa"},
    {city: "Storm Lake", state: "Iowa"},
    {city: "Urbandale", state: "Iowa"},
    {city: "Washington", state: "Iowa"},
    {city: "Waterloo", state: "Iowa"},
    {city: "Waverly", state: "Iowa"},
    {city: "Webster City", state: "Iowa"},
    {city: "West Des Moines", state: "Iowa"},
    {city: "Abilene", state: "Kansas"},
    {city: "Andover", state: "Kansas"},
    {city: "Arkansas City", state: "Kansas"},
    {city: "Atchison", state: "Kansas"},
    {city: "Augusta", state: "Kansas"},
    {city: "Bonner Springs", state: "Kansas"},
    {city: "Chanute", state: "Kansas"},
    {city: "Coffeyville", state: "Kansas"},
    {city: "Derby", state: "Kansas"},
    {city: "Dodge City", state: "Kansas"},
    {city: "El Dorado", state: "Kansas"},
    {city: "Emporia", state: "Kansas"},
    {city: "Fort Riley North", state: "Kansas"},
    {city: "Fort Scott", state: "Kansas"},
    {city: "Garden City", state: "Kansas"},
    {city: "Gardner", state: "Kansas"},
    {city: "Great Bend", state: "Kansas"},
    {city: "Hays", state: "Kansas"},
    {city: "Haysville", state: "Kansas"},
    {city: "Hutchinson", state: "Kansas"},
    {city: "Independence", state: "Kansas"},
    {city: "Iola", state: "Kansas"},
    {city: "Junction City", state: "Kansas"},
    {city: "Kansas City", state: "Kansas"},
    {city: "Lansing", state: "Kansas"},
    {city: "Lawrence", state: "Kansas"},
    {city: "Leavenworth", state: "Kansas"},
    {city: "Leawood", state: "Kansas"},
    {city: "Lenexa", state: "Kansas"},
    {city: "Liberal", state: "Kansas"},
    {city: "Manhattan", state: "Kansas"},
    {city: "McPherson", state: "Kansas"},
    {city: "Merriam", state: "Kansas"},
    {city: "Mission", state: "Kansas"},
    {city: "Newton", state: "Kansas"},
    {city: "Olathe", state: "Kansas"},
    {city: "Ottawa", state: "Kansas"},
    {city: "Overland Park", state: "Kansas"},
    {city: "Parsons", state: "Kansas"},
    {city: "Pittsburg", state: "Kansas"},
    {city: "Prairie Village", state: "Kansas"},
    {city: "Pratt", state: "Kansas"},
    {city: "Roeland Park", state: "Kansas"},
    {city: "Salina", state: "Kansas"},
    {city: "Shawnee", state: "Kansas"},
    {city: "Topeka", state: "Kansas"},
    {city: "Wellington", state: "Kansas"},
    {city: "Wichita", state: "Kansas"},
    {city: "Winfield", state: "Kansas"},
    {city: "Alexandria", state: "Kentucky"},
    {city: "Ashland", state: "Kentucky"},
    {city: "Bardstown", state: "Kentucky"},
    {city: "Bellevue", state: "Kentucky"},
    {city: "Berea", state: "Kentucky"},
    {city: "Bowling Green", state: "Kentucky"},
    {city: "Buechel", state: "Kentucky"},
    {city: "Burlington", state: "Kentucky"},
    {city: "Campbellsville", state: "Kentucky"},
    {city: "Corbin", state: "Kentucky"},
    {city: "Covington", state: "Kentucky"},
    {city: "Cynthiana", state: "Kentucky"},
    {city: "Danville", state: "Kentucky"},
    {city: "Edgewood", state: "Kentucky"},
    {city: "Elizabethtown", state: "Kentucky"},
    {city: "Elsmere", state: "Kentucky"},
    {city: "Erlanger", state: "Kentucky"},
    {city: "Fairdale", state: "Kentucky"},
    {city: "Fern Creek", state: "Kentucky"},
    {city: "Flatwoods", state: "Kentucky"},
    {city: "Florence", state: "Kentucky"},
    {city: "Fort Campbell North", state: "Kentucky"},
    {city: "Fort Knox", state: "Kentucky"},
    {city: "Fort Mitchell", state: "Kentucky"},
    {city: "Fort Thomas", state: "Kentucky"},
    {city: "Frankfort", state: "Kentucky"},
    {city: "Franklin", state: "Kentucky"},
    {city: "Georgetown", state: "Kentucky"},
    {city: "Glasgow", state: "Kentucky"},
    {city: "Harrodsburg", state: "Kentucky"},
    {city: "Henderson", state: "Kentucky"},
    {city: "Highland Heights", state: "Kentucky"},
    {city: "Highview", state: "Kentucky"},
    {city: "Hillview", state: "Kentucky"},
    {city: "Hopkinsville", state: "Kentucky"},
    {city: "Independence", state: "Kentucky"},
    {city: "Jeffersontown", state: "Kentucky"},
    {city: "Lawrenceburg", state: "Kentucky"},
    {city: "Leitchfield", state: "Kentucky"},
    {city: "Lexington", state: "Kentucky"},
    {city: "Louisville", state: "Kentucky"},
    {city: "Lyndon", state: "Kentucky"},
    {city: "Madisonville", state: "Kentucky"},
    {city: "Mayfield", state: "Kentucky"},
    {city: "Maysville", state: "Kentucky"},
    {city: "Middlesborough", state: "Kentucky"},
    {city: "Mount Washington", state: "Kentucky"},
    {city: "Murray", state: "Kentucky"},
    {city: "Newburg", state: "Kentucky"},
    {city: "Newport", state: "Kentucky"},
    {city: "Nicholasville", state: "Kentucky"},
    {city: "Oak Grove", state: "Kentucky"},
    {city: "Oakbrook", state: "Kentucky"},
    {city: "Okolona", state: "Kentucky"},
    {city: "Owensboro", state: "Kentucky"},
    {city: "Paducah", state: "Kentucky"},
    {city: "Paris", state: "Kentucky"},
    {city: "Pikeville", state: "Kentucky"},
    {city: "Pleasure Ridge Park", state: "Kentucky"},
    {city: "Princeton", state: "Kentucky"},
    {city: "Radcliff", state: "Kentucky"},
    {city: "Richmond", state: "Kentucky"},
    {city: "Russellville", state: "Kentucky"},
    {city: "Saddle Club", state: "Kentucky"},
    {city: "Shelbyville", state: "Kentucky"},
    {city: "Shepherdsville", state: "Kentucky"},
    {city: "Shively", state: "Kentucky"},
    {city: "Somerset", state: "Kentucky"},
    {city: "St. Dennis", state: "Kentucky"},
    {city: "St. Matthews", state: "Kentucky"},
    {city: "Taylor Mill", state: "Kentucky"},
    {city: "Valley Station", state: "Kentucky"},
    {city: "Versailles", state: "Kentucky"},
    {city: "Villa Hills", state: "Kentucky"},
    {city: "Winchester", state: "Kentucky"},
    {city: "Abbeville", state: "Louisiana"},
    {city: "Alexandria", state: "Louisiana"},
    {city: "Arabi", state: "Louisiana"},
    {city: "Baker", state: "Louisiana"},
    {city: "Bastrop", state: "Louisiana"},
    {city: "Baton Rouge", state: "Louisiana"},
    {city: "Bayou Cane", state: "Louisiana"},
    {city: "Belle Chasse", state: "Louisiana"},
    {city: "Bogalusa", state: "Louisiana"},
    {city: "Bossier City", state: "Louisiana"},
    {city: "Breaux Bridge", state: "Louisiana"},
    {city: "Bridge City", state: "Louisiana"},
    {city: "Brownsville-Bawcomville", state: "Louisiana"},
    {city: "Carencro", state: "Louisiana"},
    {city: "Chalmette", state: "Louisiana"},
    {city: "Claiborne", state: "Louisiana"},
    {city: "Covington", state: "Louisiana"},
    {city: "Crowley", state: "Louisiana"},
    {city: "De Ridder", state: "Louisiana"},
    {city: "Denham Springs", state: "Louisiana"},
    {city: "Destrehan", state: "Louisiana"},
    {city: "Donaldsonville", state: "Louisiana"},
    {city: "Eden Isle", state: "Louisiana"},
    {city: "Estelle", state: "Louisiana"},
    {city: "Eunice", state: "Louisiana"},
    {city: "Fort Polk South", state: "Louisiana"},
    {city: "Franklin", state: "Louisiana"},
    {city: "Galliano", state: "Louisiana"},
    {city: "Gardere", state: "Louisiana"},
    {city: "Gonzales", state: "Louisiana"},
    {city: "Gretna", state: "Louisiana"},
    {city: "Hammond", state: "Louisiana"},
    {city: "Harahan", state: "Louisiana"},
    {city: "Harvey", state: "Louisiana"},
    {city: "Houma", state: "Louisiana"},
    {city: "Jefferson", state: "Louisiana"},
    {city: "Jennings", state: "Louisiana"},
    {city: "Kenner", state: "Louisiana"},
    {city: "Lacombe", state: "Louisiana"},
    {city: "Lafayette", state: "Louisiana"},
    {city: "Lake Charles", state: "Louisiana"},
    {city: "Laplace", state: "Louisiana"},
    {city: "Larose", state: "Louisiana"},
    {city: "Leesville", state: "Louisiana"},
    {city: "Luling", state: "Louisiana"},
    {city: "Mandeville", state: "Louisiana"},
    {city: "Marrero", state: "Louisiana"},
    {city: "Meraux", state: "Louisiana"},
    {city: "Merrydale", state: "Louisiana"},
    {city: "Metairie", state: "Louisiana"},
    {city: "Minden", state: "Louisiana"},
    {city: "Monroe", state: "Louisiana"},
    {city: "Morgan City", state: "Louisiana"},
    {city: "Moss Bluff", state: "Louisiana"},
    {city: "Natchitoches", state: "Louisiana"},
    {city: "New Iberia", state: "Louisiana"},
    {city: "New Orleans", state: "Louisiana"},
    {city: "Oak Hills Place", state: "Louisiana"},
    {city: "Oakdale", state: "Louisiana"},
    {city: "Opelousas", state: "Louisiana"},
    {city: "Pineville", state: "Louisiana"},
    {city: "Plaquemine", state: "Louisiana"},
    {city: "Prien", state: "Louisiana"},
    {city: "Raceland", state: "Louisiana"},
    {city: "Rayne", state: "Louisiana"},
    {city: "Reserve", state: "Louisiana"},
    {city: "River Ridge", state: "Louisiana"},
    {city: "Ruston", state: "Louisiana"},
    {city: "Scott", state: "Louisiana"},
    {city: "Shenandoah", state: "Louisiana"},
    {city: "Shreveport", state: "Louisiana"},
    {city: "Slidell", state: "Louisiana"},
    {city: "St. Martinville", state: "Louisiana"},
    {city: "St. Rose", state: "Louisiana"},
    {city: "Sulphur", state: "Louisiana"},
    {city: "Tallulah", state: "Louisiana"},
    {city: "Terrytown", state: "Louisiana"},
    {city: "Thibodaux", state: "Louisiana"},
    {city: "Timberlane", state: "Louisiana"},
    {city: "Village St. George", state: "Louisiana"},
    {city: "Ville Platte", state: "Louisiana"},
    {city: "Violet", state: "Louisiana"},
    {city: "Waggaman", state: "Louisiana"},
    {city: "West Monroe", state: "Louisiana"},
    {city: "Westwego", state: "Louisiana"},
    {city: "Woodmere", state: "Louisiana"},
    {city: "Zachary", state: "Louisiana"},
    {city: "Auburn", state: "Maine"},
    {city: "Augusta", state: "Maine"},
    {city: "Bangor", state: "Maine"},
    {city: "Bath", state: "Maine"},
    {city: "Belfast", state: "Maine"},
    {city: "Berwick", state: "Maine"},
    {city: "Biddeford", state: "Maine"},
    {city: "Brewer", state: "Maine"},
    {city: "Brunswick", state: "Maine"},
    {city: "Buxton", state: "Maine"},
    {city: "Cape Elizabeth", state: "Maine"},
    {city: "Caribou", state: "Maine"},
    {city: "Cumberland", state: "Maine"},
    {city: "Ellsworth", state: "Maine"},
    {city: "Fairfield", state: "Maine"},
    {city: "Falmouth", state: "Maine"},
    {city: "Farmington", state: "Maine"},
    {city: "Freeport", state: "Maine"},
    {city: "Gardiner", state: "Maine"},
    {city: "Gorham", state: "Maine"},
    {city: "Gray", state: "Maine"},
    {city: "Hampden", state: "Maine"},
    {city: "Houlton", state: "Maine"},
    {city: "Kennebunk", state: "Maine"},
    {city: "Kittery", state: "Maine"},
    {city: "Lewiston", state: "Maine"},
    {city: "Lisbon", state: "Maine"},
    {city: "Old Orchard Beach", state: "Maine"},
    {city: "Old Town", state: "Maine"},
    {city: "Orono", state: "Maine"},
    {city: "Portland", state: "Maine"},
    {city: "Presque Isle", state: "Maine"},
    {city: "Rockland", state: "Maine"},
    {city: "Rumford", state: "Maine"},
    {city: "Saco", state: "Maine"},
    {city: "Sanford", state: "Maine"},
    {city: "Scarborough", state: "Maine"},
    {city: "Skowhegan", state: "Maine"},
    {city: "South Berwick", state: "Maine"},
    {city: "South Portland", state: "Maine"},
    {city: "Standish", state: "Maine"},
    {city: "Topsham", state: "Maine"},
    {city: "Waterboro", state: "Maine"},
    {city: "Waterville", state: "Maine"},
    {city: "Wells", state: "Maine"},
    {city: "Westbrook", state: "Maine"},
    {city: "Windham", state: "Maine"},
    {city: "Winslow", state: "Maine"},
    {city: "Winthrop", state: "Maine"},
    {city: "Yarmouth", state: "Maine"},
    {city: "York", state: "Maine"},
    {city: "Aberdeen", state: "Maryland"},
    {city: "Accokeek", state: "Maryland"},
    {city: "Adelphi", state: "Maryland"},
    {city: "Andrews AFB", state: "Maryland"},
    {city: "Annapolis", state: "Maryland"},
    {city: "Arbutus", state: "Maryland"},
    {city: "Arnold", state: "Maryland"},
    {city: "Aspen Hill", state: "Maryland"},
    {city: "Ballenger Creek", state: "Maryland"},
    {city: "Baltimore", state: "Maryland"},
    {city: "Bel Air", state: "Maryland"},
    {city: "Bel Air North", state: "Maryland"},
    {city: "Bel Air South", state: "Maryland"},
    {city: "Beltsville", state: "Maryland"},
    {city: "Bennsville", state: "Maryland"},
    {city: "Bethesda", state: "Maryland"},
    {city: "Bladensburg", state: "Maryland"},
    {city: "Bowie", state: "Maryland"},
    {city: "Bowleys Quarters", state: "Maryland"},
    {city: "Brooklyn Park", state: "Maryland"},
    {city: "Burtonsville", state: "Maryland"},
    {city: "California", state: "Maryland"},
    {city: "Calverton", state: "Maryland"},
    {city: "Cambridge", state: "Maryland"},
    {city: "Camp Springs", state: "Maryland"},
    {city: "Cape St. Claire", state: "Maryland"},
    {city: "Carney", state: "Maryland"},
    {city: "Catonsville", state: "Maryland"},
    {city: "Chesapeake Ranch Estates-Drum Point", state: "Maryland"},
    {city: "Cheverly", state: "Maryland"},
    {city: "Chevy Chase", state: "Maryland"},
    {city: "Chillum", state: "Maryland"},
    {city: "Clinton", state: "Maryland"},
    {city: "Cloverly", state: "Maryland"},
    {city: "Cockeysville", state: "Maryland"},
    {city: "Colesville", state: "Maryland"},
    {city: "College Park", state: "Maryland"},
    {city: "Columbia", state: "Maryland"},
    {city: "Coral Hills", state: "Maryland"},
    {city: "Crofton", state: "Maryland"},
    {city: "Cumberland", state: "Maryland"},
    {city: "Damascus", state: "Maryland"},
    {city: "Darnestown", state: "Maryland"},
    {city: "Dundalk", state: "Maryland"},
    {city: "East Riverdale", state: "Maryland"},
    {city: "Easton", state: "Maryland"},
    {city: "Edgemere", state: "Maryland"},
    {city: "Edgewood", state: "Maryland"},
    {city: "Eldersburg", state: "Maryland"},
    {city: "Elkridge", state: "Maryland"},
    {city: "Elkton", state: "Maryland"},
    {city: "Ellicott City", state: "Maryland"},
    {city: "Essex", state: "Maryland"},
    {city: "Fairland", state: "Maryland"},
    {city: "Fallston", state: "Maryland"},
    {city: "Ferndale", state: "Maryland"},
    {city: "Forest Glen", state: "Maryland"},
    {city: "Forestville", state: "Maryland"},
    {city: "Fort Meade", state: "Maryland"},
    {city: "Fort Washington", state: "Maryland"},
    {city: "Frederick", state: "Maryland"},
    {city: "Friendly", state: "Maryland"},
    {city: "Frostburg", state: "Maryland"},
    {city: "Gaithersburg", state: "Maryland"},
    {city: "Garrison", state: "Maryland"},
    {city: "Germantown", state: "Maryland"},
    {city: "Glen Burnie", state: "Maryland"},
    {city: "Glenarden", state: "Maryland"},
    {city: "Glenn Dale", state: "Maryland"},
    {city: "Greater Landover", state: "Maryland"},
    {city: "Greater Upper Marlboro", state: "Maryland"},
    {city: "Green Haven", state: "Maryland"},
    {city: "Green Valley", state: "Maryland"},
    {city: "Greenbelt", state: "Maryland"},
    {city: "Hagerstown", state: "Maryland"},
    {city: "Martinsburg", state: "Maryland"},
    {city: "Halfway", state: "Maryland"},
    {city: "Havre de Grace", state: "Maryland"},
    {city: "Hillcrest Heights", state: "Maryland"},
    {city: "Hyattsville", state: "Maryland"},
    {city: "Jessup", state: "Maryland"},
    {city: "Joppatowne", state: "Maryland"},
    {city: "Kemp Mill", state: "Maryland"},
    {city: "Kettering", state: "Maryland"},
    {city: "La Plata", state: "Maryland"},
    {city: "Lake Arbor", state: "Maryland"},
    {city: "Lake Shore", state: "Maryland"},
    {city: "Langley Park", state: "Maryland"},
    {city: "Lanham-Seabrook", state: "Maryland"},
    {city: "Lansdowne-Baltimore Highlands", state: "Maryland"},
    {city: "Largo", state: "Maryland"},
    {city: "Laurel", state: "Maryland"},
    {city: "Lexington Park", state: "Maryland"},
    {city: "Linganore-Bartonsville", state: "Maryland"},
    {city: "Linthicum", state: "Maryland"},
    {city: "Lochearn", state: "Maryland"},
    {city: "Londontowne", state: "Maryland"},
    {city: "Lutherville-Timonium", state: "Maryland"},
    {city: "Marlow Heights", state: "Maryland"},
    {city: "Marlton", state: "Maryland"},
    {city: "Maryland City", state: "Maryland"},
    {city: "Mays Chapel", state: "Maryland"},
    {city: "Middle River", state: "Maryland"},
    {city: "Milford Mill", state: "Maryland"},
    {city: "Mitchellville", state: "Maryland"},
    {city: "Montgomery Village", state: "Maryland"},
    {city: "Mount Airy", state: "Maryland"},
    {city: "Mount Rainier", state: "Maryland"},
    {city: "New Carrollton", state: "Maryland"},
    {city: "North Bethesda", state: "Maryland"},
    {city: "North Kensington", state: "Maryland"},
    {city: "North Laurel", state: "Maryland"},
    {city: "North Potomac", state: "Maryland"},
    {city: "Ocean City", state: "Maryland"},
    {city: "Ocean Pines", state: "Maryland"},
    {city: "Odenton", state: "Maryland"},
    {city: "Olney", state: "Maryland"},
    {city: "Overlea", state: "Maryland"},
    {city: "Owings Mills", state: "Maryland"},
    {city: "Oxon Hill-Glassmanor", state: "Maryland"},
    {city: "Parkville", state: "Maryland"},
    {city: "Parole", state: "Maryland"},
    {city: "Pasadena", state: "Maryland"},
    {city: "Perry Hall", state: "Maryland"},
    {city: "Pikesville", state: "Maryland"},
    {city: "Potomac", state: "Maryland"},
    {city: "Randallstown", state: "Maryland"},
    {city: "Redland", state: "Maryland"},
    {city: "Reisterstown", state: "Maryland"},
    {city: "Riverdale Park", state: "Maryland"},
    {city: "Riverside", state: "Maryland"},
    {city: "Riviera Beach", state: "Maryland"},
    {city: "Rockville", state: "Maryland"},
    {city: "Rosaryville", state: "Maryland"},
    {city: "Rosedale", state: "Maryland"},
    {city: "Rossmoor", state: "Maryland"},
    {city: "Rossville", state: "Maryland"},
    {city: "Salisbury", state: "Maryland"},
    {city: "Savage-Guilford", state: "Maryland"},
    {city: "Severn", state: "Maryland"},
    {city: "Severna Park", state: "Maryland"},
    {city: "Silver Spring", state: "Maryland"},
    {city: "South Gate", state: "Maryland"},
    {city: "South Kensington", state: "Maryland"},
    {city: "South Laurel", state: "Maryland"},
    {city: "St. Charles", state: "Maryland"},
    {city: "Suitland-Silver Hill", state: "Maryland"},
    {city: "Takoma Park", state: "Maryland"},
    {city: "Temple Hills", state: "Maryland"},
    {city: "Towson", state: "Maryland"},
    {city: "Travilah", state: "Maryland"},
    {city: "Waldorf", state: "Maryland"},
    {city: "Walker Mill", state: "Maryland"},
    {city: "Westminster", state: "Maryland"},
    {city: "Wheaton-Glenmont", state: "Maryland"},
    {city: "White Marsh", state: "Maryland"},
    {city: "White Oak", state: "Maryland"},
    {city: "Woodlawn", state: "Maryland"},
    {city: "Woodmore", state: "Maryland"},
    {city: "Abington", state: "Massachusetts"},
    {city: "Acton", state: "Massachusetts"},
    {city: "Acushnet", state: "Massachusetts"},
    {city: "Adams", state: "Massachusetts"},
    {city: "Agawam", state: "Massachusetts"},
    {city: "Amesbury", state: "Massachusetts"},
    {city: "Amherst", state: "Massachusetts"},
    {city: "Amherst Center", state: "Massachusetts"},
    {city: "Andover", state: "Massachusetts"},
    {city: "Arlington", state: "Massachusetts"},
    {city: "Ashland", state: "Massachusetts"},
    {city: "Athol", state: "Massachusetts"},
    {city: "Attleboro", state: "Massachusetts"},
    {city: "Auburn", state: "Massachusetts"},
    {city: "Ayer", state: "Massachusetts"},
    {city: "Barnstable", state: "Massachusetts"},
    {city: "Bedford", state: "Massachusetts"},
    {city: "Belchertown", state: "Massachusetts"},
    {city: "Bellingham", state: "Massachusetts"},
    {city: "Belmont", state: "Massachusetts"},
    {city: "Beverly", state: "Massachusetts"},
    {city: "Billerica", state: "Massachusetts"},
    {city: "Blackstone", state: "Massachusetts"},
    {city: "Boston", state: "Massachusetts"},
    {city: "Bourne", state: "Massachusetts"},
    {city: "Boxford", state: "Massachusetts"},
    {city: "Braintree", state: "Massachusetts"},
    {city: "Brewster", state: "Massachusetts"},
    {city: "Bridgewater", state: "Massachusetts"},
    {city: "Brockton", state: "Massachusetts"},
    {city: "Brookline", state: "Massachusetts"},
    {city: "Burlington", state: "Massachusetts"},
    {city: "Cambridge", state: "Massachusetts"},
    {city: "Canton", state: "Massachusetts"},
    {city: "Carver", state: "Massachusetts"},
    {city: "Charlton", state: "Massachusetts"},
    {city: "Chatham", state: "Massachusetts"},
    {city: "Chelmsford", state: "Massachusetts"},
    {city: "Chelsea", state: "Massachusetts"},
    {city: "Chicopee", state: "Massachusetts"},
    {city: "Clinton", state: "Massachusetts"},
    {city: "Cochituate", state: "Massachusetts"},
    {city: "Cohasset", state: "Massachusetts"},
    {city: "Concord", state: "Massachusetts"},
    {city: "Dalton", state: "Massachusetts"},
    {city: "Danvers", state: "Massachusetts"},
    {city: "Dartmouth", state: "Massachusetts"},
    {city: "Dedham", state: "Massachusetts"},
    {city: "Dennis", state: "Massachusetts"},
    {city: "Dighton", state: "Massachusetts"},
    {city: "Douglas", state: "Massachusetts"},
    {city: "Dracut", state: "Massachusetts"},
    {city: "Dudley", state: "Massachusetts"},
    {city: "Duxbury", state: "Massachusetts"},
    {city: "East Bridgewater", state: "Massachusetts"},
    {city: "East Falmouth", state: "Massachusetts"},
    {city: "East Longmeadow", state: "Massachusetts"},
    {city: "Easthampton", state: "Massachusetts"},
    {city: "Easton", state: "Massachusetts"},
    {city: "Everett", state: "Massachusetts"},
    {city: "Fairhaven", state: "Massachusetts"},
    {city: "Fall River", state: "Massachusetts"},
    {city: "Falmouth", state: "Massachusetts"},
    {city: "Fitchburg", state: "Massachusetts"},
    {city: "Foxborough", state: "Massachusetts"},
    {city: "Framingham", state: "Massachusetts"},
    {city: "Franklin", state: "Massachusetts"},
    {city: "Freetown", state: "Massachusetts"},
    {city: "Gardner", state: "Massachusetts"},
    {city: "Georgetown", state: "Massachusetts"},
    {city: "Gloucester", state: "Massachusetts"},
    {city: "Grafton", state: "Massachusetts"},
    {city: "Granby", state: "Massachusetts"},
    {city: "Great Barrington", state: "Massachusetts"},
    {city: "Greenfield", state: "Massachusetts"},
    {city: "Groton", state: "Massachusetts"},
    {city: "Groveland", state: "Massachusetts"},
    {city: "Halifax", state: "Massachusetts"},
    {city: "Hamilton", state: "Massachusetts"},
    {city: "Hanover", state: "Massachusetts"},
    {city: "Hanson", state: "Massachusetts"},
    {city: "Harwich", state: "Massachusetts"},
    {city: "Haverhill", state: "Massachusetts"},
    {city: "Hingham", state: "Massachusetts"},
    {city: "Holbrook", state: "Massachusetts"},
    {city: "Holden", state: "Massachusetts"},
    {city: "Holliston", state: "Massachusetts"},
    {city: "Holyoke", state: "Massachusetts"},
    {city: "Hopkinton", state: "Massachusetts"},
    {city: "Hudson", state: "Massachusetts"},
    {city: "Hull", state: "Massachusetts"},
    {city: "Ipswich", state: "Massachusetts"},
    {city: "Kingston", state: "Massachusetts"},
    {city: "Lakeville", state: "Massachusetts"},
    {city: "Lancaster", state: "Massachusetts"},
    {city: "Lawrence", state: "Massachusetts"},
    {city: "Leicester", state: "Massachusetts"},
    {city: "Leominster", state: "Massachusetts"},
    {city: "Lexington", state: "Massachusetts"},
    {city: "Lincoln", state: "Massachusetts"},
    {city: "Littleton", state: "Massachusetts"},
    {city: "Longmeadow", state: "Massachusetts"},
    {city: "Lowell", state: "Massachusetts"},
    {city: "Ludlow", state: "Massachusetts"},
    {city: "Lunenburg", state: "Massachusetts"},
    {city: "Lynn", state: "Massachusetts"},
    {city: "Lynnfield", state: "Massachusetts"},
    {city: "Malden", state: "Massachusetts"},
    {city: "Mansfield", state: "Massachusetts"},
    {city: "Mansfield Center", state: "Massachusetts"},
    {city: "Marblehead", state: "Massachusetts"},
    {city: "Marlborough", state: "Massachusetts"},
    {city: "Marshfield", state: "Massachusetts"},
    {city: "Mashpee", state: "Massachusetts"},
    {city: "Mattapoisett", state: "Massachusetts"},
    {city: "Maynard", state: "Massachusetts"},
    {city: "Medfield", state: "Massachusetts"},
    {city: "Medford", state: "Massachusetts"},
    {city: "Medway", state: "Massachusetts"},
    {city: "Melrose", state: "Massachusetts"},
    {city: "Merrimac", state: "Massachusetts"},
    {city: "Methuen", state: "Massachusetts"},
    {city: "Middleborough", state: "Massachusetts"},
    {city: "Middleborough Center", state: "Massachusetts"},
    {city: "Middleton", state: "Massachusetts"},
    {city: "Milford", state: "Massachusetts"},
    {city: "Millbury", state: "Massachusetts"},
    {city: "Millis", state: "Massachusetts"},
    {city: "Milton", state: "Massachusetts"},
    {city: "Monson", state: "Massachusetts"},
    {city: "Montague", state: "Massachusetts"},
    {city: "Nantucket", state: "Massachusetts"},
    {city: "Natick", state: "Massachusetts"},
    {city: "Needham", state: "Massachusetts"},
    {city: "New Bedford", state: "Massachusetts"},
    {city: "Newbury", state: "Massachusetts"},
    {city: "Newburyport", state: "Massachusetts"},
    {city: "Newton", state: "Massachusetts"},
    {city: "Norfolk", state: "Massachusetts"},
    {city: "North Adams", state: "Massachusetts"},
    {city: "North Amherst", state: "Massachusetts"},
    {city: "North Andover", state: "Massachusetts"},
    {city: "North Attleborough", state: "Massachusetts"},
    {city: "North Attleborough Center", state: "Massachusetts"},
    {city: "North Reading", state: "Massachusetts"},
    {city: "Northampton", state: "Massachusetts"},
    {city: "Northborough", state: "Massachusetts"},
    {city: "Northbridge", state: "Massachusetts"},
    {city: "Norton", state: "Massachusetts"},
    {city: "Norwell", state: "Massachusetts"},
    {city: "Norwood", state: "Massachusetts"},
    {city: "Orange", state: "Massachusetts"},
    {city: "Orleans", state: "Massachusetts"},
    {city: "Oxford", state: "Massachusetts"},
    {city: "Palmer", state: "Massachusetts"},
    {city: "Peabody", state: "Massachusetts"},
    {city: "Pembroke", state: "Massachusetts"},
    {city: "Pepperell", state: "Massachusetts"},
    {city: "Pinehurst", state: "Massachusetts"},
    {city: "Pittsfield", state: "Massachusetts"},
    {city: "Plainville", state: "Massachusetts"},
    {city: "Plymouth", state: "Massachusetts"},
    {city: "Quincy", state: "Massachusetts"},
    {city: "Randolph", state: "Massachusetts"},
    {city: "Raynham", state: "Massachusetts"},
    {city: "Reading", state: "Massachusetts"},
    {city: "Rehoboth", state: "Massachusetts"},
    {city: "Revere", state: "Massachusetts"},
    {city: "Rockland", state: "Massachusetts"},
    {city: "Rockport", state: "Massachusetts"},
    {city: "Rutland", state: "Massachusetts"},
    {city: "Salem", state: "Massachusetts"},
    {city: "Salisbury", state: "Massachusetts"},
    {city: "Sandwich", state: "Massachusetts"},
    {city: "Saugus", state: "Massachusetts"},
    {city: "Scituate", state: "Massachusetts"},
    {city: "Seekonk", state: "Massachusetts"},
    {city: "Sharon", state: "Massachusetts"},
    {city: "Shirley", state: "Massachusetts"},
    {city: "Shrewsbury", state: "Massachusetts"},
    {city: "Somerset", state: "Massachusetts"},
    {city: "Somerville", state: "Massachusetts"},
    {city: "South Hadley", state: "Massachusetts"},
    {city: "South Yarmouth", state: "Massachusetts"},
    {city: "Southborough", state: "Massachusetts"},
    {city: "Southbridge", state: "Massachusetts"},
    {city: "Southwick", state: "Massachusetts"},
    {city: "Spencer", state: "Massachusetts"},
    {city: "Springfield", state: "Massachusetts"},
    {city: "Sterling", state: "Massachusetts"},
    {city: "Stoneham", state: "Massachusetts"},
    {city: "Stoughton", state: "Massachusetts"},
    {city: "Sturbridge", state: "Massachusetts"},
    {city: "Sudbury", state: "Massachusetts"},
    {city: "Sutton", state: "Massachusetts"},
    {city: "Swampscott", state: "Massachusetts"},
    {city: "Swansea", state: "Massachusetts"},
    {city: "Taunton", state: "Massachusetts"},
    {city: "Templeton", state: "Massachusetts"},
    {city: "Tewksbury", state: "Massachusetts"},
    {city: "Topsfield", state: "Massachusetts"},
    {city: "Townsend", state: "Massachusetts"},
    {city: "Tyngsborough", state: "Massachusetts"},
    {city: "Uxbridge", state: "Massachusetts"},
    {city: "Wakefield", state: "Massachusetts"},
    {city: "Walpole", state: "Massachusetts"},
    {city: "Waltham", state: "Massachusetts"},
    {city: "Ware", state: "Massachusetts"},
    {city: "Wareham", state: "Massachusetts"},
    {city: "Watertown", state: "Massachusetts"},
    {city: "Wayland", state: "Massachusetts"},
    {city: "Webster", state: "Massachusetts"},
    {city: "Wellesley", state: "Massachusetts"},
    {city: "West Boylston", state: "Massachusetts"},
    {city: "West Bridgewater", state: "Massachusetts"},
    {city: "West Springfield", state: "Massachusetts"},
    {city: "West Yarmouth", state: "Massachusetts"},
    {city: "Westborough", state: "Massachusetts"},
    {city: "Westfield", state: "Massachusetts"},
    {city: "Westford", state: "Massachusetts"},
    {city: "Westminster", state: "Massachusetts"},
    {city: "Weston", state: "Massachusetts"},
    {city: "Westport", state: "Massachusetts"},
    {city: "Westwood", state: "Massachusetts"},
    {city: "Weymouth", state: "Massachusetts"},
    {city: "Whitinsville", state: "Massachusetts"},
    {city: "Whitman", state: "Massachusetts"},
    {city: "Wilbraham", state: "Massachusetts"},
    {city: "Williamstown", state: "Massachusetts"},
    {city: "Wilmington", state: "Massachusetts"},
    {city: "Winchendon", state: "Massachusetts"},
    {city: "Winchester", state: "Massachusetts"},
    {city: "Winthrop", state: "Massachusetts"},
    {city: "Woburn", state: "Massachusetts"},
    {city: "Worcester", state: "Massachusetts"},
    {city: "Wrentham", state: "Massachusetts"},
    {city: "Yarmouth", state: "Massachusetts"},
    {city: "Adrian", state: "Michigan"},
    {city: "Albion", state: "Michigan"},
    {city: "Allen Park", state: "Michigan"},
    {city: "Allendale", state: "Michigan"},
    {city: "Alma", state: "Michigan"},
    {city: "Alpena", state: "Michigan"},
    {city: "Ann Arbor", state: "Michigan"},
    {city: "Auburn Hills", state: "Michigan"},
    {city: "Battle Creek", state: "Michigan"},
    {city: "Bay City", state: "Michigan"},
    {city: "Beecher", state: "Michigan"},
    {city: "Benton Harbor", state: "Michigan"},
    {city: "Berkley", state: "Michigan"},
    {city: "Beverly Hills", state: "Michigan"},
    {city: "Big Rapids", state: "Michigan"},
    {city: "Birmingham", state: "Michigan"},
    {city: "Bloomfield Township", state: "Michigan"},
    {city: "Bridgeport", state: "Michigan"},
    {city: "Brighton", state: "Michigan"},
    {city: "Buena Vista", state: "Michigan"},
    {city: "Burton", state: "Michigan"},
    {city: "Cadillac", state: "Michigan"},
    {city: "Canton", state: "Michigan"},
    {city: "Carrollton", state: "Michigan"},
    {city: "Center Line", state: "Michigan"},
    {city: "Charlotte", state: "Michigan"},
    {city: "Cheboygan", state: "Michigan"},
    {city: "Clawson", state: "Michigan"},
    {city: "Clinton", state: "Michigan"},
    {city: "Coldwater", state: "Michigan"},
    {city: "Comstock Park", state: "Michigan"},
    {city: "Cutlerville", state: "Michigan"},
    {city: "Dearborn", state: "Michigan"},
    {city: "Dearborn Heights", state: "Michigan"},
    {city: "Detroit", state: "Michigan"},
    {city: "Dowagiac", state: "Michigan"},
    {city: "East Grand Rapids", state: "Michigan"},
    {city: "East Lansing", state: "Michigan"},
    {city: "Eastpointe", state: "Michigan"},
    {city: "Eastwood", state: "Michigan"},
    {city: "Ecorse", state: "Michigan"},
    {city: "Escanaba", state: "Michigan"},
    {city: "Fair Plain", state: "Michigan"},
    {city: "Farmington", state: "Michigan"},
    {city: "Farmington Hills", state: "Michigan"},
    {city: "Fenton", state: "Michigan"},
    {city: "Ferndale", state: "Michigan"},
    {city: "Flat Rock", state: "Michigan"},
    {city: "Flint", state: "Michigan"},
    {city: "Flushing", state: "Michigan"},
    {city: "Forest Hills", state: "Michigan"},
    {city: "Fraser", state: "Michigan"},
    {city: "Garden City", state: "Michigan"},
    {city: "Grand Blanc", state: "Michigan"},
    {city: "Grand Haven", state: "Michigan"},
    {city: "Grand Ledge", state: "Michigan"},
    {city: "Grand Rapids", state: "Michigan"},
    {city: "Grandville", state: "Michigan"},
    {city: "Greenville", state: "Michigan"},
    {city: "Grosse Ile", state: "Michigan"},
    {city: "Grosse Pointe Farms", state: "Michigan"},
    {city: "Grosse Pointe Park", state: "Michigan"},
    {city: "Grosse Pointe Woods", state: "Michigan"},
    {city: "Hamtramck", state: "Michigan"},
    {city: "Harper Woods", state: "Michigan"},
    {city: "Harrison", state: "Michigan"},
    {city: "Haslett", state: "Michigan"},
    {city: "Hastings", state: "Michigan"},
    {city: "Hazel Park", state: "Michigan"},
    {city: "Highland Park", state: "Michigan"},
    {city: "Hillsdale", state: "Michigan"},
    {city: "Holland", state: "Michigan"},
    {city: "Holly", state: "Michigan"},
    {city: "Holt", state: "Michigan"},
    {city: "Houghton", state: "Michigan"},
    {city: "Howell", state: "Michigan"},
    {city: "Hudsonville", state: "Michigan"},
    {city: "Huntington Woods", state: "Michigan"},
    {city: "Inkster", state: "Michigan"},
    {city: "Ionia", state: "Michigan"},
    {city: "Iron Mountain", state: "Michigan"},
    {city: "Ironwood", state: "Michigan"},
    {city: "Ishpeming", state: "Michigan"},
    {city: "Jackson", state: "Michigan"},
    {city: "Jenison", state: "Michigan"},
    {city: "Kalamazoo", state: "Michigan"},
    {city: "Kentwood", state: "Michigan"},
    {city: "Lambertville", state: "Michigan"},
    {city: "Lansing", state: "Michigan"},
    {city: "Lapeer", state: "Michigan"},
    {city: "Lincoln Park", state: "Michigan"},
    {city: "Livonia", state: "Michigan"},
    {city: "Ludington", state: "Michigan"},
    {city: "Madison Heights", state: "Michigan"},
    {city: "Manistee", state: "Michigan"},
    {city: "Marquette", state: "Michigan"},
    {city: "Marshall", state: "Michigan"},
    {city: "Marysville", state: "Michigan"},
    {city: "Mason", state: "Michigan"},
    {city: "Melvindale", state: "Michigan"},
    {city: "Menominee", state: "Michigan"},
    {city: "Midland", state: "Michigan"},
    {city: "Milford", state: "Michigan"},
    {city: "Monroe", state: "Michigan"},
    {city: "Mount Clemens", state: "Michigan"},
    {city: "Mount Pleasant", state: "Michigan"},
    {city: "Muskegon", state: "Michigan"},
    {city: "Muskegon Heights", state: "Michigan"},
    {city: "New Baltimore", state: "Michigan"},
    {city: "Niles", state: "Michigan"},
    {city: "Northview", state: "Michigan"},
    {city: "Northville", state: "Michigan"},
    {city: "Norton Shores", state: "Michigan"},
    {city: "Novi", state: "Michigan"},
    {city: "Oak Park", state: "Michigan"},
    {city: "Okemos", state: "Michigan"},
    {city: "Owosso", state: "Michigan"},
    {city: "Petoskey", state: "Michigan"},
    {city: "Plymouth", state: "Michigan"},
    {city: "Plymouth Township", state: "Michigan"},
    {city: "Pontiac", state: "Michigan"},
    {city: "Port Huron", state: "Michigan"},
    {city: "Portage", state: "Michigan"},
    {city: "Redford", state: "Michigan"},
    {city: "River Rouge", state: "Michigan"},
    {city: "Riverview", state: "Michigan"},
    {city: "Rochester", state: "Michigan"},
    {city: "Rochester Hills", state: "Michigan"},
    {city: "Romulus", state: "Michigan"},
    {city: "Roseville", state: "Michigan"},
    {city: "Royal Oak", state: "Michigan"},
    {city: "Saginaw", state: "Michigan"},
    {city: "Saginaw Township North", state: "Michigan"},
    {city: "Saginaw Township South", state: "Michigan"},
    {city: "Saline", state: "Michigan"},
    {city: "Sault Ste. Marie", state: "Michigan"},
    {city: "Shelby", state: "Michigan"},
    {city: "Shields", state: "Michigan"},
    {city: "South Lyon", state: "Michigan"},
    {city: "South Monroe", state: "Michigan"},
    {city: "Southfield", state: "Michigan"},
    {city: "Southgate", state: "Michigan"},
    {city: "St. Clair Shores", state: "Michigan"},
    {city: "St. Johns", state: "Michigan"},
    {city: "St. Joseph", state: "Michigan"},
    {city: "Sterling Heights", state: "Michigan"},
    {city: "Sturgis", state: "Michigan"},
    {city: "Taylor", state: "Michigan"},
    {city: "Tecumseh", state: "Michigan"},
    {city: "Temperance", state: "Michigan"},
    {city: "Three Rivers", state: "Michigan"},
    {city: "Traverse City", state: "Michigan"},
    {city: "Trenton", state: "Michigan"},
    {city: "Troy", state: "Michigan"},
    {city: "Walker", state: "Michigan"},
    {city: "Walled Lake", state: "Michigan"},
    {city: "Warren", state: "Michigan"},
    {city: "Waterford", state: "Michigan"},
    {city: "Waverly", state: "Michigan"},
    {city: "Wayne", state: "Michigan"},
    {city: "West Bloomfield Township", state: "Michigan"},
    {city: "Westland", state: "Michigan"},
    {city: "Westwood", state: "Michigan"},
    {city: "Whitmore Lake", state: "Michigan"},
    {city: "Wixom", state: "Michigan"},
    {city: "Woodhaven", state: "Michigan"},
    {city: "Wyandotte", state: "Michigan"},
    {city: "Wyoming", state: "Michigan"},
    {city: "Ypsilanti", state: "Michigan"},
    {city: "Albert Lea", state: "Minnesota"},
    {city: "Alexandria", state: "Minnesota"},
    {city: "Andover", state: "Minnesota"},
    {city: "Anoka", state: "Minnesota"},
    {city: "Apple Valley", state: "Minnesota"},
    {city: "Arden Hills", state: "Minnesota"},
    {city: "Austin", state: "Minnesota"},
    {city: "Bemidji", state: "Minnesota"},
    {city: "Big Lake", state: "Minnesota"},
    {city: "Blaine", state: "Minnesota"},
    {city: "Bloomington", state: "Minnesota"},
    {city: "Brainerd", state: "Minnesota"},
    {city: "Brooklyn Center", state: "Minnesota"},
    {city: "Brooklyn Park", state: "Minnesota"},
    {city: "Buffalo", state: "Minnesota"},
    {city: "Burnsville", state: "Minnesota"},
    {city: "Champlin", state: "Minnesota"},
    {city: "Chanhassen", state: "Minnesota"},
    {city: "Chaska", state: "Minnesota"},
    {city: "Cloquet", state: "Minnesota"},
    {city: "Columbia Heights", state: "Minnesota"},
    {city: "Coon Rapids", state: "Minnesota"},
    {city: "Cottage Grove", state: "Minnesota"},
    {city: "Crookston", state: "Minnesota"},
    {city: "Crystal", state: "Minnesota"},
    {city: "Detroit Lakes", state: "Minnesota"},
    {city: "Duluth", state: "Minnesota"},
    {city: "Eagan", state: "Minnesota"},
    {city: "East Bethel", state: "Minnesota"},
    {city: "Eden Prairie", state: "Minnesota"},
    {city: "Edina", state: "Minnesota"},
    {city: "Elk River", state: "Minnesota"},
    {city: "Fairmont", state: "Minnesota"},
    {city: "Faribault", state: "Minnesota"},
    {city: "Farmington", state: "Minnesota"},
    {city: "Fergus Falls", state: "Minnesota"},
    {city: "Forest Lake", state: "Minnesota"},
    {city: "Fridley", state: "Minnesota"},
    {city: "Golden Valley", state: "Minnesota"},
    {city: "Grand Forks", state: "Minnesota"},
    {city: "Grand Rapids", state: "Minnesota"},
    {city: "Ham Lake", state: "Minnesota"},
    {city: "Hastings", state: "Minnesota"},
    {city: "Hermantown", state: "Minnesota"},
    {city: "Hibbing", state: "Minnesota"},
    {city: "Hopkins", state: "Minnesota"},
    {city: "Hugo", state: "Minnesota"},
    {city: "Hutchinson", state: "Minnesota"},
    {city: "International Falls", state: "Minnesota"},
    {city: "Inver Grove Heights", state: "Minnesota"},
    {city: "Lake Elmo", state: "Minnesota"},
    {city: "Lakeville", state: "Minnesota"},
    {city: "Lino Lakes", state: "Minnesota"},
    {city: "Litchfield", state: "Minnesota"},
    {city: "Little Canada", state: "Minnesota"},
    {city: "Little Falls", state: "Minnesota"},
    {city: "Mahtomedi", state: "Minnesota"},
    {city: "Mankato", state: "Minnesota"},
    {city: "Maple Grove", state: "Minnesota"},
    {city: "Maplewood", state: "Minnesota"},
    {city: "Marshall", state: "Minnesota"},
    {city: "Mendota Heights", state: "Minnesota"},
    {city: "Minneapolis", state: "Minnesota"},
    {city: "Minnetonka", state: "Minnesota"},
    {city: "Monticello", state: "Minnesota"},
    {city: "Moorhead", state: "Minnesota"},
    {city: "Mound", state: "Minnesota"},
    {city: "Mounds View", state: "Minnesota"},
    {city: "New Brighton", state: "Minnesota"},
    {city: "New Hope", state: "Minnesota"},
    {city: "New Ulm", state: "Minnesota"},
    {city: "North Branch", state: "Minnesota"},
    {city: "North Mankato", state: "Minnesota"},
    {city: "North St. Paul", state: "Minnesota"},
    {city: "Northfield", state: "Minnesota"},
    {city: "Oak Grove", state: "Minnesota"},
    {city: "Oakdale", state: "Minnesota"},
    {city: "Orono", state: "Minnesota"},
    {city: "Otsego", state: "Minnesota"},
    {city: "Owatonna", state: "Minnesota"},
    {city: "Plymouth", state: "Minnesota"},
    {city: "Prior Lake", state: "Minnesota"},
    {city: "Ramsey", state: "Minnesota"},
    {city: "Red Wing", state: "Minnesota"},
    {city: "Richfield", state: "Minnesota"},
    {city: "Robbinsdale", state: "Minnesota"},
    {city: "Rochester", state: "Minnesota"},
    {city: "Rosemount", state: "Minnesota"},
    {city: "Roseville", state: "Minnesota"},
    {city: "Sartell", state: "Minnesota"},
    {city: "Sauk Rapids", state: "Minnesota"},
    {city: "Savage", state: "Minnesota"},
    {city: "Shakopee", state: "Minnesota"},
    {city: "Shoreview", state: "Minnesota"},
    {city: "Shorewood", state: "Minnesota"},
    {city: "South St. Paul", state: "Minnesota"},
    {city: "Spring Lake Park", state: "Minnesota"},
    {city: "St. Anthony", state: "Minnesota"},
    {city: "St. Cloud", state: "Minnesota"},
    {city: "St. Louis Park", state: "Minnesota"},
    {city: "St. Michael", state: "Minnesota"},
    {city: "St. Paul", state: "Minnesota"},
    {city: "St. Peter", state: "Minnesota"},
    {city: "Stillwater", state: "Minnesota"},
    {city: "Thief River Falls", state: "Minnesota"},
    {city: "Vadnais Heights", state: "Minnesota"},
    {city: "Virginia", state: "Minnesota"},
    {city: "Waconia", state: "Minnesota"},
    {city: "Waite Park", state: "Minnesota"},
    {city: "Waseca", state: "Minnesota"},
    {city: "West St. Paul", state: "Minnesota"},
    {city: "White Bear Lake", state: "Minnesota"},
    {city: "Willmar", state: "Minnesota"},
    {city: "Winona", state: "Minnesota"},
    {city: "Woodbury", state: "Minnesota"},
    {city: "Worthington", state: "Minnesota"},
    {city: "Aberdeen", state: "Mississippi"},
    {city: "Amory", state: "Mississippi"},
    {city: "Batesville", state: "Mississippi"},
    {city: "Bay St. Louis", state: "Mississippi"},
    {city: "Biloxi", state: "Mississippi"},
    {city: "Booneville", state: "Mississippi"},
    {city: "Brandon", state: "Mississippi"},
    {city: "Brookhaven", state: "Mississippi"},
    {city: "Byram", state: "Mississippi"},
    {city: "Canton", state: "Mississippi"},
    {city: "Clarksdale", state: "Mississippi"},
    {city: "Cleveland", state: "Mississippi"},
    {city: "Clinton", state: "Mississippi"},
    {city: "Columbia", state: "Mississippi"},
    {city: "Columbus", state: "Mississippi"},
    {city: "Corinth", state: "Mississippi"},
    {city: "D’Iberville", state: "Mississippi"},
    {city: "Gautier", state: "Mississippi"},
    {city: "Greenville", state: "Mississippi"},
    {city: "Greenwood", state: "Mississippi"},
    {city: "Grenada", state: "Mississippi"},
    {city: "Gulfport", state: "Mississippi"},
    {city: "Hattiesburg", state: "Mississippi"},
    {city: "Helena", state: "Mississippi"},
    {city: "Hernando", state: "Mississippi"},
    {city: "Holly Springs", state: "Mississippi"},
    {city: "Horn Lake", state: "Mississippi"},
    {city: "Indianola", state: "Mississippi"},
    {city: "Jackson", state: "Mississippi"},
    {city: "Kosciusko", state: "Mississippi"},
    {city: "Laurel", state: "Mississippi"},
    {city: "Long Beach", state: "Mississippi"},
    {city: "Louisville", state: "Mississippi"},
    {city: "Madison", state: "Mississippi"},
    {city: "McComb", state: "Mississippi"},
    {city: "Meridian", state: "Mississippi"},
    {city: "Moss Point", state: "Mississippi"},
    {city: "Natchez", state: "Mississippi"},
    {city: "New Albany", state: "Mississippi"},
    {city: "Ocean Springs", state: "Mississippi"},
    {city: "Olive Branch", state: "Mississippi"},
    {city: "Oxford", state: "Mississippi"},
    {city: "Pascagoula", state: "Mississippi"},
    {city: "Pass Christian", state: "Mississippi"},
    {city: "Pearl", state: "Mississippi"},
    {city: "Petal", state: "Mississippi"},
    {city: "Philadelphia", state: "Mississippi"},
    {city: "Picayune", state: "Mississippi"},
    {city: "Richland", state: "Mississippi"},
    {city: "Ridgeland", state: "Mississippi"},
    {city: "Senatobia", state: "Mississippi"},
    {city: "Southaven", state: "Mississippi"},
    {city: "St. Martin", state: "Mississippi"},
    {city: "Starkville", state: "Mississippi"},
    {city: "Tupelo", state: "Mississippi"},
    {city: "Vicksburg", state: "Mississippi"},
    {city: "Waveland", state: "Mississippi"},
    {city: "West Hattiesburg", state: "Mississippi"},
    {city: "West Point", state: "Mississippi"},
    {city: "Yazoo City", state: "Mississippi"},
    {city: "Affton", state: "Missouri"},
    {city: "Arnold", state: "Missouri"},
    {city: "Aurora", state: "Missouri"},
    {city: "Ballwin", state: "Missouri"},
    {city: "Barnhart", state: "Missouri"},
    {city: "Bellefontaine Neighbors", state: "Missouri"},
    {city: "Belton", state: "Missouri"},
    {city: "Berkeley", state: "Missouri"},
    {city: "Black Jack", state: "Missouri"},
    {city: "Blue Springs", state: "Missouri"},
    {city: "Bolivar", state: "Missouri"},
    {city: "Boonville", state: "Missouri"},
    {city: "Branson", state: "Missouri"},
    {city: "Brentwood", state: "Missouri"},
    {city: "Bridgeton", state: "Missouri"},
    {city: "Cameron", state: "Missouri"},
    {city: "Cape Girardeau", state: "Missouri"},
    {city: "Carthage", state: "Missouri"},
    {city: "Caruthersville", state: "Missouri"},
    {city: "Chesterfield", state: "Missouri"},
    {city: "Chillicothe", state: "Missouri"},
    {city: "Clayton", state: "Missouri"},
    {city: "Clinton", state: "Missouri"},
    {city: "Columbia", state: "Missouri"},
    {city: "Concord", state: "Missouri"},
    {city: "Crestwood", state: "Missouri"},
    {city: "Creve Coeur", state: "Missouri"},
    {city: "De Soto", state: "Missouri"},
    {city: "Des Peres", state: "Missouri"},
    {city: "Dexter", state: "Missouri"},
    {city: "Ellisville", state: "Missouri"},
    {city: "Eureka", state: "Missouri"},
    {city: "Excelsior Springs", state: "Missouri"},
    {city: "Farmington", state: "Missouri"},
    {city: "Ferguson", state: "Missouri"},
    {city: "Festus", state: "Missouri"},
    {city: "Florissant", state: "Missouri"},
    {city: "Fort Leonard Wood", state: "Missouri"},
    {city: "Fulton", state: "Missouri"},
    {city: "Gladstone", state: "Missouri"},
    {city: "Grandview", state: "Missouri"},
    {city: "Hannibal", state: "Missouri"},
    {city: "Harrisonville", state: "Missouri"},
    {city: "Hazelwood", state: "Missouri"},
    {city: "Independence", state: "Missouri"},
    {city: "Jackson", state: "Missouri"},
    {city: "Jefferson City", state: "Missouri"},
    {city: "Jennings", state: "Missouri"},
    {city: "Joplin", state: "Missouri"},
    {city: "Kansas City", state: "Missouri"},
    {city: "Kennett", state: "Missouri"},
    {city: "Kirksville", state: "Missouri"},
    {city: "Kirkwood", state: "Missouri"},
    {city: "Ladue", state: "Missouri"},
    {city: "Lake St. Louis", state: "Missouri"},
    {city: "Lebanon", state: "Missouri"},
    {city: "Lee’s Summit", state: "Missouri"},
    {city: "Lemay", state: "Missouri"},
    {city: "Liberty", state: "Missouri"},
    {city: "Manchester", state: "Missouri"},
    {city: "Maplewood", state: "Missouri"},
    {city: "Marshall", state: "Missouri"},
    {city: "Maryland Heights", state: "Missouri"},
    {city: "Maryville", state: "Missouri"},
    {city: "Mehlville", state: "Missouri"},
    {city: "Mexico", state: "Missouri"},
    {city: "Moberly", state: "Missouri"},
    {city: "Monett", state: "Missouri"},
    {city: "Murphy", state: "Missouri"},
    {city: "Neosho", state: "Missouri"},
    {city: "Nevada", state: "Missouri"},
    {city: "Nixa", state: "Missouri"},
    {city: "O’Fallon", state: "Missouri"},
    {city: "Oakville", state: "Missouri"},
    {city: "Olivette", state: "Missouri"},
    {city: "Overland", state: "Missouri"},
    {city: "Ozark", state: "Missouri"},
    {city: "Park Hills", state: "Missouri"},
    {city: "Perryville", state: "Missouri"},
    {city: "Poplar Bluff", state: "Missouri"},
    {city: "Raymore", state: "Missouri"},
    {city: "Raytown", state: "Missouri"},
    {city: "Republic", state: "Missouri"},
    {city: "Richmond", state: "Missouri"},
    {city: "Richmond Heights", state: "Missouri"},
    {city: "Rolla", state: "Missouri"},
    {city: "Sappington", state: "Missouri"},
    {city: "Sedalia", state: "Missouri"},
    {city: "Shrewsbury", state: "Missouri"},
    {city: "Sikeston", state: "Missouri"},
    {city: "Spanish Lake", state: "Missouri"},
    {city: "Springfield", state: "Missouri"},
    {city: "St. Ann", state: "Missouri"},
    {city: "St. Charles", state: "Missouri"},
    {city: "St. John", state: "Missouri"},
    {city: "St. Joseph", state: "Missouri"},
    {city: "St. Louis", state: "Missouri"},
    {city: "St. Peters", state: "Missouri"},
    {city: "Sullivan", state: "Missouri"},
    {city: "Sunset Hills", state: "Missouri"},
    {city: "Town and Country", state: "Missouri"},
    {city: "Trenton", state: "Missouri"},
    {city: "Troy", state: "Missouri"},
    {city: "Union", state: "Missouri"},
    {city: "University City", state: "Missouri"},
    {city: "Valley Park", state: "Missouri"},
    {city: "Warrensburg", state: "Missouri"},
    {city: "Washington", state: "Missouri"},
    {city: "Webb City", state: "Missouri"},
    {city: "Webster Groves", state: "Missouri"},
    {city: "Wentzville", state: "Missouri"},
    {city: "West Plains", state: "Missouri"},
    {city: "Wildwood", state: "Missouri"},
    {city: "Anaconda-Deer Lodge County", state: "Montana"},
    {city: "Billings", state: "Montana"},
    {city: "Bozeman", state: "Montana"},
    {city: "Butte", state: "Montana"},
    {city: "Evergreen", state: "Montana"},
    {city: "Glasgow", state: "Montana"},
    {city: "Great Falls", state: "Montana"},
    {city: "Havre", state: "Montana"},
    {city: "Helena", state: "Montana"},
    {city: "Helena Valley Southeast", state: "Montana"},
    {city: "Helena Valley West Central", state: "Montana"},
    {city: "Kalispell", state: "Montana"},
    {city: "Laurel", state: "Montana"},
    {city: "Livingston", state: "Montana"},
    {city: "Miles City", state: "Montana"},
    {city: "Missoula", state: "Montana"},
    {city: "Alliance", state: "Nebraska"},
    {city: "Beatrice", state: "Nebraska"},
    {city: "Bellevue", state: "Nebraska"},
    {city: "Blair", state: "Nebraska"},
    {city: "Chadron", state: "Nebraska"},
    {city: "Chalco", state: "Nebraska"},
    {city: "Columbus", state: "Nebraska"},
    {city: "Crete", state: "Nebraska"},
    {city: "Elkhorn", state: "Nebraska"},
    {city: "Fremont", state: "Nebraska"},
    {city: "Gering", state: "Nebraska"},
    {city: "Grand Island", state: "Nebraska"},
    {city: "Hastings", state: "Nebraska"},
    {city: "Kearney", state: "Nebraska"},
    {city: "La Vista", state: "Nebraska"},
    {city: "Lexington", state: "Nebraska"},
    {city: "Lincoln", state: "Nebraska"},
    {city: "McCook", state: "Nebraska"},
    {city: "Nebraska City", state: "Nebraska"},
    {city: "Norfolk", state: "Nebraska"},
    {city: "North Platte", state: "Nebraska"},
    {city: "Offutt AFB", state: "Nebraska"},
    {city: "Omaha", state: "Nebraska"},
    {city: "Papillion", state: "Nebraska"},
    {city: "Plattsmouth", state: "Nebraska"},
    {city: "Ralston", state: "Nebraska"},
    {city: "Scottsbluff", state: "Nebraska"},
    {city: "Seward", state: "Nebraska"},
    {city: "Sidney", state: "Nebraska"},
    {city: "South Sioux City", state: "Nebraska"},
    {city: "Valentine", state: "Nebraska"},
    {city: "York", state: "Nebraska"},
    {city: "Boulder City", state: "Nevada"},
    {city: "Carson City", state: "Nevada"},
    {city: "Elko", state: "Nevada"},
    {city: "Ely", state: "Nevada"},
    {city: "Enterprise", state: "Nevada"},
    {city: "Fallon", state: "Nevada"},
    {city: "Fernley", state: "Nevada"},
    {city: "Gardnerville Ranchos", state: "Nevada"},
    {city: "Hawthorne", state: "Nevada"},
    {city: "Henderson", state: "Nevada"},
    {city: "Incline Village-Crystal Bay", state: "Nevada"},
    {city: "Las Vegas", state: "Nevada"},
    {city: "Laughlin", state: "Nevada"},
    {city: "Lemmon Valley-Golden Valley", state: "Nevada"},
    {city: "Mesquite", state: "Nevada"},
    {city: "Nellis AFB", state: "Nevada"},
    {city: "North Las Vegas", state: "Nevada"},
    {city: "Pahrump", state: "Nevada"},
    {city: "Paradise", state: "Nevada"},
    {city: "Reno", state: "Nevada"},
    {city: "Spanish Springs", state: "Nevada"},
    {city: "Sparks", state: "Nevada"},
    {city: "Spring Creek", state: "Nevada"},
    {city: "Spring Valley", state: "Nevada"},
    {city: "Sun Valley", state: "Nevada"},
    {city: "Sunrise Manor", state: "Nevada"},
    {city: "Whitney", state: "Nevada"},
    {city: "Winchester", state: "Nevada"},
    {city: "Winnemucca", state: "Nevada"},
    {city: "Amherst", state: "New Hampshire"},
    {city: "Atkinson", state: "New Hampshire"},
    {city: "Barrington", state: "New Hampshire"},
    {city: "Bedford", state: "New Hampshire"},
    {city: "Belmont", state: "New Hampshire"},
    {city: "Berlin", state: "New Hampshire"},
    {city: "Bow", state: "New Hampshire"},
    {city: "Claremont", state: "New Hampshire"},
    {city: "Concord", state: "New Hampshire"},
    {city: "Conway", state: "New Hampshire"},
    {city: "Derry", state: "New Hampshire"},
    {city: "Dover", state: "New Hampshire"},
    {city: "Durham", state: "New Hampshire"},
    {city: "Exeter", state: "New Hampshire"},
    {city: "Franklin", state: "New Hampshire"},
    {city: "Gilford", state: "New Hampshire"},
    {city: "Goffstown", state: "New Hampshire"},
    {city: "Hampstead", state: "New Hampshire"},
    {city: "Hampton", state: "New Hampshire"},
    {city: "Hanover", state: "New Hampshire"},
    {city: "Hollis", state: "New Hampshire"},
    {city: "Hooksett", state: "New Hampshire"},
    {city: "Hudson", state: "New Hampshire"},
    {city: "Keene", state: "New Hampshire"},
    {city: "Laconia", state: "New Hampshire"},
    {city: "Lebanon", state: "New Hampshire"},
    {city: "Litchfield", state: "New Hampshire"},
    {city: "Londonderry", state: "New Hampshire"},
    {city: "Manchester", state: "New Hampshire"},
    {city: "Merrimack", state: "New Hampshire"},
    {city: "Milford", state: "New Hampshire"},
    {city: "Nashua", state: "New Hampshire"},
    {city: "Newmarket", state: "New Hampshire"},
    {city: "Newport", state: "New Hampshire"},
    {city: "Pelham", state: "New Hampshire"},
    {city: "Pembroke", state: "New Hampshire"},
    {city: "Plaistow", state: "New Hampshire"},
    {city: "Portsmouth", state: "New Hampshire"},
    {city: "Raymond", state: "New Hampshire"},
    {city: "Rochester", state: "New Hampshire"},
    {city: "Salem", state: "New Hampshire"},
    {city: "Seabrook", state: "New Hampshire"},
    {city: "Somersworth", state: "New Hampshire"},
    {city: "Stratham", state: "New Hampshire"},
    {city: "Swanzey", state: "New Hampshire"},
    {city: "Weare", state: "New Hampshire"},
    {city: "Windham", state: "New Hampshire"},
    {city: "Wolfeboro", state: "New Hampshire"},
    {city: "Absecon", state: "New Jersey"},
    {city: "Allendale", state: "New Jersey"},
    {city: "Asbury Park", state: "New Jersey"},
    {city: "Ashland", state: "New Jersey"},
    {city: "Atlantic City", state: "New Jersey"},
    {city: "Audubon", state: "New Jersey"},
    {city: "Avenel", state: "New Jersey"},
    {city: "Barclay-Kingston", state: "New Jersey"},
    {city: "Barrington", state: "New Jersey"},
    {city: "Bayonne", state: "New Jersey"},
    {city: "Beachwood", state: "New Jersey"},
    {city: "Belleville", state: "New Jersey"},
    {city: "Bellmawr", state: "New Jersey"},
    {city: "Belmar", state: "New Jersey"},
    {city: "Bergenfield", state: "New Jersey"},
    {city: "Berkeley Heights", state: "New Jersey"},
    {city: "Berlin", state: "New Jersey"},
    {city: "Bernardsville", state: "New Jersey"},
    {city: "Bloomfield", state: "New Jersey"},
    {city: "Bloomingdale", state: "New Jersey"},
    {city: "Bogota", state: "New Jersey"},
    {city: "Boonton", state: "New Jersey"},
    {city: "Bound Brook", state: "New Jersey"},
    {city: "Bridgeton", state: "New Jersey"},
    {city: "Brigantine", state: "New Jersey"},
    {city: "Browns Mills", state: "New Jersey"},
    {city: "Budd Lake", state: "New Jersey"},
    {city: "Burlington", state: "New Jersey"},
    {city: "Butler", state: "New Jersey"},
    {city: "Caldwell", state: "New Jersey"},
    {city: "Camden", state: "New Jersey"},
    {city: "Cape May", state: "New Jersey"},
    {city: "Carneys Point", state: "New Jersey"},
    {city: "Carteret", state: "New Jersey"},
    {city: "Cedar Grove", state: "New Jersey"},
    {city: "Chatham", state: "New Jersey"},
    {city: "Cherry Hill Mall", state: "New Jersey"},
    {city: "Clark", state: "New Jersey"},
    {city: "Clayton", state: "New Jersey"},
    {city: "Cliffside Park", state: "New Jersey"},
    {city: "Clifton", state: "New Jersey"},
    {city: "Closter", state: "New Jersey"},
    {city: "Collingswood", state: "New Jersey"},
    {city: "Colonia", state: "New Jersey"},
    {city: "Cranford", state: "New Jersey"},
    {city: "Cresskill", state: "New Jersey"},
    {city: "Crestwood Village", state: "New Jersey"},
    {city: "Dayton", state: "New Jersey"},
    {city: "Dover", state: "New Jersey"},
    {city: "Dumont", state: "New Jersey"},
    {city: "Dunellen", state: "New Jersey"},
    {city: "East Brunswick", state: "New Jersey"},
    {city: "East Orange", state: "New Jersey"},
    {city: "East Rutherford", state: "New Jersey"},
    {city: "Eatontown", state: "New Jersey"},
    {city: "Echelon", state: "New Jersey"},
    {city: "Edgewater", state: "New Jersey"},
    {city: "Edison", state: "New Jersey"},
    {city: "Elizabeth", state: "New Jersey"},
    {city: "Elmwood Park", state: "New Jersey"},
    {city: "Emerson", state: "New Jersey"},
    {city: "Englewood", state: "New Jersey"},
    {city: "Erlton-Ellisburg", state: "New Jersey"},
    {city: "Ewing", state: "New Jersey"},
    {city: "Fair Lawn", state: "New Jersey"},
    {city: "Fairfield", state: "New Jersey"},
    {city: "Fairview", state: "New Jersey"},
    {city: "Fanwood", state: "New Jersey"},
    {city: "Flanders", state: "New Jersey"},
    {city: "Florence-Roebling", state: "New Jersey"},
    {city: "Florham Park", state: "New Jersey"},
    {city: "Fords", state: "New Jersey"},
    {city: "Fort Dix", state: "New Jersey"},
    {city: "Fort Lee", state: "New Jersey"},
    {city: "Franklin Lakes", state: "New Jersey"},
    {city: "Freehold", state: "New Jersey"},
    {city: "Garfield", state: "New Jersey"},
    {city: "Glassboro", state: "New Jersey"},
    {city: "Glen Ridge", state: "New Jersey"},
    {city: "Glen Rock", state: "New Jersey"},
    {city: "Gloucester City", state: "New Jersey"},
    {city: "Greentree", state: "New Jersey"},
    {city: "Guttenberg", state: "New Jersey"},
    {city: "Hackensack", state: "New Jersey"},
    {city: "Hackettstown", state: "New Jersey"},
    {city: "Haddon Heights", state: "New Jersey"},
    {city: "Haddonfield", state: "New Jersey"},
    {city: "Haledon", state: "New Jersey"},
    {city: "Hammonton", state: "New Jersey"},
    {city: "Harrison", state: "New Jersey"},
    {city: "Hasbrouck Heights", state: "New Jersey"},
    {city: "Hawthorne", state: "New Jersey"},
    {city: "Highland Park", state: "New Jersey"},
    {city: "Hillsdale", state: "New Jersey"},
    {city: "Hillside", state: "New Jersey"},
    {city: "Hoboken", state: "New Jersey"},
    {city: "Holiday City-Berkeley", state: "New Jersey"},
    {city: "Hopatcong", state: "New Jersey"},
    {city: "Hunterdon", state: "New Jersey"},
    {city: "Irvington", state: "New Jersey"},
    {city: "Iselin", state: "New Jersey"},
    {city: "Jamesburg", state: "New Jersey"},
    {city: "Jersey City", state: "New Jersey"},
    {city: "Keansburg", state: "New Jersey"},
    {city: "Kearny", state: "New Jersey"},
    {city: "Kendall Park", state: "New Jersey"},
    {city: "Kenilworth", state: "New Jersey"},
    {city: "Keyport", state: "New Jersey"},
    {city: "Kinnelon", state: "New Jersey"},
    {city: "Lake Mohawk", state: "New Jersey"},
    {city: "Lakewood", state: "New Jersey"},
    {city: "Laurence Harbor", state: "New Jersey"},
    {city: "Leisure Village West-Pine Lake Park", state: "New Jersey"},
    {city: "Leonia", state: "New Jersey"},
    {city: "Lincoln Park", state: "New Jersey"},
    {city: "Lincroft", state: "New Jersey"},
    {city: "Linden", state: "New Jersey"},
    {city: "Lindenwold", state: "New Jersey"},
    {city: "Linwood", state: "New Jersey"},
    {city: "Little Falls", state: "New Jersey"},
    {city: "Little Ferry", state: "New Jersey"},
    {city: "Little Silver", state: "New Jersey"},
    {city: "Livingston", state: "New Jersey"},
    {city: "Lodi", state: "New Jersey"},
    {city: "Long Branch", state: "New Jersey"},
    {city: "Lyndhurst", state: "New Jersey"},
    {city: "Madison", state: "New Jersey"},
    {city: "Madison Park", state: "New Jersey"},
    {city: "Manasquan", state: "New Jersey"},
    {city: "Manville", state: "New Jersey"},
    {city: "Maplewood", state: "New Jersey"},
    {city: "Margate City", state: "New Jersey"},
    {city: "Marlton", state: "New Jersey"},
    {city: "Matawan", state: "New Jersey"},
    {city: "Maywood", state: "New Jersey"},
    {city: "McGuire AFB", state: "New Jersey"},
    {city: "Mercerville-Hamilton Square", state: "New Jersey"},
    {city: "Metuchen", state: "New Jersey"},
    {city: "Middlesex", state: "New Jersey"},
    {city: "Midland Park", state: "New Jersey"},
    {city: "Millburn", state: "New Jersey"},
    {city: "Milltown", state: "New Jersey"},
    {city: "Millville", state: "New Jersey"},
    {city: "Monmouth", state: "New Jersey"},
    {city: "Montclair", state: "New Jersey"},
    {city: "Montvale", state: "New Jersey"},
    {city: "Moorestown-Lenola", state: "New Jersey"},
    {city: "Morganville", state: "New Jersey"},
    {city: "Morristown", state: "New Jersey"},
    {city: "Mountainside", state: "New Jersey"},
    {city: "Mystic Island", state: "New Jersey"},
    {city: "New Brunswick", state: "New Jersey"},
    {city: "New Milford", state: "New Jersey"},
    {city: "New Providence", state: "New Jersey"},
    {city: "Newark", state: "New Jersey"},
    {city: "Newton", state: "New Jersey"},
    {city: "North Arlington", state: "New Jersey"},
    {city: "North Brunswick Township", state: "New Jersey"},
    {city: "North Caldwell", state: "New Jersey"},
    {city: "North Haledon", state: "New Jersey"},
    {city: "North Plainfield", state: "New Jersey"},
    {city: "Northfield", state: "New Jersey"},
    {city: "Nutley", state: "New Jersey"},
    {city: "Oakland", state: "New Jersey"},
    {city: "Ocean Acres", state: "New Jersey"},
    {city: "Ocean City", state: "New Jersey"},
    {city: "Old Bridge", state: "New Jersey"},
    {city: "Oradell", state: "New Jersey"},
    {city: "Orange", state: "New Jersey"},
    {city: "Palisades Park", state: "New Jersey"},
    {city: "Palmyra", state: "New Jersey"},
    {city: "Paramus", state: "New Jersey"},
    {city: "Park Ridge", state: "New Jersey"},
    {city: "Passaic", state: "New Jersey"},
    {city: "Paterson", state: "New Jersey"},
    {city: "Paulsboro", state: "New Jersey"},
    {city: "Pennsauken", state: "New Jersey"},
    {city: "Pennsville", state: "New Jersey"},
    {city: "Perth Amboy", state: "New Jersey"},
    {city: "Phillipsburg", state: "New Jersey"},
    {city: "Pine Hill", state: "New Jersey"},
    {city: "Pitman", state: "New Jersey"},
    {city: "Plainfield", state: "New Jersey"},
    {city: "Pleasantville", state: "New Jersey"},
    {city: "Point Pleasant", state: "New Jersey"},
    {city: "Pompton Lakes", state: "New Jersey"},
    {city: "Princeton", state: "New Jersey"},
    {city: "Princeton Meadows", state: "New Jersey"},
    {city: "Rahway", state: "New Jersey"},
    {city: "Ramblewood", state: "New Jersey"},
    {city: "Ramsey", state: "New Jersey"},
    {city: "Raritan", state: "New Jersey"},
    {city: "Red Bank", state: "New Jersey"},
    {city: "Ridgefield", state: "New Jersey"},
    {city: "Ridgefield Park", state: "New Jersey"},
    {city: "Ridgewood", state: "New Jersey"},
    {city: "Ringwood", state: "New Jersey"},
    {city: "River Edge", state: "New Jersey"},
    {city: "River Vale", state: "New Jersey"},
    {city: "Rockaway", state: "New Jersey"},
    {city: "Roselle", state: "New Jersey"},
    {city: "Roselle Park", state: "New Jersey"},
    {city: "Rumson", state: "New Jersey"},
    {city: "Runnemede", state: "New Jersey"},
    {city: "Rutherford", state: "New Jersey"},
    {city: "Saddle Brook", state: "New Jersey"},
    {city: "Sayreville", state: "New Jersey"},
    {city: "Scotch Plains", state: "New Jersey"},
    {city: "Secaucus", state: "New Jersey"},
    {city: "Somers Point", state: "New Jersey"},
    {city: "Somerset", state: "New Jersey"},
    {city: "Somerville", state: "New Jersey"},
    {city: "South Amboy", state: "New Jersey"},
    {city: "South Orange", state: "New Jersey"},
    {city: "South Plainfield", state: "New Jersey"},
    {city: "South River", state: "New Jersey"},
    {city: "Spotswood", state: "New Jersey"},
    {city: "Springdale", state: "New Jersey"},
    {city: "Springfield", state: "New Jersey"},
    {city: "Stratford", state: "New Jersey"},
    {city: "Strathmore", state: "New Jersey"},
    {city: "Succasunna-Kenvil", state: "New Jersey"},
    {city: "Summit", state: "New Jersey"},
    {city: "Teaneck", state: "New Jersey"},
    {city: "Tenafly", state: "New Jersey"},
    {city: "Tinton Falls", state: "New Jersey"},
    {city: "Toms River", state: "New Jersey"},
    {city: "Totowa", state: "New Jersey"},
    {city: "Trenton", state: "New Jersey"},
    {city: "Twin Rivers", state: "New Jersey"},
    {city: "Union", state: "New Jersey"},
    {city: "Union Beach", state: "New Jersey"},
    {city: "Union City", state: "New Jersey"},
    {city: "Upper Saddle River", state: "New Jersey"},
    {city: "Ventnor City", state: "New Jersey"},
    {city: "Verona", state: "New Jersey"},
    {city: "Villas", state: "New Jersey"},
    {city: "Vineland", state: "New Jersey"},
    {city: "Waldwick", state: "New Jersey"},
    {city: "Wallington", state: "New Jersey"},
    {city: "Wanaque", state: "New Jersey"},
    {city: "Washington", state: "New Jersey"},
    {city: "Washington Township", state: "New Jersey"},
    {city: "Wayne", state: "New Jersey"},
    {city: "West Caldwell", state: "New Jersey"},
    {city: "West Freehold", state: "New Jersey"},
    {city: "West Long Branch", state: "New Jersey"},
    {city: "West Milford", state: "New Jersey"},
    {city: "West New York", state: "New Jersey"},
    {city: "West Orange", state: "New Jersey"},
    {city: "West Paterson", state: "New Jersey"},
    {city: "Westfield", state: "New Jersey"},
    {city: "Westwood", state: "New Jersey"},
    {city: "Wharton", state: "New Jersey"},
    {city: "White Horse", state: "New Jersey"},
    {city: "White Meadow Lake", state: "New Jersey"},
    {city: "Williamstown", state: "New Jersey"},
    {city: "Willingboro", state: "New Jersey"},
    {city: "Wood-Ridge", state: "New Jersey"},
    {city: "Woodbridge", state: "New Jersey"},
    {city: "Woodbury", state: "New Jersey"},
    {city: "Wyckoff", state: "New Jersey"},
    {city: "Yardville-Groveville", state: "New Jersey"},
    {city: "Yorketown", state: "New Jersey"},
    {city: "Alamogordo", state: "New Mexico"},
    {city: "Albuquerque", state: "New Mexico"},
    {city: "Anthony", state: "New Mexico"},
    {city: "Artesia", state: "New Mexico"},
    {city: "Aztec", state: "New Mexico"},
    {city: "Belen", state: "New Mexico"},
    {city: "Bernalillo", state: "New Mexico"},
    {city: "Bloomfield", state: "New Mexico"},
    {city: "Carlsbad", state: "New Mexico"},
    {city: "Chaparral", state: "New Mexico"},
    {city: "Clovis", state: "New Mexico"},
    {city: "Corrales", state: "New Mexico"},
    {city: "Deming", state: "New Mexico"},
    {city: "Espanola", state: "New Mexico"},
    {city: "Farmington", state: "New Mexico"},
    {city: "Gallup", state: "New Mexico"},
    {city: "Grants", state: "New Mexico"},
    {city: "Hobbs", state: "New Mexico"},
    {city: "Kirtland", state: "New Mexico"},
    {city: "Las Cruces", state: "New Mexico"},
    {city: "Las Vegas", state: "New Mexico"},
    {city: "Los Alamos", state: "New Mexico"},
    {city: "Los Lunas", state: "New Mexico"},
    {city: "Lovington", state: "New Mexico"},
    {city: "North Valley", state: "New Mexico"},
    {city: "Portales", state: "New Mexico"},
    {city: "Raton", state: "New Mexico"},
    {city: "Rio Rancho", state: "New Mexico"},
    {city: "Roswell", state: "New Mexico"},
    {city: "Ruidoso", state: "New Mexico"},
    {city: "Santa Fe", state: "New Mexico"},
    {city: "Shiprock", state: "New Mexico"},
    {city: "Silver City", state: "New Mexico"},
    {city: "Socorro", state: "New Mexico"},
    {city: "South Valley", state: "New Mexico"},
    {city: "Sunland Park", state: "New Mexico"},
    {city: "Truth or Consequences", state: "New Mexico"},
    {city: "White Rock", state: "New Mexico"},
    {city: "Zuni Pueblo", state: "New Mexico"},
    {city: "Airmont", state: "New York"},
    {city: "Albany", state: "New York"},
    {city: "Albion", state: "New York"},
    {city: "Alden", state: "New York"},
    {city: "Allegany", state: "New York"},
    {city: "Altamont", state: "New York"},
    {city: "Amherst", state: "New York"},
    {city: "Amityville", state: "New York"},
    {city: "Amsterdam", state: "New York"},
    {city: "Arcadia", state: "New York"},
    {city: "Arlington", state: "New York"},
    {city: "Attica", state: "New York"},
    {city: "Auburn", state: "New York"},
    {city: "Aurora", state: "New York"},
    {city: "Avon", state: "New York"},
    {city: "Babylon", state: "New York"},
    {city: "Baldwin", state: "New York"},
    {city: "Baldwin Harbor", state: "New York"},
    {city: "Baldwinsville", state: "New York"},
    {city: "Ballston", state: "New York"},
    {city: "Barton", state: "New York"},
    {city: "Batavia", state: "New York"},
    {city: "Bath", state: "New York"},
    {city: "Bay Shore", state: "New York"},
    {city: "Bayport", state: "New York"},
    {city: "Bayville", state: "New York"},
    {city: "Baywood", state: "New York"},
    {city: "Beacon", state: "New York"},
    {city: "Bedford", state: "New York"},
    {city: "Beekman", state: "New York"},
    {city: "Bellmore", state: "New York"},
    {city: "Bethlehem", state: "New York"},
    {city: "Bethpage", state: "New York"},
    {city: "Big Flats", state: "New York"},
    {city: "Binghamton", state: "New York"},
    {city: "Blooming Grove", state: "New York"},
    {city: "Bohemia", state: "New York"},
    {city: "Boston", state: "New York"},
    {city: "Brentwood", state: "New York"},
    {city: "Briarcliff Manor", state: "New York"},
    {city: "Brighton", state: "New York"},
    {city: "Brockport", state: "New York"},
    {city: "Bronxville", state: "New York"},
    {city: "Brookhaven", state: "New York"},
    {city: "Brunswick", state: "New York"},
    {city: "Buffalo", state: "New York"},
    {city: "Busti", state: "New York"},
    {city: "Cairo", state: "New York"},
    {city: "Camillus", state: "New York"},
    {city: "Canandaigua", state: "New York"},
    {city: "Canton", state: "New York"},
    {city: "Carmel", state: "New York"},
    {city: "Catskill", state: "New York"},
    {city: "Cazenovia", state: "New York"},
    {city: "Cedarhurst", state: "New York"},
    {city: "Center Moriches", state: "New York"},
    {city: "Centereach", state: "New York"},
    {city: "Central Islip", state: "New York"},
    {city: "Chappaqua", state: "New York"},
    {city: "Cheektowaga", state: "New York"},
    {city: "Chenango", state: "New York"},
    {city: "Chester", state: "New York"},
    {city: "Chestnut Ridge", state: "New York"},
    {city: "Chili", state: "New York"},
    {city: "Cicero", state: "New York"},
    {city: "Clarence", state: "New York"},
    {city: "Clarkson", state: "New York"},
    {city: "Clarkstown", state: "New York"},
    {city: "Claverack", state: "New York"},
    {city: "Clay", state: "New York"},
    {city: "Clifton Park", state: "New York"},
    {city: "Cobleskill", state: "New York"},
    {city: "Coeymans", state: "New York"},
    {city: "Cohoes", state: "New York"},
    {city: "Collins", state: "New York"},
    {city: "Colonie", state: "New York"},
    {city: "Commack", state: "New York"},
    {city: "Concord", state: "New York"},
    {city: "Congers", state: "New York"},
    {city: "Copiague", state: "New York"},
    {city: "Coram", state: "New York"},
    {city: "Corning", state: "New York"},
    {city: "Cornwall", state: "New York"},
    {city: "Cortland", state: "New York"},
    {city: "Cortlandt", state: "New York"},
    {city: "Cortlandville", state: "New York"},
    {city: "Coxsackie", state: "New York"},
    {city: "Crawford", state: "New York"},
    {city: "Croton-on-Hudson", state: "New York"},
    {city: "De Witt", state: "New York"},
    {city: "Deer Park", state: "New York"},
    {city: "Deerpark", state: "New York"},
    {city: "Delmar", state: "New York"},
    {city: "Depew", state: "New York"},
    {city: "Dix Hills", state: "New York"},
    {city: "Dobbs Ferry", state: "New York"},
    {city: "Dover", state: "New York"},
    {city: "Dryden", state: "New York"},
    {city: "Dunkirk", state: "New York"},
    {city: "Dutchess County", state: "New York"},
    {city: "East Aurora", state: "New York"},
    {city: "East Fishkill", state: "New York"},
    {city: "East Glenville", state: "New York"},
    {city: "East Greenbush", state: "New York"},
    {city: "East Hampton", state: "New York"},
    {city: "East Hills", state: "New York"},
    {city: "East Islip", state: "New York"},
    {city: "East Massapequa", state: "New York"},
    {city: "East Meadow", state: "New York"},
    {city: "East Northport", state: "New York"},
    {city: "East Patchogue", state: "New York"},
    {city: "East Rochester", state: "New York"},
    {city: "East Rockaway", state: "New York"},
    {city: "Eastchester", state: "New York"},
    {city: "Eden", state: "New York"},
    {city: "Elbridge", state: "New York"},
    {city: "Ellicott", state: "New York"},
    {city: "Elma", state: "New York"},
    {city: "Elmira", state: "New York"},
    {city: "Elmont", state: "New York"},
    {city: "Elwood", state: "New York"},
    {city: "Endicott", state: "New York"},
    {city: "Endwell", state: "New York"},
    {city: "Erwin", state: "New York"},
    {city: "Esopus", state: "New York"},
    {city: "Evans", state: "New York"},
    {city: "Fairmount", state: "New York"},
    {city: "Fallsburg", state: "New York"},
    {city: "Farmingdale", state: "New York"},
    {city: "Farmington", state: "New York"},
    {city: "Farmingville", state: "New York"},
    {city: "Fenton", state: "New York"},
    {city: "Fishkill", state: "New York"},
    {city: "Floral Park", state: "New York"},
    {city: "Fort Ann", state: "New York"},
    {city: "Fort Drum", state: "New York"},
    {city: "Fort Salonga", state: "New York"},
    {city: "Frankfort", state: "New York"},
    {city: "Franklin Square", state: "New York"},
    {city: "Fredonia", state: "New York"},
    {city: "Freeport", state: "New York"},
    {city: "Fulton", state: "New York"},
    {city: "Garden City", state: "New York"},
    {city: "Garden City Park", state: "New York"},
    {city: "Gates", state: "New York"},
    {city: "Gates-North Gates", state: "New York"},
    {city: "Geddes", state: "New York"},
    {city: "Geneseo", state: "New York"},
    {city: "Geneva", state: "New York"},
    {city: "German Flatts", state: "New York"},
    {city: "Glen Cove", state: "New York"},
    {city: "Glens Falls", state: "New York"},
    {city: "Glens Falls North", state: "New York"},
    {city: "Glenville", state: "New York"},
    {city: "Gloversville", state: "New York"},
    {city: "Goshen", state: "New York"},
    {city: "Gouverneur", state: "New York"},
    {city: "Granby", state: "New York"},
    {city: "Grand Island", state: "New York"},
    {city: "Granville", state: "New York"},
    {city: "Great Neck", state: "New York"},
    {city: "Great Neck Plaza", state: "New York"},
    {city: "Greece", state: "New York"},
    {city: "Greenburgh", state: "New York"},
    {city: "Greenfield", state: "New York"},
    {city: "Greenlawn", state: "New York"},
    {city: "Greenville", state: "New York"},
    {city: "Guilderland", state: "New York"},
    {city: "Halfmoon", state: "New York"},
    {city: "Hamburg", state: "New York"},
    {city: "Hamlin", state: "New York"},
    {city: "Hampton Bays", state: "New York"},
    {city: "Hanover", state: "New York"},
    {city: "Harrison", state: "New York"},
    {city: "Hartsdale", state: "New York"},
    {city: "Hastings", state: "New York"},
    {city: "Hastings-on-Hudson", state: "New York"},
    {city: "Hauppauge", state: "New York"},
    {city: "Haverstraw", state: "New York"},
    {city: "Hempstead", state: "New York"},
    {city: "Henrietta", state: "New York"},
    {city: "Herkimer", state: "New York"},
    {city: "Hewlett", state: "New York"},
    {city: "Hicksville", state: "New York"},
    {city: "Highlands", state: "New York"},
    {city: "Hillcrest", state: "New York"},
    {city: "Holbrook", state: "New York"},
    {city: "Holtsville", state: "New York"},
    {city: "Homer", state: "New York"},
    {city: "Hoosick", state: "New York"},
    {city: "Hornell", state: "New York"},
    {city: "Horseheads", state: "New York"},
    {city: "Hudson", state: "New York"},
    {city: "Hudson Falls", state: "New York"},
    {city: "Huntington", state: "New York"},
    {city: "Huntington Station", state: "New York"},
    {city: "Hurley", state: "New York"},
    {city: "Hyde Park", state: "New York"},
    {city: "Ilion", state: "New York"},
    {city: "Inwood", state: "New York"},
    {city: "Irondequoit", state: "New York"},
    {city: "Irvington", state: "New York"},
    {city: "Islip", state: "New York"},
    {city: "Ithaca", state: "New York"},
    {city: "Jamestown", state: "New York"},
    {city: "Jefferson Valley-Yorktown", state: "New York"},
    {city: "Jericho", state: "New York"},
    {city: "Johnson City", state: "New York"},
    {city: "Johnstown", state: "New York"},
    {city: "Kenmore", state: "New York"},
    {city: "Kent", state: "New York"},
    {city: "Kinderhook", state: "New York"},
    {city: "Kings Park", state: "New York"},
    {city: "Kingsbury", state: "New York"},
    {city: "Kingston", state: "New York"},
    {city: "Kirkland", state: "New York"},
    {city: "Kiryas Joel", state: "New York"},
    {city: "La Grange", state: "New York"},
    {city: "Lackawanna", state: "New York"},
    {city: "Lake Carmel", state: "New York"},
    {city: "Lake Grove", state: "New York"},
    {city: "Lake Ronkonkoma", state: "New York"},
    {city: "Lancaster", state: "New York"},
    {city: "Lansing", state: "New York"},
    {city: "Larchmont", state: "New York"},
    {city: "Lawrence", state: "New York"},
    {city: "Le Ray", state: "New York"},
    {city: "Le Roy", state: "New York"},
    {city: "Lee", state: "New York"},
    {city: "Lenox", state: "New York"},
    {city: "Levittown", state: "New York"},
    {city: "Lewisboro", state: "New York"},
    {city: "Lewiston", state: "New York"},
    {city: "Liberty", state: "New York"},
    {city: "Lindenhurst", state: "New York"},
    {city: "Livonia", state: "New York"},
    {city: "Lloyd", state: "New York"},
    {city: "Lockport", state: "New York"},
    {city: "Long Island", state: "New York"},
    {city: "Lynbrook", state: "New York"},
    {city: "Lysander", state: "New York"},
    {city: "Macedon", state: "New York"},
    {city: "Mahopac", state: "New York"},
    {city: "Malone", state: "New York"},
    {city: "Malta", state: "New York"},
    {city: "Malverne", state: "New York"},
    {city: "Mamakating", state: "New York"},
    {city: "Mamaroneck", state: "New York"},
    {city: "Manchester", state: "New York"},
    {city: "Manhasset", state: "New York"},
    {city: "Manlius", state: "New York"},
    {city: "Manorhaven", state: "New York"},
    {city: "Manorville", state: "New York"},
    {city: "Marcellus", state: "New York"},
    {city: "Marcy", state: "New York"},
    {city: "Marlborough", state: "New York"},
    {city: "Massapequa", state: "New York"},
    {city: "Massapequa Park", state: "New York"},
    {city: "Massena", state: "New York"},
    {city: "Mastic", state: "New York"},
    {city: "Mastic Beach", state: "New York"},
    {city: "Mattydale", state: "New York"},
    {city: "Mayfield", state: "New York"},
    {city: "Mechanicstown", state: "New York"},
    {city: "Medford", state: "New York"},
    {city: "Medina", state: "New York"},
    {city: "Melville", state: "New York"},
    {city: "Mendon", state: "New York"},
    {city: "Merrick", state: "New York"},
    {city: "Middle Island", state: "New York"},
    {city: "Middletown", state: "New York"},
    {city: "Miller Place", state: "New York"},
    {city: "Milo", state: "New York"},
    {city: "Milton", state: "New York"},
    {city: "Mineola", state: "New York"},
    {city: "Monroe", state: "New York"},
    {city: "Monsey", state: "New York"},
    {city: "Montgomery", state: "New York"},
    {city: "Monticello", state: "New York"},
    {city: "Moreau", state: "New York"},
    {city: "Mount Hope", state: "New York"},
    {city: "Mount Ivy", state: "New York"},
    {city: "Mount Kisco", state: "New York"},
    {city: "Mount Pleasant", state: "New York"},
    {city: "Mount Sinai", state: "New York"},
    {city: "Mount Vernon", state: "New York"},
    {city: "Murray", state: "New York"},
    {city: "Nanuet", state: "New York"},
    {city: "Nassau", state: "New York"},
    {city: "Nesconset", state: "New York"},
    {city: "New Cassel", state: "New York"},
    {city: "New Castle", state: "New York"},
    {city: "New City", state: "New York"},
    {city: "New Hartford", state: "New York"},
    {city: "New Hyde Park", state: "New York"},
    {city: "New Paltz", state: "New York"},
    {city: "New Rochelle", state: "New York"},
    {city: "New Scotland", state: "New York"},
    {city: "New Windsor", state: "New York"},
    {city: "New York", state: "New York"},
    {city: "New York City", state: "New York"},
    {city: "Newark", state: "New York"},
    {city: "Newburgh", state: "New York"},
    {city: "Newfane", state: "New York"},
    {city: "Newstead", state: "New York"},
    {city: "Niagara", state: "New York"},
    {city: "Niagara Falls", state: "New York"},
    {city: "Niskayuna", state: "New York"},
    {city: "North Amityville", state: "New York"},
    {city: "North Babylon", state: "New York"},
    {city: "North Bay Shore", state: "New York"},
    {city: "North Bellmore", state: "New York"},
    {city: "North Bellport", state: "New York"},
    {city: "North Castle", state: "New York"},
    {city: "North Elba", state: "New York"},
    {city: "North Greenbush", state: "New York"},
    {city: "North Hempstead", state: "New York"},
    {city: "North Lindenhurst", state: "New York"},
    {city: "North Massapequa", state: "New York"},
    {city: "North Merrick", state: "New York"},
    {city: "North New Hyde Park", state: "New York"},
    {city: "North Patchogue", state: "New York"},
    {city: "North Syracuse", state: "New York"},
    {city: "North Tonawanda", state: "New York"},
    {city: "North Valley Stream", state: "New York"},
    {city: "North Wantagh", state: "New York"},
    {city: "Northport", state: "New York"},
    {city: "Norwich", state: "New York"},
    {city: "Nyack", state: "New York"},
    {city: "Oakdale", state: "New York"},
    {city: "Oceanside", state: "New York"},
    {city: "Ogden", state: "New York"},
    {city: "Ogdensburg", state: "New York"},
    {city: "Olean", state: "New York"},
    {city: "Oneida", state: "New York"},
    {city: "Oneonta", state: "New York"},
    {city: "Onondaga", state: "New York"},
    {city: "Ontario", state: "New York"},
    {city: "Orange Lake", state: "New York"},
    {city: "Orangetown", state: "New York"},
    {city: "Orchard Park", state: "New York"},
    {city: "Ossining", state: "New York"},
    {city: "Oswego", state: "New York"},
    {city: "Owego", state: "New York"},
    {city: "Oyster Bay", state: "New York"},
    {city: "Palmyra", state: "New York"},
    {city: "Parma", state: "New York"},
    {city: "Patchogue", state: "New York"},
    {city: "Patterson", state: "New York"},
    {city: "Pawling", state: "New York"},
    {city: "Pearl River", state: "New York"},
    {city: "Peekskill", state: "New York"},
    {city: "Pelham", state: "New York"},
    {city: "Pendleton", state: "New York"},
    {city: "Penfield", state: "New York"},
    {city: "Perinton", state: "New York"},
    {city: "Perry", state: "New York"},
    {city: "Peru", state: "New York"},
    {city: "Phelps", state: "New York"},
    {city: "Philipstown", state: "New York"},
    {city: "Pittsford", state: "New York"},
    {city: "Plainedge", state: "New York"},
    {city: "Plainview", state: "New York"},
    {city: "Plattekill", state: "New York"},
    {city: "Plattsburgh", state: "New York"},
    {city: "Pleasant Valley", state: "New York"},
    {city: "Pleasantville", state: "New York"},
    {city: "Pomfret", state: "New York"},
    {city: "Pompey", state: "New York"},
    {city: "Port Chester", state: "New York"},
    {city: "Port Jefferson", state: "New York"},
    {city: "Port Jefferson Station", state: "New York"},
    {city: "Port Jervis", state: "New York"},
    {city: "Port Washington", state: "New York"},
    {city: "Porter", state: "New York"},
    {city: "Potsdam", state: "New York"},
    {city: "Poughkeepsie", state: "New York"},
    {city: "Putnam Valley", state: "New York"},
    {city: "Queensbury", state: "New York"},
    {city: "Ramapo", state: "New York"},
    {city: "Red Hook", state: "New York"},
    {city: "Rensselaer", state: "New York"},
    {city: "Rhinebeck", state: "New York"},
    {city: "Ridge", state: "New York"},
    {city: "Ridgeway", state: "New York"},
    {city: "Riverhead", state: "New York"},
    {city: "Rochester", state: "New York"},
    {city: "Rockville Centre", state: "New York"},
    {city: "Rocky Point", state: "New York"},
    {city: "Rome", state: "New York"},
    {city: "Ronkonkoma", state: "New York"},
    {city: "Roosevelt", state: "New York"},
    {city: "Rosendale", state: "New York"},
    {city: "Roslyn Heights", state: "New York"},
    {city: "Rotterdam", state: "New York"},
    {city: "Royalton", state: "New York"},
    {city: "Rye", state: "New York"},
    {city: "Rye Brook", state: "New York"},
    {city: "Salamanca", state: "New York"},
    {city: "Salina", state: "New York"},
    {city: "Salisbury", state: "New York"},
    {city: "Sand Lake", state: "New York"},
    {city: "Saratoga Springs", state: "New York"},
    {city: "Saugerties", state: "New York"},
    {city: "Sayville", state: "New York"},
    {city: "Scarsdale", state: "New York"},
    {city: "Schaghticoke", state: "New York"},
    {city: "Schenectady", state: "New York"},
    {city: "Schodack", state: "New York"},
    {city: "Schroeppel", state: "New York"},
    {city: "Scotchtown", state: "New York"},
    {city: "Scotia", state: "New York"},
    {city: "Scriba", state: "New York"},
    {city: "Seaford", state: "New York"},
    {city: "Selden", state: "New York"},
    {city: "Seneca Falls", state: "New York"},
    {city: "Setauket-East Setauket", state: "New York"},
    {city: "Shawangunk", state: "New York"},
    {city: "Shirley", state: "New York"},
    {city: "Sidney", state: "New York"},
    {city: "Skaneateles", state: "New York"},
    {city: "Sleepy Hollow", state: "New York"},
    {city: "Smithtown", state: "New York"},
    {city: "Sodus", state: "New York"},
    {city: "Solvay", state: "New York"},
    {city: "Somers", state: "New York"},
    {city: "Sound Beach", state: "New York"},
    {city: "South Farmingdale", state: "New York"},
    {city: "South Hill", state: "New York"},
    {city: "South Huntington", state: "New York"},
    {city: "South Lockport", state: "New York"},
    {city: "Southampton", state: "New York"},
    {city: "Southeast", state: "New York"},
    {city: "Southold", state: "New York"},
    {city: "Southport", state: "New York"},
    {city: "Spring Valley", state: "New York"},
    {city: "St. James", state: "New York"},
    {city: "Stillwater", state: "New York"},
    {city: "Stony Brook", state: "New York"},
    {city: "Stony Point", state: "New York"},
    {city: "Suffern", state: "New York"},
    {city: "Suffolk", state: "New York"},
    {city: "Sullivan", state: "New York"},
    {city: "Sweden", state: "New York"},
    {city: "Syosset", state: "New York"},
    {city: "Syracuse", state: "New York"},
    {city: "Tappan", state: "New York"},
    {city: "Tarrytown", state: "New York"},
    {city: "Terryville", state: "New York"},
    {city: "Thompson", state: "New York"},
    {city: "Tonawanda", state: "New York"},
    {city: "Troy", state: "New York"},
    {city: "Tuckahoe", state: "New York"},
    {city: "Ulster", state: "New York"},
    {city: "Union", state: "New York"},
    {city: "Uniondale", state: "New York"},
    {city: "Utica", state: "New York"},
    {city: "Valley Cottage", state: "New York"},
    {city: "Valley Stream", state: "New York"},
    {city: "Van Buren", state: "New York"},
    {city: "Verona", state: "New York"},
    {city: "Vestal", state: "New York"},
    {city: "Victor", state: "New York"},
    {city: "Volney", state: "New York"},
    {city: "Wading River", state: "New York"},
    {city: "Walden", state: "New York"},
    {city: "Wallkill", state: "New York"},
    {city: "Walworth", state: "New York"},
    {city: "Wantagh", state: "New York"},
    {city: "Wappinger", state: "New York"},
    {city: "Warwick", state: "New York"},
    {city: "Waterford", state: "New York"},
    {city: "Waterloo", state: "New York"},
    {city: "Watertown", state: "New York"},
    {city: "Watervliet", state: "New York"},
    {city: "Wawarsing", state: "New York"},
    {city: "Wawayanda", state: "New York"},
    {city: "Webster", state: "New York"},
    {city: "Wellsville", state: "New York"},
    {city: "West Babylon", state: "New York"},
    {city: "West Glens Falls", state: "New York"},
    {city: "West Haverstraw", state: "New York"},
    {city: "West Hempstead", state: "New York"},
    {city: "West Islip", state: "New York"},
    {city: "West Point", state: "New York"},
    {city: "West Seneca", state: "New York"},
    {city: "Westbury", state: "New York"},
    {city: "Westmere", state: "New York"},
    {city: "Westmoreland", state: "New York"},
    {city: "Wheatfield", state: "New York"},
    {city: "White Plains", state: "New York"},
    {city: "Whitestown", state: "New York"},
    {city: "Williamson", state: "New York"},
    {city: "Williston Park", state: "New York"},
    {city: "Wilna", state: "New York"},
    {city: "Wilton", state: "New York"},
    {city: "Windsor", state: "New York"},
    {city: "Woodbury", state: "New York"},
    {city: "Woodmere", state: "New York"},
    {city: "Woodstock", state: "New York"},
    {city: "Wyandanch", state: "New York"},
    {city: "Yonkers", state: "New York"},
    {city: "Yorktown", state: "New York"},
    {city: "Yorktown Heights", state: "New York"},
    {city: "Albemarle", state: "North Carolina"},
    {city: "Apex", state: "North Carolina"},
    {city: "Archdale", state: "North Carolina"},
    {city: "Asheboro", state: "North Carolina"},
    {city: "Asheville", state: "North Carolina"},
    {city: "Belmont", state: "North Carolina"},
    {city: "Black Mountain", state: "North Carolina"},
    {city: "Boone", state: "North Carolina"},
    {city: "Brevard", state: "North Carolina"},
    {city: "Burlington", state: "North Carolina"},
    {city: "Carrboro", state: "North Carolina"},
    {city: "Cary", state: "North Carolina"},
    {city: "Chapel Hill", state: "North Carolina"},
    {city: "Charlotte", state: "North Carolina"},
    {city: "Clayton", state: "North Carolina"},
    {city: "Clemmons", state: "North Carolina"},
    {city: "Clinton", state: "North Carolina"},
    {city: "Concord", state: "North Carolina"},
    {city: "Conover", state: "North Carolina"},
    {city: "Cornelius", state: "North Carolina"},
    {city: "Davidson", state: "North Carolina"},
    {city: "Dunn", state: "North Carolina"},
    {city: "Durham", state: "North Carolina"},
    {city: "Eden", state: "North Carolina"},
    {city: "Elizabeth City", state: "North Carolina"},
    {city: "Elon College", state: "North Carolina"},
    {city: "Fayetteville", state: "North Carolina"},
    {city: "Forest City", state: "North Carolina"},
    {city: "Fort Bragg", state: "North Carolina"},
    {city: "Fuquay-Varina", state: "North Carolina"},
    {city: "Garner", state: "North Carolina"},
    {city: "Gastonia", state: "North Carolina"},
    {city: "Goldsboro", state: "North Carolina"},
    {city: "Graham", state: "North Carolina"},
    {city: "Greensboro", state: "North Carolina"},
    {city: "Greenville", state: "North Carolina"},
    {city: "Half Moon", state: "North Carolina"},
    {city: "Hamlet", state: "North Carolina"},
    {city: "Havelock", state: "North Carolina"},
    {city: "Henderson", state: "North Carolina"},
    {city: "Hendersonville", state: "North Carolina"},
    {city: "Hickory", state: "North Carolina"},
    {city: "High Point", state: "North Carolina"},
    {city: "Holly Springs", state: "North Carolina"},
    {city: "Hope Mills", state: "North Carolina"},
    {city: "Huntersville", state: "North Carolina"},
    {city: "Indian Trail", state: "North Carolina"},
    {city: "Jacksonville", state: "North Carolina"},
    {city: "Kannapolis", state: "North Carolina"},
    {city: "Kernersville", state: "North Carolina"},
    {city: "Kings Grant", state: "North Carolina"},
    {city: "Kings Mountain", state: "North Carolina"},
    {city: "Kinston", state: "North Carolina"},
    {city: "Laurinburg", state: "North Carolina"},
    {city: "Lenoir", state: "North Carolina"},
    {city: "Lewisville", state: "North Carolina"},
    {city: "Lexington", state: "North Carolina"},
    {city: "Lincolnton", state: "North Carolina"},
    {city: "Lumberton", state: "North Carolina"},
    {city: "Masonboro", state: "North Carolina"},
    {city: "Matthews", state: "North Carolina"},
    {city: "Mebane", state: "North Carolina"},
    {city: "Mint Hill", state: "North Carolina"},
    {city: "Monroe", state: "North Carolina"},
    {city: "Mooresville", state: "North Carolina"},
    {city: "Morehead City", state: "North Carolina"},
    {city: "Morganton", state: "North Carolina"},
    {city: "Mount Airy", state: "North Carolina"},
    {city: "Mount Holly", state: "North Carolina"},
    {city: "Murraysville", state: "North Carolina"},
    {city: "Myrtle Grove", state: "North Carolina"},
    {city: "New Bern", state: "North Carolina"},
    {city: "Newton", state: "North Carolina"},
    {city: "Oak Island", state: "North Carolina"},
    {city: "Oxford", state: "North Carolina"},
    {city: "Pinehurst", state: "North Carolina"},
    {city: "Piney Green", state: "North Carolina"},
    {city: "Raleigh", state: "North Carolina"},
    {city: "Reidsville", state: "North Carolina"},
    {city: "Roanoke Rapids", state: "North Carolina"},
    {city: "Rockingham", state: "North Carolina"},
    {city: "Rocky Mount", state: "North Carolina"},
    {city: "Roxboro", state: "North Carolina"},
    {city: "Salisbury", state: "North Carolina"},
    {city: "Sanford", state: "North Carolina"},
    {city: "Shelby", state: "North Carolina"},
    {city: "Siler City", state: "North Carolina"},
    {city: "Smithfield", state: "North Carolina"},
    {city: "Southern Pines", state: "North Carolina"},
    {city: "Spring Lake", state: "North Carolina"},
    {city: "St. Stephens", state: "North Carolina"},
    {city: "Statesville", state: "North Carolina"},
    {city: "Summerfield", state: "North Carolina"},
    {city: "Tarboro", state: "North Carolina"},
    {city: "Thomasville", state: "North Carolina"},
    {city: "Trinity", state: "North Carolina"},
    {city: "Wake Forest", state: "North Carolina"},
    {city: "Washington", state: "North Carolina"},
    {city: "Waynesville", state: "North Carolina"},
    {city: "Weddington", state: "North Carolina"},
    {city: "Wilmington", state: "North Carolina"},
    {city: "Wilson", state: "North Carolina"},
    {city: "Winston-Salem", state: "North Carolina"},
    {city: "Bismarck", state: "North Dakota"},
    {city: "Devils Lake", state: "North Dakota"},
    {city: "Dickinson", state: "North Dakota"},
    {city: "Fargo", state: "North Dakota"},
    {city: "Grand Forks", state: "North Dakota"},
    {city: "Jamestown", state: "North Dakota"},
    {city: "Mandan", state: "North Dakota"},
    {city: "Minot", state: "North Dakota"},
    {city: "Minot AFB", state: "North Dakota"},
    {city: "Valley City", state: "North Dakota"},
    {city: "Wahpeton", state: "North Dakota"},
    {city: "West Fargo", state: "North Dakota"},
    {city: "Williston", state: "North Dakota"},
    {city: "Akron", state: "Ohio"},
    {city: "Alliance", state: "Ohio"},
    {city: "Amherst", state: "Ohio"},
    {city: "Ashland", state: "Ohio"},
    {city: "Ashtabula", state: "Ohio"},
    {city: "Athens", state: "Ohio"},
    {city: "Aurora", state: "Ohio"},
    {city: "Austintown", state: "Ohio"},
    {city: "Avon", state: "Ohio"},
    {city: "Avon Lake", state: "Ohio"},
    {city: "Barberton", state: "Ohio"},
    {city: "Bay Village", state: "Ohio"},
    {city: "Beachwood", state: "Ohio"},
    {city: "Beavercreek", state: "Ohio"},
    {city: "Beckett Ridge", state: "Ohio"},
    {city: "Bedford", state: "Ohio"},
    {city: "Bedford Heights", state: "Ohio"},
    {city: "Bellbrook", state: "Ohio"},
    {city: "Bellefontaine", state: "Ohio"},
    {city: "Bellevue", state: "Ohio"},
    {city: "Belpre", state: "Ohio"},
    {city: "Berea", state: "Ohio"},
    {city: "Bexley", state: "Ohio"},
    {city: "Blacklick Estates", state: "Ohio"},
    {city: "Blue Ash", state: "Ohio"},
    {city: "Boardman", state: "Ohio"},
    {city: "Bowling Green", state: "Ohio"},
    {city: "Brecksville", state: "Ohio"},
    {city: "Bridgetown North", state: "Ohio"},
    {city: "Broadview Heights", state: "Ohio"},
    {city: "Brook Park", state: "Ohio"},
    {city: "Brooklyn", state: "Ohio"},
    {city: "Brunswick", state: "Ohio"},
    {city: "Bryan", state: "Ohio"},
    {city: "Bucyrus", state: "Ohio"},
    {city: "Cambridge", state: "Ohio"},
    {city: "Campbell", state: "Ohio"},
    {city: "Canfield", state: "Ohio"},
    {city: "Canton", state: "Ohio"},
    {city: "Celina", state: "Ohio"},
    {city: "Centerville", state: "Ohio"},
    {city: "Cheviot", state: "Ohio"},
    {city: "Chillicothe", state: "Ohio"},
    {city: "Cincinnati", state: "Ohio"},
    {city: "Circleville", state: "Ohio"},
    {city: "Clayton", state: "Ohio"},
    {city: "Cleveland", state: "Ohio"},
    {city: "Cleveland Heights", state: "Ohio"},
    {city: "Clyde", state: "Ohio"},
    {city: "Columbus", state: "Ohio"},
    {city: "Conneaut", state: "Ohio"},
    {city: "Cortland", state: "Ohio"},
    {city: "Coshocton", state: "Ohio"},
    {city: "Covedale", state: "Ohio"},
    {city: "Cuyahoga Falls", state: "Ohio"},
    {city: "Dayton", state: "Ohio"},
    {city: "Defiance", state: "Ohio"},
    {city: "Delaware", state: "Ohio"},
    {city: "Delphos", state: "Ohio"},
    {city: "Dent", state: "Ohio"},
    {city: "Dover", state: "Ohio"},
    {city: "Dry Run", state: "Ohio"},
    {city: "Dublin", state: "Ohio"},
    {city: "East Cleveland", state: "Ohio"},
    {city: "East Liverpool", state: "Ohio"},
    {city: "Eastlake", state: "Ohio"},
    {city: "Eaton", state: "Ohio"},
    {city: "Elyria", state: "Ohio"},
    {city: "Englewood", state: "Ohio"},
    {city: "Euclid", state: "Ohio"},
    {city: "Fairborn", state: "Ohio"},
    {city: "Fairfield", state: "Ohio"},
    {city: "Fairlawn", state: "Ohio"},
    {city: "Fairview Park", state: "Ohio"},
    {city: "Findlay", state: "Ohio"},
    {city: "Finneytown", state: "Ohio"},
    {city: "Forest Park", state: "Ohio"},
    {city: "Forestville", state: "Ohio"},
    {city: "Fostoria", state: "Ohio"},
    {city: "Franklin", state: "Ohio"},
    {city: "Fremont", state: "Ohio"},
    {city: "Gahanna", state: "Ohio"},
    {city: "Galion", state: "Ohio"},
    {city: "Garfield Heights", state: "Ohio"},
    {city: "Geneva", state: "Ohio"},
    {city: "Girard", state: "Ohio"},
    {city: "Grandview Heights", state: "Ohio"},
    {city: "Green", state: "Ohio"},
    {city: "Greenville", state: "Ohio"},
    {city: "Groesbeck", state: "Ohio"},
    {city: "Grove City", state: "Ohio"},
    {city: "Hamilton", state: "Ohio"},
    {city: "Harrison", state: "Ohio"},
    {city: "Heath", state: "Ohio"},
    {city: "Highland Heights", state: "Ohio"},
    {city: "Hilliard", state: "Ohio"},
    {city: "Hillsboro", state: "Ohio"},
    {city: "Howland Center", state: "Ohio"},
    {city: "Hubbard", state: "Ohio"},
    {city: "Huber Heights", state: "Ohio"},
    {city: "Hudson", state: "Ohio"},
    {city: "Huron", state: "Ohio"},
    {city: "Independence", state: "Ohio"},
    {city: "Ironton", state: "Ohio"},
    {city: "Jackson", state: "Ohio"},
    {city: "Kent", state: "Ohio"},
    {city: "Kenton", state: "Ohio"},
    {city: "Kenwood", state: "Ohio"},
    {city: "Kettering", state: "Ohio"},
    {city: "Kirtland", state: "Ohio"},
    {city: "Lakewood", state: "Ohio"},
    {city: "Lancaster", state: "Ohio"},
    {city: "Landen", state: "Ohio"},
    {city: "Lebanon", state: "Ohio"},
    {city: "Lima", state: "Ohio"},
    {city: "Lincoln Village", state: "Ohio"},
    {city: "Logan", state: "Ohio"},
    {city: "London", state: "Ohio"},
    {city: "Lorain", state: "Ohio"},
    {city: "Louisville", state: "Ohio"},
    {city: "Loveland", state: "Ohio"},
    {city: "Lyndhurst", state: "Ohio"},
    {city: "Macedonia", state: "Ohio"},
    {city: "Madeira", state: "Ohio"},
    {city: "Mansfield", state: "Ohio"},
    {city: "Maple Heights", state: "Ohio"},
    {city: "Marietta", state: "Ohio"},
    {city: "Marion", state: "Ohio"},
    {city: "Martins Ferry", state: "Ohio"},
    {city: "Marysville", state: "Ohio"},
    {city: "Mason", state: "Ohio"},
    {city: "Massillon", state: "Ohio"},
    {city: "Maumee", state: "Ohio"},
    {city: "Mayfield Heights", state: "Ohio"},
    {city: "Medina", state: "Ohio"},
    {city: "Mentor", state: "Ohio"},
    {city: "Mentor-on-the-Lake", state: "Ohio"},
    {city: "Miamisburg", state: "Ohio"},
    {city: "Middleburg Heights", state: "Ohio"},
    {city: "Middletown", state: "Ohio"},
    {city: "Milford", state: "Ohio"},
    {city: "Monroe", state: "Ohio"},
    {city: "Montgomery", state: "Ohio"},
    {city: "Moraine", state: "Ohio"},
    {city: "Mount Healthy", state: "Ohio"},
    {city: "Mount Vernon", state: "Ohio"},
    {city: "Napoleon", state: "Ohio"},
    {city: "New Philadelphia", state: "Ohio"},
    {city: "Newark", state: "Ohio"},
    {city: "Niles", state: "Ohio"},
    {city: "North Canton", state: "Ohio"},
    {city: "North College Hill", state: "Ohio"},
    {city: "North Madison", state: "Ohio"},
    {city: "North Olmsted", state: "Ohio"},
    {city: "North Ridgeville", state: "Ohio"},
    {city: "North Royalton", state: "Ohio"},
    {city: "Northbrook", state: "Ohio"},
    {city: "Northgate", state: "Ohio"},
    {city: "Northridge", state: "Ohio"},
    {city: "Norton", state: "Ohio"},
    {city: "Norwalk", state: "Ohio"},
    {city: "Norwood", state: "Ohio"},
    {city: "Oakwood", state: "Ohio"},
    {city: "Oberlin", state: "Ohio"},
    {city: "Olmsted Falls", state: "Ohio"},
    {city: "Oregon", state: "Ohio"},
    {city: "Orrville", state: "Ohio"},
    {city: "Oxford", state: "Ohio"},
    {city: "Painesville", state: "Ohio"},
    {city: "Parma", state: "Ohio"},
    {city: "Parma Heights", state: "Ohio"},
    {city: "Pataskala", state: "Ohio"},
    {city: "Pepper Pike", state: "Ohio"},
    {city: "Perry Heights", state: "Ohio"},
    {city: "Perrysburg", state: "Ohio"},
    {city: "Pickerington", state: "Ohio"},
    {city: "Piqua", state: "Ohio"},
    {city: "Port Clinton", state: "Ohio"},
    {city: "Portage Lakes", state: "Ohio"},
    {city: "Portsmouth", state: "Ohio"},
    {city: "Powell", state: "Ohio"},
    {city: "Ravenna", state: "Ohio"},
    {city: "Reading", state: "Ohio"},
    {city: "Reynoldsburg", state: "Ohio"},
    {city: "Richmond Heights", state: "Ohio"},
    {city: "Rittman", state: "Ohio"},
    {city: "Riverside", state: "Ohio"},
    {city: "Rocky River", state: "Ohio"},
    {city: "Rossford", state: "Ohio"},
    {city: "Salem", state: "Ohio"},
    {city: "Sandusky", state: "Ohio"},
    {city: "Sandusky South", state: "Ohio"},
    {city: "Seven Hills", state: "Ohio"},
    {city: "Shaker Heights", state: "Ohio"},
    {city: "Sharonville", state: "Ohio"},
    {city: "Sheffield Lake", state: "Ohio"},
    {city: "Shelby", state: "Ohio"},
    {city: "Shiloh", state: "Ohio"},
    {city: "Sidney", state: "Ohio"},
    {city: "Solon", state: "Ohio"},
    {city: "South Euclid", state: "Ohio"},
    {city: "Springboro", state: "Ohio"},
    {city: "Springdale", state: "Ohio"},
    {city: "Springfield", state: "Ohio"},
    {city: "St. Marys", state: "Ohio"},
    {city: "Steubenville", state: "Ohio"},
    {city: "Stow", state: "Ohio"},
    {city: "Streetsboro", state: "Ohio"},
    {city: "Strongsville", state: "Ohio"},
    {city: "Struthers", state: "Ohio"},
    {city: "Sylvania", state: "Ohio"},
    {city: "Tallmadge", state: "Ohio"},
    {city: "Tiffin", state: "Ohio"},
    {city: "Tipp City", state: "Ohio"},
    {city: "Toledo", state: "Ohio"},
    {city: "Trenton", state: "Ohio"},
    {city: "Trotwood", state: "Ohio"},
    {city: "Troy", state: "Ohio"},
    {city: "Twinsburg", state: "Ohio"},
    {city: "University Heights", state: "Ohio"},
    {city: "Upper Arlington", state: "Ohio"},
    {city: "Upper Sandusky", state: "Ohio"},
    {city: "Urbana", state: "Ohio"},
    {city: "Van Wert", state: "Ohio"},
    {city: "Vandalia", state: "Ohio"},
    {city: "Vermilion", state: "Ohio"},
    {city: "Wadsworth", state: "Ohio"},
    {city: "Wapakoneta", state: "Ohio"},
    {city: "Warren", state: "Ohio"},
    {city: "Warrensville Heights", state: "Ohio"},
    {city: "Washington", state: "Ohio"},
    {city: "Wauseon", state: "Ohio"},
    {city: "Wellston", state: "Ohio"},
    {city: "West Carrollton City", state: "Ohio"},
    {city: "Westerville", state: "Ohio"},
    {city: "Westlake", state: "Ohio"},
    {city: "Wheelersburg", state: "Ohio"},
    {city: "White Oak", state: "Ohio"},
    {city: "Whitehall", state: "Ohio"},
    {city: "Wickliffe", state: "Ohio"},
    {city: "Willard", state: "Ohio"},
    {city: "Willoughby", state: "Ohio"},
    {city: "Willoughby Hills", state: "Ohio"},
    {city: "Willowick", state: "Ohio"},
    {city: "Wilmington", state: "Ohio"},
    {city: "Woodbourne-Hyde Park", state: "Ohio"},
    {city: "Wooster", state: "Ohio"},
    {city: "Worthington", state: "Ohio"},
    {city: "Wright-Patterson AFB", state: "Ohio"},
    {city: "Wyoming", state: "Ohio"},
    {city: "Xenia", state: "Ohio"},
    {city: "Youngstown", state: "Ohio"},
    {city: "Zanesville", state: "Ohio"},
    {city: "Ada", state: "Oklahoma"},
    {city: "Altus", state: "Oklahoma"},
    {city: "Anadarko", state: "Oklahoma"},
    {city: "Ardmore", state: "Oklahoma"},
    {city: "Bartlesville", state: "Oklahoma"},
    {city: "Bethany", state: "Oklahoma"},
    {city: "Bixby", state: "Oklahoma"},
    {city: "Blackwell", state: "Oklahoma"},
    {city: "Broken Arrow", state: "Oklahoma"},
    {city: "Chickasha", state: "Oklahoma"},
    {city: "Choctaw", state: "Oklahoma"},
    {city: "Claremore", state: "Oklahoma"},
    {city: "Clinton", state: "Oklahoma"},
    {city: "Coweta", state: "Oklahoma"},
    {city: "Cushing", state: "Oklahoma"},
    {city: "Del City", state: "Oklahoma"},
    {city: "Duncan", state: "Oklahoma"},
    {city: "Durant", state: "Oklahoma"},
    {city: "Edmond", state: "Oklahoma"},
    {city: "El Reno", state: "Oklahoma"},
    {city: "Elk City", state: "Oklahoma"},
    {city: "Enid", state: "Oklahoma"},
    {city: "Glenpool", state: "Oklahoma"},
    {city: "Guthrie", state: "Oklahoma"},
    {city: "Guymon", state: "Oklahoma"},
    {city: "Henryetta", state: "Oklahoma"},
    {city: "Idabel", state: "Oklahoma"},
    {city: "Jenks", state: "Oklahoma"},
    {city: "Lawton", state: "Oklahoma"},
    {city: "McAlester", state: "Oklahoma"},
    {city: "Miami", state: "Oklahoma"},
    {city: "Midwest City", state: "Oklahoma"},
    {city: "Moore", state: "Oklahoma"},
    {city: "Muskogee", state: "Oklahoma"},
    {city: "Mustang", state: "Oklahoma"},
    {city: "Norman", state: "Oklahoma"},
    {city: "Oklahoma City", state: "Oklahoma"},
    {city: "Okmulgee", state: "Oklahoma"},
    {city: "Owasso", state: "Oklahoma"},
    {city: "Pauls Valley", state: "Oklahoma"},
    {city: "Ponca City", state: "Oklahoma"},
    {city: "Poteau", state: "Oklahoma"},
    {city: "Pryor Creek", state: "Oklahoma"},
    {city: "Sallisaw", state: "Oklahoma"},
    {city: "Sand Springs", state: "Oklahoma"},
    {city: "Sapulpa", state: "Oklahoma"},
    {city: "Seminole", state: "Oklahoma"},
    {city: "Shawnee", state: "Oklahoma"},
    {city: "Stillwater", state: "Oklahoma"},
    {city: "Tahlequah", state: "Oklahoma"},
    {city: "Tecumseh", state: "Oklahoma"},
    {city: "The Village", state: "Oklahoma"},
    {city: "Tulsa", state: "Oklahoma"},
    {city: "Vinita", state: "Oklahoma"},
    {city: "Wagoner", state: "Oklahoma"},
    {city: "Warr Acres", state: "Oklahoma"},
    {city: "Weatherford", state: "Oklahoma"},
    {city: "Woodward", state: "Oklahoma"},
    {city: "Yukon", state: "Oklahoma"},
    {city: "Albany", state: "Oregon"},
    {city: "Aloha", state: "Oregon"},
    {city: "Altamont", state: "Oregon"},
    {city: "Ashland", state: "Oregon"},
    {city: "Astoria", state: "Oregon"},
    {city: "Baker City", state: "Oregon"},
    {city: "Beaverton", state: "Oregon"},
    {city: "Bend", state: "Oregon"},
    {city: "Canby", state: "Oregon"},
    {city: "Cedar Hills", state: "Oregon"},
    {city: "Cedar Mill", state: "Oregon"},
    {city: "Central Point", state: "Oregon"},
    {city: "Coos Bay", state: "Oregon"},
    {city: "Cornelius", state: "Oregon"},
    {city: "Corvallis", state: "Oregon"},
    {city: "Cottage Grove", state: "Oregon"},
    {city: "Dallas", state: "Oregon"},
    {city: "Eugene", state: "Oregon"},
    {city: "Fairview", state: "Oregon"},
    {city: "Florence", state: "Oregon"},
    {city: "Forest Grove", state: "Oregon"},
    {city: "Four Corners", state: "Oregon"},
    {city: "Garden Home-Whitford", state: "Oregon"},
    {city: "Gladstone", state: "Oregon"},
    {city: "Grants Pass", state: "Oregon"},
    {city: "Green", state: "Oregon"},
    {city: "Gresham", state: "Oregon"},
    {city: "Hayesville", state: "Oregon"},
    {city: "Hermiston", state: "Oregon"},
    {city: "Hillsboro", state: "Oregon"},
    {city: "Independence", state: "Oregon"},
    {city: "Jennings Lodge", state: "Oregon"},
    {city: "Keizer", state: "Oregon"},
    {city: "Klamath Falls", state: "Oregon"},
    {city: "La Grande", state: "Oregon"},
    {city: "Lake Oswego", state: "Oregon"},
    {city: "Lebanon", state: "Oregon"},
    {city: "Lincoln City", state: "Oregon"},
    {city: "McMinnville", state: "Oregon"},
    {city: "Medford", state: "Oregon"},
    {city: "Milton-Freewater", state: "Oregon"},
    {city: "Milwaukie", state: "Oregon"},
    {city: "Monmouth", state: "Oregon"},
    {city: "Newberg", state: "Oregon"},
    {city: "Newport", state: "Oregon"},
    {city: "North Bend", state: "Oregon"},
    {city: "Oak Grove", state: "Oregon"},
    {city: "Oak Hills", state: "Oregon"},
    {city: "Oatfield", state: "Oregon"},
    {city: "Ontario", state: "Oregon"},
    {city: "Oregon City", state: "Oregon"},
    {city: "Pendleton", state: "Oregon"},
    {city: "Portland", state: "Oregon"},
    {city: "Prineville", state: "Oregon"},
    {city: "Redmond", state: "Oregon"},
    {city: "Rockcreek", state: "Oregon"},
    {city: "Roseburg", state: "Oregon"},
    {city: "Salem", state: "Oregon"},
    {city: "Sherwood", state: "Oregon"},
    {city: "Silverton", state: "Oregon"},
    {city: "Springfield", state: "Oregon"},
    {city: "St. Helens", state: "Oregon"},
    {city: "Stayton", state: "Oregon"},
    {city: "Sunnyside", state: "Oregon"},
    {city: "Sutherlin", state: "Oregon"},
    {city: "Sweet Home", state: "Oregon"},
    {city: "The Dalles", state: "Oregon"},
    {city: "Tigard", state: "Oregon"},
    {city: "Troutdale", state: "Oregon"},
    {city: "Tualatin", state: "Oregon"},
    {city: "West Haven-Sylvan", state: "Oregon"},
    {city: "West Linn", state: "Oregon"},
    {city: "West Slope", state: "Oregon"},
    {city: "Wilsonville", state: "Oregon"},
    {city: "Woodburn", state: "Oregon"},
    {city: "Aliquippa", state: "Pennsylvania"},
    {city: "Allentown", state: "Pennsylvania"},
    {city: "Altoona", state: "Pennsylvania"},
    {city: "Ambler", state: "Pennsylvania"},
    {city: "Ambridge", state: "Pennsylvania"},
    {city: "Archbald", state: "Pennsylvania"},
    {city: "Ardmore", state: "Pennsylvania"},
    {city: "Audubon", state: "Pennsylvania"},
    {city: "Back Mountain", state: "Pennsylvania"},
    {city: "Baldwin", state: "Pennsylvania"},
    {city: "Beaver Falls", state: "Pennsylvania"},
    {city: "Bellefonte", state: "Pennsylvania"},
    {city: "Bellevue", state: "Pennsylvania"},
    {city: "Berwick", state: "Pennsylvania"},
    {city: "Bethel Park", state: "Pennsylvania"},
    {city: "Bethlehem", state: "Pennsylvania"},
    {city: "Blakely", state: "Pennsylvania"},
    {city: "Bloomsburg", state: "Pennsylvania"},
    {city: "Blue Bell", state: "Pennsylvania"},
    {city: "Bradford", state: "Pennsylvania"},
    {city: "Brentwood", state: "Pennsylvania"},
    {city: "Bristol", state: "Pennsylvania"},
    {city: "Brookhaven", state: "Pennsylvania"},
    {city: "Broomall", state: "Pennsylvania"},
    {city: "Butler", state: "Pennsylvania"},
    {city: "Camp Hill", state: "Pennsylvania"},
    {city: "Canonsburg", state: "Pennsylvania"},
    {city: "Carbondale", state: "Pennsylvania"},
    {city: "Carlisle", state: "Pennsylvania"},
    {city: "Carnegie", state: "Pennsylvania"},
    {city: "Carnot-Moon", state: "Pennsylvania"},
    {city: "Castle Shannon", state: "Pennsylvania"},
    {city: "Catasauqua", state: "Pennsylvania"},
    {city: "Chambersburg", state: "Pennsylvania"},
    {city: "Chester", state: "Pennsylvania"},
    {city: "Clairton", state: "Pennsylvania"},
    {city: "Clarion", state: "Pennsylvania"},
    {city: "Clearfield", state: "Pennsylvania"},
    {city: "Clifton Heights", state: "Pennsylvania"},
    {city: "Coatesville", state: "Pennsylvania"},
    {city: "Collegeville", state: "Pennsylvania"},
    {city: "Collingdale", state: "Pennsylvania"},
    {city: "Colonial Park", state: "Pennsylvania"},
    {city: "Columbia", state: "Pennsylvania"},
    {city: "Connellsville", state: "Pennsylvania"},
    {city: "Conshohocken", state: "Pennsylvania"},
    {city: "Coraopolis", state: "Pennsylvania"},
    {city: "Corry", state: "Pennsylvania"},
    {city: "Crafton", state: "Pennsylvania"},
    {city: "Croydon", state: "Pennsylvania"},
    {city: "Darby", state: "Pennsylvania"},
    {city: "Darby Township", state: "Pennsylvania"},
    {city: "Dickson City", state: "Pennsylvania"},
    {city: "Dormont", state: "Pennsylvania"},
    {city: "Downingtown", state: "Pennsylvania"},
    {city: "Doylestown", state: "Pennsylvania"},
    {city: "Drexel Hill", state: "Pennsylvania"},
    {city: "DuBois", state: "Pennsylvania"},
    {city: "Dunmore", state: "Pennsylvania"},
    {city: "Duquesne", state: "Pennsylvania"},
    {city: "East Norriton", state: "Pennsylvania"},
    {city: "East Stroudsburg", state: "Pennsylvania"},
    {city: "East York", state: "Pennsylvania"},
    {city: "Easton", state: "Pennsylvania"},
    {city: "Economy", state: "Pennsylvania"},
    {city: "Edinboro", state: "Pennsylvania"},
    {city: "Elizabethtown", state: "Pennsylvania"},
    {city: "Ellwood City", state: "Pennsylvania"},
    {city: "Emmaus", state: "Pennsylvania"},
    {city: "Ephrata", state: "Pennsylvania"},
    {city: "Erie", state: "Pennsylvania"},
    {city: "Fairless Hills", state: "Pennsylvania"},
    {city: "Farrell", state: "Pennsylvania"},
    {city: "Feasterville-Trevose", state: "Pennsylvania"},
    {city: "Fernway", state: "Pennsylvania"},
    {city: "Folcroft", state: "Pennsylvania"},
    {city: "Folsom", state: "Pennsylvania"},
    {city: "Forest Hills", state: "Pennsylvania"},
    {city: "Franklin", state: "Pennsylvania"},
    {city: "Franklin Park", state: "Pennsylvania"},
    {city: "Fullerton", state: "Pennsylvania"},
    {city: "Gettysburg", state: "Pennsylvania"},
    {city: "Glenolden", state: "Pennsylvania"},
    {city: "Glenside", state: "Pennsylvania"},
    {city: "Greensburg", state: "Pennsylvania"},
    {city: "Greenville", state: "Pennsylvania"},
    {city: "Grove City", state: "Pennsylvania"},
    {city: "Hampton Township", state: "Pennsylvania"},
    {city: "Hanover", state: "Pennsylvania"},
    {city: "Harleysville", state: "Pennsylvania"},
    {city: "Harrisburg", state: "Pennsylvania"},
    {city: "Harrison Township", state: "Pennsylvania"},
    {city: "Hatboro", state: "Pennsylvania"},
    {city: "Hazleton", state: "Pennsylvania"},
    {city: "Hermitage", state: "Pennsylvania"},
    {city: "Hershey", state: "Pennsylvania"},
    {city: "Homeacre-Lyndora", state: "Pennsylvania"},
    {city: "Horsham", state: "Pennsylvania"},
    {city: "Huntingdon", state: "Pennsylvania"},
    {city: "Indiana", state: "Pennsylvania"},
    {city: "Jeannette", state: "Pennsylvania"},
    {city: "Jefferson Hills", state: "Pennsylvania"},
    {city: "Johnstown", state: "Pennsylvania"},
    {city: "Kennedy Township", state: "Pennsylvania"},
    {city: "King of Prussia", state: "Pennsylvania"},
    {city: "Kingston", state: "Pennsylvania"},
    {city: "Kulpsville", state: "Pennsylvania"},
    {city: "Lancaster", state: "Pennsylvania"},
    {city: "Lansdale", state: "Pennsylvania"},
    {city: "Lansdowne", state: "Pennsylvania"},
    {city: "Latrobe", state: "Pennsylvania"},
    {city: "Leacock-Leola-Bareville", state: "Pennsylvania"},
    {city: "Lebanon", state: "Pennsylvania"},
    {city: "Levittown", state: "Pennsylvania"},
    {city: "Lewistown", state: "Pennsylvania"},
    {city: "Linglestown", state: "Pennsylvania"},
    {city: "Lionville-Marchwood", state: "Pennsylvania"},
    {city: "Lititz", state: "Pennsylvania"},
    {city: "Lock Haven", state: "Pennsylvania"},
    {city: "Lower Allen", state: "Pennsylvania"},
    {city: "Lower Burrell", state: "Pennsylvania"},
    {city: "Maple Glen", state: "Pennsylvania"},
    {city: "McCandless Township", state: "Pennsylvania"},
    {city: "McKees Rocks", state: "Pennsylvania"},
    {city: "McKeesport", state: "Pennsylvania"},
    {city: "Meadville", state: "Pennsylvania"},
    {city: "Mechanicsburg", state: "Pennsylvania"},
    {city: "Middletown", state: "Pennsylvania"},
    {city: "Millersville", state: "Pennsylvania"},
    {city: "Milton", state: "Pennsylvania"},
    {city: "Monaca", state: "Pennsylvania"},
    {city: "Monessen", state: "Pennsylvania"},
    {city: "Montgomeryville", state: "Pennsylvania"},
    {city: "Morrisville", state: "Pennsylvania"},
    {city: "Mount Carmel", state: "Pennsylvania"},
    {city: "Mount Joy", state: "Pennsylvania"},
    {city: "Mount Lebanon", state: "Pennsylvania"},
    {city: "Mountain Top", state: "Pennsylvania"},
    {city: "Munhall", state: "Pennsylvania"},
    {city: "Municipality of Monroeville", state: "Pennsylvania"},
    {city: "Municipality of Murrysville", state: "Pennsylvania"},
    {city: "Nanticoke", state: "Pennsylvania"},
    {city: "Nazareth", state: "Pennsylvania"},
    {city: "Nether Providence Township", state: "Pennsylvania"},
    {city: "New Brighton", state: "Pennsylvania"},
    {city: "New Castle", state: "Pennsylvania"},
    {city: "New Cumberland", state: "Pennsylvania"},
    {city: "New Kensington", state: "Pennsylvania"},
    {city: "Norristown", state: "Pennsylvania"},
    {city: "North Braddock", state: "Pennsylvania"},
    {city: "North Versailles", state: "Pennsylvania"},
    {city: "Northampton", state: "Pennsylvania"},
    {city: "Northwest Harborcreek", state: "Pennsylvania"},
    {city: "O’Hara Township", state: "Pennsylvania"},
    {city: "Oakmont", state: "Pennsylvania"},
    {city: "Oil City", state: "Pennsylvania"},
    {city: "Old Forge", state: "Pennsylvania"},
    {city: "Palmyra", state: "Pennsylvania"},
    {city: "Park Forest Village", state: "Pennsylvania"},
    {city: "Parkville", state: "Pennsylvania"},
    {city: "Penn Hills", state: "Pennsylvania"},
    {city: "Perkasie", state: "Pennsylvania"},
    {city: "Philadelphia", state: "Pennsylvania"},
    {city: "Phoenixville", state: "Pennsylvania"},
    {city: "Pittsburgh", state: "Pennsylvania"},
    {city: "Pittston", state: "Pennsylvania"},
    {city: "Pleasant Hills", state: "Pennsylvania"},
    {city: "Plum", state: "Pennsylvania"},
    {city: "Plymouth", state: "Pennsylvania"},
    {city: "Pottstown", state: "Pennsylvania"},
    {city: "Pottsville", state: "Pennsylvania"},
    {city: "Progress", state: "Pennsylvania"},
    {city: "Prospect Park", state: "Pennsylvania"},
    {city: "Punxsutawney", state: "Pennsylvania"},
    {city: "Quakertown", state: "Pennsylvania"},
    {city: "Radnor Township", state: "Pennsylvania"},
    {city: "Reading", state: "Pennsylvania"},
    {city: "Red Lion", state: "Pennsylvania"},
    {city: "Richboro", state: "Pennsylvania"},
    {city: "Ridley Park", state: "Pennsylvania"},
    {city: "Robinson Township", state: "Pennsylvania"},
    {city: "Ross Township", state: "Pennsylvania"},
    {city: "Sanatoga", state: "Pennsylvania"},
    {city: "Scott Township", state: "Pennsylvania"},
    {city: "Scranton", state: "Pennsylvania"},
    {city: "Shaler Township", state: "Pennsylvania"},
    {city: "Shamokin", state: "Pennsylvania"},
    {city: "Sharon", state: "Pennsylvania"},
    {city: "Shiloh", state: "Pennsylvania"},
    {city: "Somerset", state: "Pennsylvania"},
    {city: "Souderton", state: "Pennsylvania"},
    {city: "South Park Township", state: "Pennsylvania"},
    {city: "South Williamsport", state: "Pennsylvania"},
    {city: "Springfield", state: "Pennsylvania"},
    {city: "St. Marys", state: "Pennsylvania"},
    {city: "State College", state: "Pennsylvania"},
    {city: "Stowe Township", state: "Pennsylvania"},
    {city: "Sunbury", state: "Pennsylvania"},
    {city: "Swarthmore", state: "Pennsylvania"},
    {city: "Swissvale", state: "Pennsylvania"},
    {city: "Tamaqua", state: "Pennsylvania"},
    {city: "Taylor", state: "Pennsylvania"},
    {city: "Titusville", state: "Pennsylvania"},
    {city: "Trooper", state: "Pennsylvania"},
    {city: "Turtle Creek", state: "Pennsylvania"},
    {city: "Uniontown", state: "Pennsylvania"},
    {city: "Upper Providence Township", state: "Pennsylvania"},
    {city: "Upper St. Clair", state: "Pennsylvania"},
    {city: "Village Green-Green Ridge", state: "Pennsylvania"},
    {city: "Warren", state: "Pennsylvania"},
    {city: "Washington", state: "Pennsylvania"},
    {city: "Waynesboro", state: "Pennsylvania"},
    {city: "Weigelstown", state: "Pennsylvania"},
    {city: "West Chester", state: "Pennsylvania"},
    {city: "West Goshen", state: "Pennsylvania"},
    {city: "West Mifflin", state: "Pennsylvania"},
    {city: "West Norriton", state: "Pennsylvania"},
    {city: "West View", state: "Pennsylvania"},
    {city: "White Oak", state: "Pennsylvania"},
    {city: "Whitehall", state: "Pennsylvania"},
    {city: "Wilkes-Barre", state: "Pennsylvania"},
    {city: "Wilkins Township", state: "Pennsylvania"},
    {city: "Wilkinsburg", state: "Pennsylvania"},
    {city: "Williamsport", state: "Pennsylvania"},
    {city: "Willow Grove", state: "Pennsylvania"},
    {city: "Willow Street", state: "Pennsylvania"},
    {city: "Wilson", state: "Pennsylvania"},
    {city: "Woodlyn", state: "Pennsylvania"},
    {city: "Wyomissing", state: "Pennsylvania"},
    {city: "Yeadon", state: "Pennsylvania"},
    {city: "York", state: "Pennsylvania"},
    {city: "Barrington", state: "Rhode Island"},
    {city: "Bristol", state: "Rhode Island"},
    {city: "Burrillville", state: "Rhode Island"},
    {city: "Central Falls", state: "Rhode Island"},
    {city: "Charlestown", state: "Rhode Island"},
    {city: "Coventry", state: "Rhode Island"},
    {city: "Cranston", state: "Rhode Island"},
    {city: "Cumberland", state: "Rhode Island"},
    {city: "Cumberland Hill", state: "Rhode Island"},
    {city: "East Greenwich", state: "Rhode Island"},
    {city: "East Providence", state: "Rhode Island"},
    {city: "Exeter", state: "Rhode Island"},
    {city: "Glocester", state: "Rhode Island"},
    {city: "Greenville", state: "Rhode Island"},
    {city: "Hopkinton", state: "Rhode Island"},
    {city: "Johnston", state: "Rhode Island"},
    {city: "Lincoln", state: "Rhode Island"},
    {city: "Middletown", state: "Rhode Island"},
    {city: "Narragansett", state: "Rhode Island"},
    {city: "Newport", state: "Rhode Island"},
    {city: "Newport East", state: "Rhode Island"},
    {city: "North Kingstown", state: "Rhode Island"},
    {city: "North Providence", state: "Rhode Island"},
    {city: "North Smithfield", state: "Rhode Island"},
    {city: "Pawtucket", state: "Rhode Island"},
    {city: "Portsmouth", state: "Rhode Island"},
    {city: "Providence", state: "Rhode Island"},
    {city: "Richmond", state: "Rhode Island"},
    {city: "Scituate", state: "Rhode Island"},
    {city: "Smithfield", state: "Rhode Island"},
    {city: "South Kingstown", state: "Rhode Island"},
    {city: "Tiverton", state: "Rhode Island"},
    {city: "Valley Falls", state: "Rhode Island"},
    {city: "Wakefield-Peacedale", state: "Rhode Island"},
    {city: "Warren", state: "Rhode Island"},
    {city: "Warwick", state: "Rhode Island"},
    {city: "West Warwick", state: "Rhode Island"},
    {city: "Westerly", state: "Rhode Island"},
    {city: "Woonsocket", state: "Rhode Island"},
    {city: "Aiken", state: "South Carolina"},
    {city: "Anderson", state: "South Carolina"},
    {city: "Beaufort", state: "South Carolina"},
    {city: "Bennettsville", state: "South Carolina"},
    {city: "Berea", state: "South Carolina"},
    {city: "Burton", state: "South Carolina"},
    {city: "Camden", state: "South Carolina"},
    {city: "Cayce", state: "South Carolina"},
    {city: "Charleston", state: "South Carolina"},
    {city: "Chester", state: "South Carolina"},
    {city: "Clemson", state: "South Carolina"},
    {city: "Clinton", state: "South Carolina"},
    {city: "Columbia", state: "South Carolina"},
    {city: "Conway", state: "South Carolina"},
    {city: "Darlington", state: "South Carolina"},
    {city: "Dentsville", state: "South Carolina"},
    {city: "Dillon", state: "South Carolina"},
    {city: "Easley", state: "South Carolina"},
    {city: "Five Forks", state: "South Carolina"},
    {city: "Florence", state: "South Carolina"},
    {city: "Forest Acres", state: "South Carolina"},
    {city: "Fort Mill", state: "South Carolina"},
    {city: "Fountain Inn", state: "South Carolina"},
    {city: "Gaffney", state: "South Carolina"},
    {city: "Gantt", state: "South Carolina"},
    {city: "Garden City", state: "South Carolina"},
    {city: "Georgetown", state: "South Carolina"},
    {city: "Georgetown County", state: "South Carolina"},
    {city: "Goose Creek", state: "South Carolina"},
    {city: "Greenville", state: "South Carolina"},
    {city: "Greenwood", state: "South Carolina"},
    {city: "Greer", state: "South Carolina"},
    {city: "Hanahan", state: "South Carolina"},
    {city: "Hartsville", state: "South Carolina"},
    {city: "Hilton Head Island", state: "South Carolina"},
    {city: "Bluffton", state: "South Carolina"},
    {city: "Port Royal", state: "South Carolina"},
    {city: "Homeland Park", state: "South Carolina"},
    {city: "Irmo", state: "South Carolina"},
    {city: "Ladson", state: "South Carolina"},
    {city: "Lake City", state: "South Carolina"},
    {city: "Lancaster", state: "South Carolina"},
    {city: "Laurel Bay", state: "South Carolina"},
    {city: "Laurens", state: "South Carolina"},
    {city: "Lexington", state: "South Carolina"},
    {city: "Little River", state: "South Carolina"},
    {city: "Lugoff", state: "South Carolina"},
    {city: "Marion", state: "South Carolina"},
    {city: "Mauldin", state: "South Carolina"},
    {city: "Mount Pleasant", state: "South Carolina"},
    {city: "Myrtle Beach", state: "South Carolina"},
    {city: "Newberry", state: "South Carolina"},
    {city: "North Augusta", state: "South Carolina"},
    {city: "North Charleston", state: "South Carolina"},
    {city: "North Myrtle Beach", state: "South Carolina"},
    {city: "Oak Grove", state: "South Carolina"},
    {city: "Orangeburg", state: "South Carolina"},
    {city: "Parker", state: "South Carolina"},
    {city: "Red Bank", state: "South Carolina"},
    {city: "Red Hill", state: "South Carolina"},
    {city: "Rock Hill", state: "South Carolina"},
    {city: "Sans Souci", state: "South Carolina"},
    {city: "Seneca", state: "South Carolina"},
    {city: "Seven Oaks", state: "South Carolina"},
    {city: "Simpsonville", state: "South Carolina"},
    {city: "Socastee", state: "South Carolina"},
    {city: "Spartanburg", state: "South Carolina"},
    {city: "St. Andrews", state: "South Carolina"},
    {city: "Summerville", state: "South Carolina"},
    {city: "Sumter", state: "South Carolina"},
    {city: "Taylors", state: "South Carolina"},
    {city: "Union", state: "South Carolina"},
    {city: "Wade Hampton", state: "South Carolina"},
    {city: "Welcome", state: "South Carolina"},
    {city: "West Columbia", state: "South Carolina"},
    {city: "Woodfield", state: "South Carolina"},
    {city: "York", state: "South Carolina"},
    {city: "Aberdeen", state: "South Dakota"},
    {city: "Brookings", state: "South Dakota"},
    {city: "Huron", state: "South Dakota"},
    {city: "Madison", state: "South Dakota"},
    {city: "Mitchell", state: "South Dakota"},
    {city: "Pierre", state: "South Dakota"},
    {city: "Rapid City", state: "South Dakota"},
    {city: "Rapid Valley", state: "South Dakota"},
    {city: "Sioux Falls", state: "South Dakota"},
    {city: "Spearfish", state: "South Dakota"},
    {city: "Sturgis", state: "South Dakota"},
    {city: "Vermillion", state: "South Dakota"},
    {city: "Watertown", state: "South Dakota"},
    {city: "Yankton", state: "South Dakota"},
    {city: "Alcoa", state: "Tennessee"},
    {city: "Athens", state: "Tennessee"},
    {city: "Bartlett", state: "Tennessee"},
    {city: "Bloomingdale", state: "Tennessee"},
    {city: "Brentwood", state: "Tennessee"},
    {city: "Bristol", state: "Tennessee"},
    {city: "Brownsville", state: "Tennessee"},
    {city: "Chattanooga", state: "Tennessee"},
    {city: "Clarksville", state: "Tennessee"},
    {city: "Cleveland", state: "Tennessee"},
    {city: "Clinton", state: "Tennessee"},
    {city: "Collegedale", state: "Tennessee"},
    {city: "Collierville", state: "Tennessee"},
    {city: "Colonial Heights", state: "Tennessee"},
    {city: "Columbia", state: "Tennessee"},
    {city: "Cookeville", state: "Tennessee"},
    {city: "Covington", state: "Tennessee"},
    {city: "Crossville", state: "Tennessee"},
    {city: "Dayton", state: "Tennessee"},
    {city: "Dickson", state: "Tennessee"},
    {city: "Dyersburg", state: "Tennessee"},
    {city: "East Brainerd", state: "Tennessee"},
    {city: "East Ridge", state: "Tennessee"},
    {city: "Elizabethton", state: "Tennessee"},
    {city: "Farragut", state: "Tennessee"},
    {city: "Fayetteville", state: "Tennessee"},
    {city: "Franklin", state: "Tennessee"},
    {city: "Gallatin", state: "Tennessee"},
    {city: "Germantown", state: "Tennessee"},
    {city: "Goodlettsville", state: "Tennessee"},
    {city: "Green Hill", state: "Tennessee"},
    {city: "Greeneville", state: "Tennessee"},
    {city: "Harriman", state: "Tennessee"},
    {city: "Harrison", state: "Tennessee"},
    {city: "Hendersonville", state: "Tennessee"},
    {city: "Humboldt", state: "Tennessee"},
    {city: "Jackson", state: "Tennessee"},
    {city: "Jefferson City", state: "Tennessee"},
    {city: "Johnson City", state: "Tennessee"},
    {city: "Kingsport", state: "Tennessee"},
    {city: "Knoxville", state: "Tennessee"},
    {city: "La Follette", state: "Tennessee"},
    {city: "La Vergne", state: "Tennessee"},
    {city: "Lakeland", state: "Tennessee"},
    {city: "Lawrenceburg", state: "Tennessee"},
    {city: "Lebanon", state: "Tennessee"},
    {city: "Lenoir City", state: "Tennessee"},
    {city: "Lewisburg", state: "Tennessee"},
    {city: "Lexington", state: "Tennessee"},
    {city: "Manchester", state: "Tennessee"},
    {city: "Martin", state: "Tennessee"},
    {city: "Maryville", state: "Tennessee"},
    {city: "McMinnville", state: "Tennessee"},
    {city: "Memphis", state: "Tennessee"},
    {city: "Middle Valley", state: "Tennessee"},
    {city: "Milan", state: "Tennessee"},
    {city: "Millington", state: "Tennessee"},
    {city: "Morristown", state: "Tennessee"},
    {city: "Mount Juliet", state: "Tennessee"},
    {city: "Murfreesboro", state: "Tennessee"},
    {city: "Nashville", state: "Tennessee"},
    {city: "Newport", state: "Tennessee"},
    {city: "Oak Ridge", state: "Tennessee"},
    {city: "Paris", state: "Tennessee"},
    {city: "Portland", state: "Tennessee"},
    {city: "Pulaski", state: "Tennessee"},
    {city: "Red Bank", state: "Tennessee"},
    {city: "Ripley", state: "Tennessee"},
    {city: "Savannah", state: "Tennessee"},
    {city: "Sevierville", state: "Tennessee"},
    {city: "Seymour", state: "Tennessee"},
    {city: "Shelbyville", state: "Tennessee"},
    {city: "Signal Mountain", state: "Tennessee"},
    {city: "Smyrna", state: "Tennessee"},
    {city: "Soddy-Daisy", state: "Tennessee"},
    {city: "South Cleveland", state: "Tennessee"},
    {city: "Spring Hill", state: "Tennessee"},
    {city: "Springfield", state: "Tennessee"},
    {city: "Tullahoma", state: "Tennessee"},
    {city: "Union City", state: "Tennessee"},
    {city: "White House", state: "Tennessee"},
    {city: "Winchester", state: "Tennessee"},
    {city: "Abilene", state: "Texas"},
    {city: "Addison", state: "Texas"},
    {city: "Alamo", state: "Texas"},
    {city: "Alamo Heights", state: "Texas"},
    {city: "Aldine", state: "Texas"},
    {city: "Alice", state: "Texas"},
    {city: "Allen", state: "Texas"},
    {city: "Alvin", state: "Texas"},
    {city: "Amarillo", state: "Texas"},
    {city: "Anderson Mill", state: "Texas"},
    {city: "Andrews", state: "Texas"},
    {city: "Angleton", state: "Texas"},
    {city: "Aransas Pass", state: "Texas"},
    {city: "Arlington", state: "Texas"},
    {city: "Atascocita", state: "Texas"},
    {city: "Athens", state: "Texas"},
    {city: "Austin", state: "Texas"},
    {city: "Azle", state: "Texas"},
    {city: "Bacliff", state: "Texas"},
    {city: "Balch Springs", state: "Texas"},
    {city: "Bay City", state: "Texas"},
    {city: "Baytown", state: "Texas"},
    {city: "Beaumont", state: "Texas"},
    {city: "Bedford", state: "Texas"},
    {city: "Beeville", state: "Texas"},
    {city: "Bellaire", state: "Texas"},
    {city: "Bellmead", state: "Texas"},
    {city: "Belton", state: "Texas"},
    {city: "Benbrook", state: "Texas"},
    {city: "Big Spring", state: "Texas"},
    {city: "Boerne", state: "Texas"},
    {city: "Bonham", state: "Texas"},
    {city: "Borger", state: "Texas"},
    {city: "Brazoria", state: "Texas"},
    {city: "Brenham", state: "Texas"},
    {city: "Bridge City", state: "Texas"},
    {city: "Brownfield", state: "Texas"},
    {city: "Brownsville", state: "Texas"},
    {city: "Brownwood", state: "Texas"},
    {city: "Brushy Creek", state: "Texas"},
    {city: "Bryan", state: "Texas"},
    {city: "Burkburnett", state: "Texas"},
    {city: "Burleson", state: "Texas"},
    {city: "Canyon", state: "Texas"},
    {city: "Canyon Lake", state: "Texas"},
    {city: "Carrollton", state: "Texas"},
    {city: "Carthage", state: "Texas"},
    {city: "Cedar Hill", state: "Texas"},
    {city: "Cedar Park", state: "Texas"},
    {city: "Channelview", state: "Texas"},
    {city: "Childress", state: "Texas"},
    {city: "Cinco Ranch", state: "Texas"},
    {city: "Cleburne", state: "Texas"},
    {city: "Cleveland", state: "Texas"},
    {city: "Cloverleaf", state: "Texas"},
    {city: "Clute", state: "Texas"},
    {city: "College Station", state: "Texas"},
    {city: "Colleyville", state: "Texas"},
    {city: "Commerce", state: "Texas"},
    {city: "Conroe", state: "Texas"},
    {city: "Converse", state: "Texas"},
    {city: "Coppell", state: "Texas"},
    {city: "Copperas Cove", state: "Texas"},
    {city: "Corinth", state: "Texas"},
    {city: "Corpus Christi", state: "Texas"},
    {city: "Corsicana", state: "Texas"},
    {city: "Crockett", state: "Texas"},
    {city: "Crowley", state: "Texas"},
    {city: "Crystal City", state: "Texas"},
    {city: "Cuero", state: "Texas"},
    {city: "Dalhart", state: "Texas"},
    {city: "Dallas", state: "Texas"},
    {city: "Deer Park", state: "Texas"},
    {city: "Del Rio", state: "Texas"},
    {city: "Denison", state: "Texas"},
    {city: "Denton", state: "Texas"},
    {city: "DeSoto", state: "Texas"},
    {city: "Dickinson", state: "Texas"},
    {city: "Donna", state: "Texas"},
    {city: "Dumas", state: "Texas"},
    {city: "Duncanville", state: "Texas"},
    {city: "Eagle Mountain", state: "Texas"},
    {city: "Eagle Pass", state: "Texas"},
    {city: "Edinburg", state: "Texas"},
    {city: "Eidson Road", state: "Texas"},
    {city: "El Campo", state: "Texas"},
    {city: "El Paso", state: "Texas"},
    {city: "Ennis", state: "Texas"},
    {city: "Euless", state: "Texas"},
    {city: "Fabens", state: "Texas"},
    {city: "Farmers Branch", state: "Texas"},
    {city: "Flower Mound", state: "Texas"},
    {city: "Forest Hill", state: "Texas"},
    {city: "Fort Bliss", state: "Texas"},
    {city: "Fort Hood", state: "Texas"},
    {city: "Fort Stockton", state: "Texas"},
    {city: "Fort Worth", state: "Texas"},
    {city: "Fredericksburg", state: "Texas"},
    {city: "Freeport", state: "Texas"},
    {city: "Fresno", state: "Texas"},
    {city: "Friendswood", state: "Texas"},
    {city: "Frisco", state: "Texas"},
    {city: "Gainesville", state: "Texas"},
    {city: "Galena Park", state: "Texas"},
    {city: "Galveston", state: "Texas"},
    {city: "Garland", state: "Texas"},
    {city: "Gatesville", state: "Texas"},
    {city: "Georgetown", state: "Texas"},
    {city: "Gladewater", state: "Texas"},
    {city: "Glenn Heights", state: "Texas"},
    {city: "Gonzales", state: "Texas"},
    {city: "Graham", state: "Texas"},
    {city: "Grand Prairie", state: "Texas"},
    {city: "Grapevine", state: "Texas"},
    {city: "Greatwood", state: "Texas"},
    {city: "Greenville", state: "Texas"},
    {city: "Groves", state: "Texas"},
    {city: "Haltom City", state: "Texas"},
    {city: "Harker Heights", state: "Texas"},
    {city: "Harlingen", state: "Texas"},
    {city: "Henderson", state: "Texas"},
    {city: "Hereford", state: "Texas"},
    {city: "Hewitt", state: "Texas"},
    {city: "Hidalgo", state: "Texas"},
    {city: "Highland Park", state: "Texas"},
    {city: "Highland Village", state: "Texas"},
    {city: "Highlands", state: "Texas"},
    {city: "Hillsboro", state: "Texas"},
    {city: "Hitchcock", state: "Texas"},
    {city: "Homestead Meadows South", state: "Texas"},
    {city: "Hondo", state: "Texas"},
    {city: "Houston", state: "Texas"},
    {city: "Humble", state: "Texas"},
    {city: "Huntsville", state: "Texas"},
    {city: "Hurst", state: "Texas"},
    {city: "Ingleside", state: "Texas"},
    {city: "Iowa Park", state: "Texas"},
    {city: "Irving", state: "Texas"},
    {city: "Jacinto City", state: "Texas"},
    {city: "Jacksonville", state: "Texas"},
    {city: "Jasper", state: "Texas"},
    {city: "Jersey Village", state: "Texas"},
    {city: "Jollyville", state: "Texas"},
    {city: "Katy", state: "Texas"},
    {city: "Kaufman", state: "Texas"},
    {city: "Keller", state: "Texas"},
    {city: "Kerrville", state: "Texas"},
    {city: "Kilgore", state: "Texas"},
    {city: "Killeen", state: "Texas"},
    {city: "Kingsville", state: "Texas"},
    {city: "Kirby", state: "Texas"},
    {city: "La Feria", state: "Texas"},
    {city: "La Homa", state: "Texas"},
    {city: "La Marque", state: "Texas"},
    {city: "La Porte", state: "Texas"},
    {city: "Lackland AFB", state: "Texas"},
    {city: "Lake Dallas", state: "Texas"},
    {city: "Lake Jackson", state: "Texas"},
    {city: "Lakeway", state: "Texas"},
    {city: "Lamesa", state: "Texas"},
    {city: "Lampasas", state: "Texas"},
    {city: "Lancaster", state: "Texas"},
    {city: "Laredo", state: "Texas"},
    {city: "League City", state: "Texas"},
    {city: "Leander", state: "Texas"},
    {city: "Leon Valley", state: "Texas"},
    {city: "Levelland", state: "Texas"},
    {city: "Lewisville", state: "Texas"},
    {city: "Liberty", state: "Texas"},
    {city: "Littlefield", state: "Texas"},
    {city: "Live Oak", state: "Texas"},
    {city: "Lockhart", state: "Texas"},
    {city: "Longview", state: "Texas"},
    {city: "Lubbock", state: "Texas"},
    {city: "Lufkin", state: "Texas"},
    {city: "Lumberton", state: "Texas"},
    {city: "Mansfield", state: "Texas"},
    {city: "Marlin", state: "Texas"},
    {city: "Marshall", state: "Texas"},
    {city: "McAllen", state: "Texas"},
    {city: "McKinney", state: "Texas"},
    {city: "Mercedes", state: "Texas"},
    {city: "Mesquite", state: "Texas"},
    {city: "Mexia", state: "Texas"},
    {city: "Midland", state: "Texas"},
    {city: "Midlothian", state: "Texas"},
    {city: "Mineral Wells", state: "Texas"},
    {city: "Mission", state: "Texas"},
    {city: "Mission Bend", state: "Texas"},
    {city: "Missouri City", state: "Texas"},
    {city: "Monahans", state: "Texas"},
    {city: "Mount Pleasant", state: "Texas"},
    {city: "Nacogdoches", state: "Texas"},
    {city: "Navasota", state: "Texas"},
    {city: "Nederland", state: "Texas"},
    {city: "New Braunfels", state: "Texas"},
    {city: "New Territory", state: "Texas"},
    {city: "North Richland Hills", state: "Texas"},
    {city: "Odessa", state: "Texas"},
    {city: "Orange", state: "Texas"},
    {city: "Palestine", state: "Texas"},
    {city: "Palmview South", state: "Texas"},
    {city: "Pampa", state: "Texas"},
    {city: "Paris", state: "Texas"},
    {city: "Pasadena", state: "Texas"},
    {city: "Pearland", state: "Texas"},
    {city: "Pearsall", state: "Texas"},
    {city: "Pecan Grove", state: "Texas"},
    {city: "Pecos", state: "Texas"},
    {city: "Perryton", state: "Texas"},
    {city: "Pflugerville", state: "Texas"},
    {city: "Pharr", state: "Texas"},
    {city: "Plainview", state: "Texas"},
    {city: "Plano", state: "Texas"},
    {city: "Pleasanton", state: "Texas"},
    {city: "Port Arthur", state: "Texas"},
    {city: "Port Lavaca", state: "Texas"},
    {city: "Port Neches", state: "Texas"},
    {city: "Portland", state: "Texas"},
    {city: "Raymondville", state: "Texas"},
    {city: "Rendon", state: "Texas"},
    {city: "Richardson", state: "Texas"},
    {city: "Richland Hills", state: "Texas"},
    {city: "Richmond", state: "Texas"},
    {city: "Rio Grande City", state: "Texas"},
    {city: "River Oaks", state: "Texas"},
    {city: "Robinson", state: "Texas"},
    {city: "Robstown", state: "Texas"},
    {city: "Rockport", state: "Texas"},
    {city: "Rockwall", state: "Texas"},
    {city: "Roma", state: "Texas"},
    {city: "Rosenberg", state: "Texas"},
    {city: "Round Rock", state: "Texas"},
    {city: "Rowlett", state: "Texas"},
    {city: "Sachse", state: "Texas"},
    {city: "Saginaw", state: "Texas"},
    {city: "San Angelo", state: "Texas"},
    {city: "San Antonio", state: "Texas"},
    {city: "San Benito", state: "Texas"},
    {city: "San Elizario", state: "Texas"},
    {city: "San Juan", state: "Texas"},
    {city: "San Marcos", state: "Texas"},
    {city: "Santa Fe", state: "Texas"},
    {city: "Schertz", state: "Texas"},
    {city: "Seabrook", state: "Texas"},
    {city: "Seagoville", state: "Texas"},
    {city: "Seguin", state: "Texas"},
    {city: "Sherman", state: "Texas"},
    {city: "Silsbee", state: "Texas"},
    {city: "Slaton", state: "Texas"},
    {city: "Snyder", state: "Texas"},
    {city: "Socorro", state: "Texas"},
    {city: "South Houston", state: "Texas"},
    {city: "Southlake", state: "Texas"},
    {city: "Spring", state: "Texas"},
    {city: "Stafford", state: "Texas"},
    {city: "Stephenville", state: "Texas"},
    {city: "Sugar Land", state: "Texas"},
    {city: "Sulphur Springs", state: "Texas"},
    {city: "Sweetwater", state: "Texas"},
    {city: "Taylor", state: "Texas"},
    {city: "Temple", state: "Texas"},
    {city: "Terrell", state: "Texas"},
    {city: "Texarkana", state: "Texas"},
    {city: "Texas City", state: "Texas"},
    {city: "The Colony", state: "Texas"},
    {city: "The Woodlands", state: "Texas"},
    {city: "Tomball", state: "Texas"},
    {city: "Trophy Club", state: "Texas"},
    {city: "Tyler", state: "Texas"},
    {city: "Universal City", state: "Texas"},
    {city: "University Park", state: "Texas"},
    {city: "Uvalde", state: "Texas"},
    {city: "Vernon", state: "Texas"},
    {city: "Victoria", state: "Texas"},
    {city: "Vidor", state: "Texas"},
    {city: "Waco", state: "Texas"},
    {city: "Watauga", state: "Texas"},
    {city: "Waxahachie", state: "Texas"},
    {city: "Weatherford", state: "Texas"},
    {city: "Webster", state: "Texas"},
    {city: "Wells Branch", state: "Texas"},
    {city: "Weslaco", state: "Texas"},
    {city: "West Livingston", state: "Texas"},
    {city: "West Odessa", state: "Texas"},
    {city: "West University Place", state: "Texas"},
    {city: "Wharton", state: "Texas"},
    {city: "White Settlement", state: "Texas"},
    {city: "Wichita Falls", state: "Texas"},
    {city: "Windemere", state: "Texas"},
    {city: "Woodway", state: "Texas"},
    {city: "Wylie", state: "Texas"},
    {city: "Alpine", state: "Utah"},
    {city: "American Fork", state: "Utah"},
    {city: "Bountiful", state: "Utah"},
    {city: "Brigham City", state: "Utah"},
    {city: "Canyon Rim", state: "Utah"},
    {city: "Cedar City", state: "Utah"},
    {city: "Centerville", state: "Utah"},
    {city: "Clearfield", state: "Utah"},
    {city: "Clinton", state: "Utah"},
    {city: "Cottonwood Heights", state: "Utah"},
    {city: "Cottonwood West", state: "Utah"},
    {city: "Draper", state: "Utah"},
    {city: "East Millcreek", state: "Utah"},
    {city: "Farmington", state: "Utah"},
    {city: "Grantsville", state: "Utah"},
    {city: "Heber", state: "Utah"},
    {city: "Highland", state: "Utah"},
    {city: "Holladay", state: "Utah"},
    {city: "Hurricane", state: "Utah"},
    {city: "Hyrum", state: "Utah"},
    {city: "Kaysville", state: "Utah"},
    {city: "Kearns", state: "Utah"},
    {city: "Layton", state: "Utah"},
    {city: "Lehi", state: "Utah"},
    {city: "Lindon", state: "Utah"},
    {city: "Little Cottonwood Creek Valley", state: "Utah"},
    {city: "Logan", state: "Utah"},
    {city: "Magna", state: "Utah"},
    {city: "Midvale", state: "Utah"},
    {city: "Millcreek", state: "Utah"},
    {city: "Mount Olympus", state: "Utah"},
    {city: "Murray", state: "Utah"},
    {city: "North Logan", state: "Utah"},
    {city: "North Ogden", state: "Utah"},
    {city: "North Salt Lake", state: "Utah"},
    {city: "Ogden", state: "Utah"},
    {city: "Oquirrh", state: "Utah"},
    {city: "Orem", state: "Utah"},
    {city: "Park City", state: "Utah"},
    {city: "Payson", state: "Utah"},
    {city: "Pleasant Grove", state: "Utah"},
    {city: "Price", state: "Utah"},
    {city: "Provo", state: "Utah"},
    {city: "Richfield", state: "Utah"},
    {city: "Riverdale", state: "Utah"},
    {city: "Riverton", state: "Utah"},
    {city: "Roy", state: "Utah"},
    {city: "Salt Lake City", state: "Utah"},
    {city: "Sandy", state: "Utah"},
    {city: "Smithfield", state: "Utah"},
    {city: "South Jordan", state: "Utah"},
    {city: "South Ogden", state: "Utah"},
    {city: "South Salt Lake", state: "Utah"},
    {city: "Spanish Fork", state: "Utah"},
    {city: "Springville", state: "Utah"},
    {city: "St. George", state: "Utah"},
    {city: "Summit Park", state: "Utah"},
    {city: "Syracuse", state: "Utah"},
    {city: "Taylorsville", state: "Utah"},
    {city: "Tooele", state: "Utah"},
    {city: "Vernal", state: "Utah"},
    {city: "Washington", state: "Utah"},
    {city: "Washington Terrace", state: "Utah"},
    {city: "West Jordan", state: "Utah"},
    {city: "West Point", state: "Utah"},
    {city: "West Valley City", state: "Utah"},
    {city: "Woods Cross", state: "Utah"},
    {city: "Barre", state: "Vermont"},
    {city: "Bennington", state: "Vermont"},
    {city: "Brattleboro", state: "Vermont"},
    {city: "Burlington", state: "Vermont"},
    {city: "Colchester", state: "Vermont"},
    {city: "Essex", state: "Vermont"},
    {city: "Essex Junction", state: "Vermont"},
    {city: "Hartford", state: "Vermont"},
    {city: "Middlebury", state: "Vermont"},
    {city: "Milton", state: "Vermont"},
    {city: "Montpelier", state: "Vermont"},
    {city: "Newport", state: "Vermont"},
    {city: "Rutland", state: "Vermont"},
    {city: "Shelburne", state: "Vermont"},
    {city: "South Burlington", state: "Vermont"},
    {city: "Springfield", state: "Vermont"},
    {city: "St. Albans", state: "Vermont"},
    {city: "St. Johnsbury", state: "Vermont"},
    {city: "Swanton", state: "Vermont"},
    {city: "Williston", state: "Vermont"},
    {city: "Winooski", state: "Vermont"},
    {city: "Abingdon", state: "Virginia"},
    {city: "Alexandria", state: "Virginia"},
    {city: "Annandale", state: "Virginia"},
    {city: "Aquia Harbour", state: "Virginia"},
    {city: "Arlington", state: "Virginia"},
    {city: "Ashland", state: "Virginia"},
    {city: "Bailey’s Crossroads", state: "Virginia"},
    {city: "Bedford", state: "Virginia"},
    {city: "Belle Haven", state: "Virginia"},
    {city: "Blacksburg", state: "Virginia"},
    {city: "Bon Air", state: "Virginia"},
    {city: "Bristol", state: "Virginia"},
    {city: "Buena Vista", state: "Virginia"},
    {city: "Bull Run", state: "Virginia"},
    {city: "Burke", state: "Virginia"},
    {city: "Cave Spring", state: "Virginia"},
    {city: "Centreville", state: "Virginia"},
    {city: "Chantilly", state: "Virginia"},
    {city: "Charlottesville", state: "Virginia"},
    {city: "Chesapeake", state: "Virginia"},
    {city: "Chester", state: "Virginia"},
    {city: "Christiansburg", state: "Virginia"},
    {city: "Collinsville", state: "Virginia"},
    {city: "Colonial Heights", state: "Virginia"},
    {city: "Covington", state: "Virginia"},
    {city: "Culpeper", state: "Virginia"},
    {city: "Dale City", state: "Virginia"},
    {city: "Danville", state: "Virginia"},
    {city: "Dumbarton", state: "Virginia"},
    {city: "Dunn Loring", state: "Virginia"},
    {city: "East Highland Park", state: "Virginia"},
    {city: "Fairfax", state: "Virginia"},
    {city: "Falls Church", state: "Virginia"},
    {city: "Farmville", state: "Virginia"},
    {city: "Forest", state: "Virginia"},
    {city: "Fort Belvoir", state: "Virginia"},
    {city: "Fort Hunt", state: "Virginia"},
    {city: "Fort Lee", state: "Virginia"},
    {city: "Franconia", state: "Virginia"},
    {city: "Franklin", state: "Virginia"},
    {city: "Fredericksburg", state: "Virginia"},
    {city: "Front Royal", state: "Virginia"},
    {city: "Galax", state: "Virginia"},
    {city: "Glen Allen", state: "Virginia"},
    {city: "Gloucester Point", state: "Virginia"},
    {city: "Great Falls", state: "Virginia"},
    {city: "Groveton", state: "Virginia"},
    {city: "Hampton", state: "Virginia"},
    {city: "Harrisonburg", state: "Virginia"},
    {city: "Herndon", state: "Virginia"},
    {city: "Highland Springs", state: "Virginia"},
    {city: "Hollins", state: "Virginia"},
    {city: "Hopewell", state: "Virginia"},
    {city: "Huntington", state: "Virginia"},
    {city: "Hybla Valley", state: "Virginia"},
    {city: "Idylwood", state: "Virginia"},
    {city: "Jefferson", state: "Virginia"},
    {city: "Lake Barcroft", state: "Virginia"},
    {city: "Lake Monticello", state: "Virginia"},
    {city: "Lake Ridge", state: "Virginia"},
    {city: "Lakeside", state: "Virginia"},
    {city: "Laurel", state: "Virginia"},
    {city: "Leesburg", state: "Virginia"},
    {city: "Lexington", state: "Virginia"},
    {city: "Lincolnia", state: "Virginia"},
    {city: "Linton Hall", state: "Virginia"},
    {city: "Lorton", state: "Virginia"},
    {city: "Lynchburg", state: "Virginia"},
    {city: "Madison Heights", state: "Virginia"},
    {city: "Manassas", state: "Virginia"},
    {city: "Manassas Park", state: "Virginia"},
    {city: "Mantua", state: "Virginia"},
    {city: "Marion", state: "Virginia"},
    {city: "Martinsville", state: "Virginia"},
    {city: "McLean", state: "Virginia"},
    {city: "Mechanicsville", state: "Virginia"},
    {city: "Merrifield", state: "Virginia"},
    {city: "Montclair", state: "Virginia"},
    {city: "Montrose", state: "Virginia"},
    {city: "Mount Vernon", state: "Virginia"},
    {city: "Newington", state: "Virginia"},
    {city: "Newport News", state: "Virginia"},
    {city: "Norfolk", state: "Virginia"},
    {city: "North Springfield", state: "Virginia"},
    {city: "Oakton", state: "Virginia"},
    {city: "Petersburg", state: "Virginia"},
    {city: "Pimmit Hills", state: "Virginia"},
    {city: "Poquoson", state: "Virginia"},
    {city: "Portsmouth", state: "Virginia"},
    {city: "Pulaski", state: "Virginia"},
    {city: "Quantico Station", state: "Virginia"},
    {city: "Radford", state: "Virginia"},
    {city: "Reston", state: "Virginia"},
    {city: "Richmond", state: "Virginia"},
    {city: "Roanoke", state: "Virginia"},
    {city: "Rose Hill", state: "Virginia"},
    {city: "Salem", state: "Virginia"},
    {city: "Seven Corners", state: "Virginia"},
    {city: "Smithfield", state: "Virginia"},
    {city: "South Boston", state: "Virginia"},
    {city: "Springfield", state: "Virginia"},
    {city: "Staunton", state: "Virginia"},
    {city: "Stuarts Draft", state: "Virginia"},
    {city: "Sudley", state: "Virginia"},
    {city: "Suffolk", state: "Virginia"},
    {city: "Timberlake", state: "Virginia"},
    {city: "Tuckahoe", state: "Virginia"},
    {city: "Tysons Corner", state: "Virginia"},
    {city: "Vienna", state: "Virginia"},
    {city: "Vinton", state: "Virginia"},
    {city: "Virginia Beach", state: "Virginia"},
    {city: "Warrenton", state: "Virginia"},
    {city: "Waynesboro", state: "Virginia"},
    {city: "West Gate", state: "Virginia"},
    {city: "West Springfield", state: "Virginia"},
    {city: "Williamsburg", state: "Virginia"},
    {city: "Winchester", state: "Virginia"},
    {city: "Wolf Trap", state: "Virginia"},
    {city: "Woodbridge", state: "Virginia"},
    {city: "Wyndham", state: "Virginia"},
    {city: "Wytheville", state: "Virginia"},
    {city: "Yorkshire", state: "Virginia"},
    {city: "Aberdeen", state: "Washington"},
    {city: "Alderwood Manor", state: "Washington"},
    {city: "Anacortes", state: "Washington"},
    {city: "Arlington", state: "Washington"},
    {city: "Artondale", state: "Washington"},
    {city: "Auburn", state: "Washington"},
    {city: "Bainbridge Island", state: "Washington"},
    {city: "Bangor Trident Base", state: "Washington"},
    {city: "Battle Ground", state: "Washington"},
    {city: "Bellevue", state: "Washington"},
    {city: "Bellingham", state: "Washington"},
    {city: "Bonney Lake", state: "Washington"},
    {city: "Bothell", state: "Washington"},
    {city: "Bremerton", state: "Washington"},
    {city: "Brier", state: "Washington"},
    {city: "Bryn Mawr-Skyway", state: "Washington"},
    {city: "Burien", state: "Washington"},
    {city: "Burlington", state: "Washington"},
    {city: "Camano", state: "Washington"},
    {city: "Camas", state: "Washington"},
    {city: "Cascade-Fairwood", state: "Washington"},
    {city: "Centralia", state: "Washington"},
    {city: "Chehalis", state: "Washington"},
    {city: "Cheney", state: "Washington"},
    {city: "Clarkston", state: "Washington"},
    {city: "Clarkston Heights-Vineland", state: "Washington"},
    {city: "College Place", state: "Washington"},
    {city: "Cottage Lake", state: "Washington"},
    {city: "Covington", state: "Washington"},
    {city: "Des Moines", state: "Washington"},
    {city: "Dishman", state: "Washington"},
    {city: "East Hill-Meridian", state: "Washington"},
    {city: "East Renton Highlands", state: "Washington"},
    {city: "East Wenatchee Bench", state: "Washington"},
    {city: "Edgewood", state: "Washington"},
    {city: "Edmonds", state: "Washington"},
    {city: "Elk Plain", state: "Washington"},
    {city: "Ellensburg", state: "Washington"},
    {city: "Enumclaw", state: "Washington"},
    {city: "Ephrata", state: "Washington"},
    {city: "Everett", state: "Washington"},
    {city: "Fairwood", state: "Washington"},
    {city: "Federal Way", state: "Washington"},
    {city: "Ferndale", state: "Washington"},
    {city: "Five Corners", state: "Washington"},
    {city: "Fort Lewis", state: "Washington"},
    {city: "Gig Harbor", state: "Washington"},
    {city: "Graham", state: "Washington"},
    {city: "Grandview", state: "Washington"},
    {city: "Hazel Dell North", state: "Washington"},
    {city: "Hazel Dell South", state: "Washington"},
    {city: "Hobart", state: "Washington"},
    {city: "Hoquiam", state: "Washington"},
    {city: "Inglewood-Finn Hill", state: "Washington"},
    {city: "Issaquah", state: "Washington"},
    {city: "Kelso", state: "Washington"},
    {city: "Kenmore", state: "Washington"},
    {city: "Kennewick", state: "Washington"},
    {city: "Kent", state: "Washington"},
    {city: "Kingsgate", state: "Washington"},
    {city: "Kirkland", state: "Washington"},
    {city: "Lacey", state: "Washington"},
    {city: "Lake Forest Park", state: "Washington"},
    {city: "Lake Morton-Berrydale", state: "Washington"},
    {city: "Lake Shore", state: "Washington"},
    {city: "Lake Stevens", state: "Washington"},
    {city: "Lakeland North", state: "Washington"},
    {city: "Lakeland South", state: "Washington"},
    {city: "Lakewood", state: "Washington"},
    {city: "Lea Hill", state: "Washington"},
    {city: "Longview", state: "Washington"},
    {city: "Lynden", state: "Washington"},
    {city: "Lynnwood", state: "Washington"},
    {city: "Maltby", state: "Washington"},
    {city: "Maple Valley", state: "Washington"},
    {city: "Martha Lake", state: "Washington"},
    {city: "Marysville", state: "Washington"},
    {city: "Mercer Island", state: "Washington"},
    {city: "Midland", state: "Washington"},
    {city: "Mill Creek", state: "Washington"},
    {city: "Mill Plain", state: "Washington"},
    {city: "Minnehaha", state: "Washington"},
    {city: "Monroe", state: "Washington"},
    {city: "Moses Lake", state: "Washington"},
    {city: "Mount Vernon", state: "Washington"},
    {city: "Mountlake Terrace", state: "Washington"},
    {city: "Mukilteo", state: "Washington"},
    {city: "Newcastle", state: "Washington"},
    {city: "Normandy Park", state: "Washington"},
    {city: "North Creek", state: "Washington"},
    {city: "North Marysville", state: "Washington"},
    {city: "Oak Harbor", state: "Washington"},
    {city: "Olympia", state: "Washington"},
    {city: "Opportunity", state: "Washington"},
    {city: "Orchards", state: "Washington"},
    {city: "Otis Orchards-East Farms", state: "Washington"},
    {city: "Paine Field-Lake Stickney", state: "Washington"},
    {city: "Parkland", state: "Washington"},
    {city: "Parkwood", state: "Washington"},
    {city: "Pasco", state: "Washington"},
    {city: "Picnic Point-North Lynnwood", state: "Washington"},
    {city: "Port Angeles", state: "Washington"},
    {city: "Port Orchard", state: "Washington"},
    {city: "Port Townsend", state: "Washington"},
    {city: "Poulsbo", state: "Washington"},
    {city: "Prairie Ridge", state: "Washington"},
    {city: "Pullman", state: "Washington"},
    {city: "Puyallup", state: "Washington"},
    {city: "Redmond", state: "Washington"},
    {city: "Renton", state: "Washington"},
    {city: "Richland", state: "Washington"},
    {city: "Riverton-Boulevard Park", state: "Washington"},
    {city: "Salmon Creek", state: "Washington"},
    {city: "Sammamish", state: "Washington"},
    {city: "SeaTac", state: "Washington"},
    {city: "Seattle", state: "Washington"},
    {city: "Seattle Hill-Silver Firs", state: "Washington"},
    {city: "Sedro-Woolley", state: "Washington"},
    {city: "Selah", state: "Washington"},
    {city: "Shelton", state: "Washington"},
    {city: "Shoreline", state: "Washington"},
    {city: "Silverdale", state: "Washington"},
    {city: "Snohomish", state: "Washington"},
    {city: "South Hill", state: "Washington"},
    {city: "Spanaway", state: "Washington"},
    {city: "Spokane", state: "Washington"},
    {city: "Spokane Valley", state: "Washington"},
    {city: "Steilacoom", state: "Washington"},
    {city: "Summit", state: "Washington"},
    {city: "Sumner", state: "Washington"},
    {city: "Sunnyside", state: "Washington"},
    {city: "Tacoma", state: "Washington"},
    {city: "Terrace Heights", state: "Washington"},
    {city: "Toppenish", state: "Washington"},
    {city: "Tukwila", state: "Washington"},
    {city: "Tumwater", state: "Washington"},
    {city: "Union Hill-Novelty Hill", state: "Washington"},
    {city: "University Place", state: "Washington"},
    {city: "Vancouver", state: "Washington"},
    {city: "Vashon", state: "Washington"},
    {city: "Veradale", state: "Washington"},
    {city: "Walla Walla", state: "Washington"},
    {city: "Waller", state: "Washington"},
    {city: "Walnut Grove", state: "Washington"},
    {city: "Washington", state: "Washington"},
    {city: "Washougal", state: "Washington"},
    {city: "Wenatchee", state: "Washington"},
    {city: "West Lake Stevens", state: "Washington"},
    {city: "West Richland", state: "Washington"},
    {city: "West Valley", state: "Washington"},
    {city: "White Center", state: "Washington"},
    {city: "Woodinville", state: "Washington"},
    {city: "Yakima", state: "Washington"},
    {city: "Beckley", state: "West Virginia"},
    {city: "Bluefield", state: "West Virginia"},
    {city: "Bridgeport", state: "West Virginia"},
    {city: "Charleston", state: "West Virginia"},
    {city: "Cheat Lake", state: "West Virginia"},
    {city: "Clarksburg", state: "West Virginia"},
    {city: "Cross Lanes", state: "West Virginia"},
    {city: "Dunbar", state: "West Virginia"},
    {city: "Elkins", state: "West Virginia"},
    {city: "Fairmont", state: "West Virginia"},
    {city: "Huntington", state: "West Virginia"},
    {city: "Martinsburg", state: "West Virginia"},
    {city: "Morgantown", state: "West Virginia"},
    {city: "Moundsville", state: "West Virginia"},
    {city: "Nitro", state: "West Virginia"},
    {city: "Oak Hill", state: "West Virginia"},
    {city: "Parkersburg", state: "West Virginia"},
    {city: "Pea Ridge", state: "West Virginia"},
    {city: "Princeton", state: "West Virginia"},
    {city: "South Charleston", state: "West Virginia"},
    {city: "St. Albans", state: "West Virginia"},
    {city: "Teays Valley", state: "West Virginia"},
    {city: "Vienna", state: "West Virginia"},
    {city: "Weirton", state: "West Virginia"},
    {city: "Wheeling", state: "West Virginia"},
    {city: "Allouez", state: "Wisconsin"},
    {city: "Altoona", state: "Wisconsin"},
    {city: "Antigo", state: "Wisconsin"},
    {city: "Appleton", state: "Wisconsin"},
    {city: "Ashland", state: "Wisconsin"},
    {city: "Ashwaubenon", state: "Wisconsin"},
    {city: "Baraboo", state: "Wisconsin"},
    {city: "Beaver Dam", state: "Wisconsin"},
    {city: "Bellevue", state: "Wisconsin"},
    {city: "Bellevue Town", state: "Wisconsin"},
    {city: "Beloit", state: "Wisconsin"},
    {city: "Brookfield", state: "Wisconsin"},
    {city: "Brown Deer", state: "Wisconsin"},
    {city: "Burlington", state: "Wisconsin"},
    {city: "Caledonia", state: "Wisconsin"},
    {city: "Cedarburg", state: "Wisconsin"},
    {city: "Chippewa Falls", state: "Wisconsin"},
    {city: "Cudahy", state: "Wisconsin"},
    {city: "De Pere", state: "Wisconsin"},
    {city: "DeForest", state: "Wisconsin"},
    {city: "Delafield", state: "Wisconsin"},
    {city: "Delavan", state: "Wisconsin"},
    {city: "Eau Claire", state: "Wisconsin"},
    {city: "Elkhorn", state: "Wisconsin"},
    {city: "Elm Grove", state: "Wisconsin"},
    {city: "Fitchburg", state: "Wisconsin"},
    {city: "Fond du Lac", state: "Wisconsin"},
    {city: "Fort Atkinson", state: "Wisconsin"},
    {city: "Fox Point", state: "Wisconsin"},
    {city: "Franklin", state: "Wisconsin"},
    {city: "Genesee", state: "Wisconsin"},
    {city: "Germantown", state: "Wisconsin"},
    {city: "Glendale", state: "Wisconsin"},
    {city: "Grafton", state: "Wisconsin"},
    {city: "Grand Chute", state: "Wisconsin"},
    {city: "Grand Rapids", state: "Wisconsin"},
    {city: "Green Bay", state: "Wisconsin"},
    {city: "Greendale", state: "Wisconsin"},
    {city: "Greenfield", state: "Wisconsin"},
    {city: "Greenville", state: "Wisconsin"},
    {city: "Hales Corners", state: "Wisconsin"},
    {city: "Hartford", state: "Wisconsin"},
    {city: "Hartland", state: "Wisconsin"},
    {city: "Holmen", state: "Wisconsin"},
    {city: "Howard", state: "Wisconsin"},
    {city: "Hudson", state: "Wisconsin"},
    {city: "Janesville", state: "Wisconsin"},
    {city: "Jefferson", state: "Wisconsin"},
    {city: "Kaukauna", state: "Wisconsin"},
    {city: "Kenosha", state: "Wisconsin"},
    {city: "Kimberly", state: "Wisconsin"},
    {city: "La Crosse", state: "Wisconsin"},
    {city: "Lake Geneva", state: "Wisconsin"},
    {city: "Lisbon", state: "Wisconsin"},
    {city: "Little Chute", state: "Wisconsin"},
    {city: "Madison", state: "Wisconsin"},
    {city: "Manitowoc", state: "Wisconsin"},
    {city: "Marinette", state: "Wisconsin"},
    {city: "Marshfield", state: "Wisconsin"},
    {city: "McFarland", state: "Wisconsin"},
    {city: "Menasha", state: "Wisconsin"},
    {city: "Menomonee Falls", state: "Wisconsin"},
    {city: "Menomonie", state: "Wisconsin"},
    {city: "Mequon", state: "Wisconsin"},
    {city: "Merrill", state: "Wisconsin"},
    {city: "Merton", state: "Wisconsin"},
    {city: "Middleton", state: "Wisconsin"},
    {city: "Milwaukee", state: "Wisconsin"},
    {city: "Monona", state: "Wisconsin"},
    {city: "Monroe", state: "Wisconsin"},
    {city: "Mount Pleasant", state: "Wisconsin"},
    {city: "Mukwonago", state: "Wisconsin"},
    {city: "Muskego", state: "Wisconsin"},
    {city: "Neenah", state: "Wisconsin"},
    {city: "New Berlin", state: "Wisconsin"},
    {city: "New London", state: "Wisconsin"},
    {city: "New Richmond", state: "Wisconsin"},
    {city: "Norway", state: "Wisconsin"},
    {city: "Oak Creek", state: "Wisconsin"},
    {city: "Oconomowoc", state: "Wisconsin"},
    {city: "Onalaska", state: "Wisconsin"},
    {city: "Oregon", state: "Wisconsin"},
    {city: "Oshkosh", state: "Wisconsin"},
    {city: "Pewaukee", state: "Wisconsin"},
    {city: "Platteville", state: "Wisconsin"},
    {city: "Pleasant Prairie", state: "Wisconsin"},
    {city: "Plover", state: "Wisconsin"},
    {city: "Plymouth", state: "Wisconsin"},
    {city: "Port Washington", state: "Wisconsin"},
    {city: "Portage", state: "Wisconsin"},
    {city: "Prairie du Chien", state: "Wisconsin"},
    {city: "Racine", state: "Wisconsin"},
    {city: "Reedsburg", state: "Wisconsin"},
    {city: "Rhinelander", state: "Wisconsin"},
    {city: "Rib Mountain", state: "Wisconsin"},
    {city: "Rice Lake", state: "Wisconsin"},
    {city: "Richfield", state: "Wisconsin"},
    {city: "Ripon", state: "Wisconsin"},
    {city: "River Falls", state: "Wisconsin"},
    {city: "Salem", state: "Wisconsin"},
    {city: "Shawano", state: "Wisconsin"},
    {city: "Sheboygan", state: "Wisconsin"},
    {city: "Sheboygan Falls", state: "Wisconsin"},
    {city: "Shorewood", state: "Wisconsin"},
    {city: "Somers", state: "Wisconsin"},
    {city: "South Milwaukee", state: "Wisconsin"},
    {city: "Sparta", state: "Wisconsin"},
    {city: "St. Francis", state: "Wisconsin"},
    {city: "Stevens Point", state: "Wisconsin"},
    {city: "Stoughton", state: "Wisconsin"},
    {city: "Sturgeon Bay", state: "Wisconsin"},
    {city: "Suamico", state: "Wisconsin"},
    {city: "Sun Prairie", state: "Wisconsin"},
    {city: "Superior", state: "Wisconsin"},
    {city: "Sussex", state: "Wisconsin"},
    {city: "Tomah", state: "Wisconsin"},
    {city: "Two Rivers", state: "Wisconsin"},
    {city: "Vernon", state: "Wisconsin"},
    {city: "Verona", state: "Wisconsin"},
    {city: "Washington", state: "Wisconsin"},
    {city: "Watertown", state: "Wisconsin"},
    {city: "Waukesha", state: "Wisconsin"},
    {city: "Waunakee", state: "Wisconsin"},
    {city: "Waupun", state: "Wisconsin"},
    {city: "Wausau", state: "Wisconsin"},
    {city: "Wauwatosa", state: "Wisconsin"},
    {city: "West Allis", state: "Wisconsin"},
    {city: "West Bend", state: "Wisconsin"},
    {city: "Weston", state: "Wisconsin"},
    {city: "Whitefish Bay", state: "Wisconsin"},
    {city: "Whitewater", state: "Wisconsin"},
    {city: "Wisconsin Rapids", state: "Wisconsin"},
    {city: "Casper", state: "Wyoming"},
    {city: "Cheyenne", state: "Wyoming"},
    {city: "Cody", state: "Wyoming"},
    {city: "Evanston", state: "Wyoming"},
    {city: "Gillette", state: "Wyoming"},
    {city: "Green River", state: "Wyoming"},
    {city: "Jackson", state: "Wyoming"},
    {city: "Lander", state: "Wyoming"},
    {city: "Laramie", state: "Wyoming"},
    {city: "Rawlins", state: "Wyoming"},
    {city: "Riverton", state: "Wyoming"},
    {city: "Rock Springs", state: "Wyoming"},
    {city: "Sheridan", state: "Wyoming"},
];

export const UsLocations = USLocationsData.map((data) => {
    let label = `${data.city}, ${data.state}`;
    return {
        value: label,
        label: label,
    }
});