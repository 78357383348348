import React from 'react';
import { EMAIL_CONSTANTS } from '../../services/constant';

export const STRIPE_SUBSCRIPTION_STATUS = {
    CANCELED: 'canceled',
    TRIALING: 'trialing',
    ACTIVE: 'active',
};

export const STRIPE_VALIDATION_MODAL_CONTENT: any = {
    NEW_SUBSCRIPTION: {
        header: (
            <>
                <b>Try LEMOS Pro for 30 days</b>
            </>
        ),
        body: [
            <>
                Sign up for your <b>free 30 day LEMOS Pro trial </b> to unlock
                premium features,
                <br /> including connecting BIM models.
            </>,
            <>
                If you decide to continue after the trial period, the Pro plan
                will be US$198
                <br />
                per month for each project.
                <div className='note'>
                    <i>No payment details required for the trial</i>
                </div>
            </>,
        ],
        questionIcon: true,
    },
    ACTIVATE_ON_TRIAL: {
        header: (
            <>
                <b>Upgrade Project - Pro Trial</b>
            </>
        ),
        body: [
            <>
                Upgrading will add this project to your{' '}
                <b className='emphasized-note'>LEMOS Pro Trial</b> and unlock
                premium features, including connecting BIM models. You’ll have
                access to these features until the end of the trial period.
            </>,
            <>
                If you decide to continue after the trial period, the Pro plan
                will be US$198/mo for each project. <br />
                <i className='payment-note'>
                    No payment details required for the trial
                </i>
            </>,
            <>
                <b className='emphasized-note'>
                    Would you like to upgrade this project?
                </b>
            </>,
        ],
        questionIcon: true,
    },
    ACTIVATE_ON_ACTIVE: {
        header: (
            <>
                <b>Upgrade Project</b>
            </>
        ),
        body: [
            <>
                To upgrade this project to a Pro plan, you’ll be charged US$198
                per month.
                <br /> You’ll have access to premium features, such as auditing
                a model file.
            </>,
            <>
                Downgrading the project will cancel the monthly charge and
                access to <br />
                premium features will remain active until the end of your
                current billing cycle.
            </>,
            <>
                <b className='emphasized-note'>
                    Would you like to upgrade this project?
                </b>
            </>,
        ],
        questionIcon: true,
    },
    ACTIVATE_ON_CANCELED: {
        header: (
            <>
                <b>Continue with LEMOS Pro</b>
            </>
        ),
        body: [
            <>
                To continue using the Pro premium features for this project,
                you’ll be
                <br />
                charged US$198 per month.
            </>,
            <>
                Downgrading the project will cancel the monthly charge and
                access to
                <br />
                premium features will remain active until the end of your
                current billing cycle.
            </>,
            <>
                <b className='emphasized-note'>
                    Would you like to continue with LEMOS Pro for this project?
                </b>
            </>,
        ],
        questionIcon: true,
    },
    SUCCESS_TRIAL_SUBSCRIPTION: {
        header: <>Welcome to LEMOS Pro</>,
        body: [
            <>
                You are on a 30 day free trial of the Pro plan from now until{' '}
                <br />
                enter-day-date-here. You’ll have access to premium features,{' '}
                <br />
                such as auditing a model file.
            </>,
        ],
    },
    SUCCESS_PAID_SUBSCRIPTION: {
        header: <>Payment Successful</>,
        body: [
            <>Thank you for your purchase. Your subscription is now active.</>,
        ],
    },
    FAILED_TRIAL_SUBSCRIPTION: {
        header: <>Trial Activation Failed"</>,
        body: [
            <>
                There was an issue with your trial activation. Please try again
                later. If you continue to have issues please contact{' '}
                <a href={`mailto:${EMAIL_CONSTANTS.SUPPORT_EMAIL}`}>customer support.</a>
            </>,
        ],
    },
    FAILED_PAID_SUBSCRIPTION: {
        header: <>Payment Failed</>,
        body: [
            <>
                There was an issue with your payment. Please try again later. If
                you continue to have issues please contact{' '}
                <a href={`mailto:${EMAIL_CONSTANTS.SUPPORT_EMAIL}`}>customer support.</a>
            </>,
        ],
    },
};
